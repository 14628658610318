define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.ecThresholdSetupActionsService',[])
        .factory("ecThresholdSetupActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var ecThresholdSetupActionsService = {};

                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_TH_REFRESH_TYPE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32863",
                    GET_TH_CLASSIFICATION: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32864",
                    GET_TH_TABLE_TYPE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32865",
                    GET_TH_VALUE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32866",
                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                ecThresholdSetupActionsService.loadThresholdRefreshType = function(){
                    var  params = {
                        action_code: '1mv9vb', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_TH_REFRESH_TYPE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Refresh Type.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Refresh Type. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecThresholdSetupActionsService.loadThresholdClassification = function(){
                    var  params = {
                        action_code: 'g3g3mz', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_TH_CLASSIFICATION,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to Classification.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Classification. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecThresholdSetupActionsService.loadThresholdTableType = function(){
                    var  params = {
                        action_code: 's05y4h', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_TH_TABLE_TYPE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to Table Type.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Table Type. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecThresholdSetupActionsService.loadThresholdValue = function(){
                    var  params = {
                        action_code: '0ibo0u', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_TH_VALUE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Threshold Value.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Threshold Value. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };
                

                ecThresholdSetupActionsService.convertDateFormates=function(jsonObj){
                    jsonObj.BATCH_START_DATE= moment(jsonObj.BATCH_START_DATE).format('MM/DD/YYYY');
                    jsonObj.BATCH_END_DATE= (jsonObj.BATCH_END_DATE == undefined || jsonObj.BATCH_END_DATE == 'Invalid date' || jsonObj.BATCH_END_DATE == '')  ? null: moment(jsonObj.BATCH_END_DATE).format('MM/DD/YYYY');
                    jsonObj.DEFAULT_TAX_YEAR_BEGIN= (jsonObj.DEFAULT_TAX_YEAR_BEGIN == undefined) ? null: moment(jsonObj.DEFAULT_TAX_YEAR_BEGIN).format('MM/DD/YYYY');
                    jsonObj.DEFAULT_TAX_YEAR_END= (jsonObj.DEFAULT_TAX_YEAR_BEGIN == undefined) ? null: moment(jsonObj.DEFAULT_TAX_YEAR_END).format('MM/DD/YYYY');
                    return jsonObj;
                }

               
                return ecThresholdSetupActionsService;

        }]);

    return services;

});