
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.AttributeBatchService',[])

    .factory('AttributeBatchServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        var attributeBatchServiceFactory = {};
	        attributeBatchServiceFactory.url = {};
	        attributeBatchServiceFactory.url.locking_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=medwnk"; 
	        attributeBatchServiceFactory.url.send_notify = GENERAL_CONFIG.base_url + "/sendTagUpdateNotification"; 
	        attributeBatchServiceFactory.url.attribute_save = GENERAL_CONFIG.base_url + "/saveJsonAsPivotObject?action_code=1rs6lg";
	
	
	        attributeBatchServiceFactory.saveDefaults = function(_data,Details){

	        	 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	             var jsonObj = JSON.stringify(Details);
	             var jsonSettings =  JSON.stringify(_data);
	             //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	             
	             console.log(jsonSettings);
	             var params = filterParams;
	             params = _.merge({},  GlobalService.globalParams , params);
	             params =  _.extend({clob_data:jsonObj}, params);
	             params =  _.extend({clob_settings:jsonSettings}, params);
	             params.process_name =  "attributes-grid-save";
	             console.log(params);
	             var promise = $http({
	                 method: "post",
	                 url: attributeBatchServiceFactory.url.attribute_save,
	                 data: params
	             }).then(function (response) {
	                 var status = response.status;
	                 return response;
	             });
	             return promise;
	         };
        
        
        attributeBatchServiceFactory.saveLockAndUnLocking = function(_tags,_flag){

            //var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
           
            var params = {};
            params["p_tag_key"] = _tags;
            params["p_is_locked"] = _flag;
            params = _.merge({},  GlobalService.globalParams , params);
            params.process_name =  "attributes-grid-save";
            
            console.log("Save params:",params);            
            var promise = $http({
                method: "post",
                url: attributeBatchServiceFactory.url.locking_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        
        attributeBatchServiceFactory.sendNotification = function(_notificationData){
            var params = {};
            params["notification_data"] = JSON.stringify(_notificationData);                       
            console.log("Sending params:",params);
           
            var promise = $http({
                method: "post",
                url: attributeBatchServiceFactory.url.send_notify,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        
        return attributeBatchServiceFactory
    }])

    return services;

});