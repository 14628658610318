define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.ghostSettlementReclassesDeleteCtrl',  [])
		.controller('ghostSettlementReclassesDeleteCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','firGhostSettlementScreenFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', ghostSettlementReclassesDeleteCtrl]);


	function ghostSettlementReclassesDeleteCtrl (CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,firGhostSettlementScreenFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;	
	    vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.modalTitle = "Delete Settlement Reclasses";
        vm.isDelete = true;
        
        vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
        
        
		vm.deleteSettlementReclasses = function(){
	            var deleteJson = [{rec_seq_key: vm.rowData.REC_SEQ_KEY}];
	        	vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
	        	var success_message = "Settlement Reclass was successfully deleted";
	        	firGhostSettlementScreenFactory.deleteSettlementReclasses(deleteJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ", data.data.errorMessage);
	        			AlertService.add("error", data.data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ", data.data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
	        			var args = {rec_seq_key: vm.rowData.REC_SEQ_KEY, gridFilter: {rec_seq_key: vm.rowData.REC_SEQ_KEY},object_id:vm.rowData.object_id,action:"delete"};
	        			$rootScope.$emit('gridUpdate', args);
                    	$uibModalInstance.dismiss('cancel');
	        		}
	        	});
	        }
		
		
	}
	  }); 