define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.sltDataserviceController', [])
    .controller('sltDataserviceController', ['$rootScope', '$scope',
        '$timeout', '$uibModalInstance', 'USER_SETTINGS', 'sltApiDataFactory', 'GENERAL_CONFIG', 'GlobalService',
        'rowData', 'ObjectDiff', 'AlertService', 'ModalFactory', sltDataserviceController]);

    function sltDataserviceController($rootScope, $scope, $timeout, $uibModalInstance, USER_SETTINGS,
        sltApiDataFactory, GENERAL_CONFIG, GlobalService, rowData, ObjectDiff, AlertService, ModalFactory) {
        
        var vm = this;
        vm.row = rowData;
        // ModalFactory.response.data && ModalFactory.response.data.modalScreen ? ModalFactory.response.data.modalScreen.modal_name
        vm.title = 'Data Service';
        vm.userSettings = USER_SETTINGS;
        vm.client_key = vm.row.CLIENT_KEY === -1 ? 0 : vm.row.CLIENT_KEY;
        vm.tax_year = GlobalService.globalParams.tax_year;
        vm.scenario = GlobalService.globalParams.scenario;
        vm.loading = false;
        // vm.inParams = {};
        vm.inParams = [];
        vm.dataServiceResponse = '';
        vm.dataServiceResponseToDownload = '';
        vm.rowInParams = {};
        let procInParams = rowData.PROC_PARAMS_IN ? rowData.PROC_PARAMS_IN.split(",") : [];
        procInParams.forEach(element => {
            if(element && element.split(":").length > 2) {
                // vm.inParams[_.startCase(_.toLower(element.split(":")[0].replace('_',' ')))] = element.split(":")[2];
                if(element.split(":")[2] != 'null') {
                    vm.rowInParams[element.split(":")[0]] = element.split(":")[2];
                } else {
                    vm.rowInParams[element.split(":")[0]] = '';
                }
            }
        });
        vm.rowInParams['client_key'] = vm.client_key;
        vm.rowInParams['tax_year'] = vm.tax_year;
        vm.rowInParams['scenario'] = vm.scenario;
        vm.rowInParams['jcd_key'] = GlobalService.globalParams.jcd_key;
        vm.rowInParams['service_key'] = vm.row.SERVICE_KEY;
        vm.rowInParams['sso_id'] = vm.row.SSO_ID;

        for(let i in vm.rowInParams) {
            let obj = {};
            obj['key'] = _.startCase(_.toLower(i));
            obj['value'] = vm.rowInParams[i]; 
            obj['urlparam'] = i;
            vm.inParams.push(obj);
        }

        vm.test = function() {
            vm.loading = true;
            sltApiDataFactory.testDataService(vm.inParams).then(response => {
                vm.loading = false;
                if(response && response.callSuccess === "0"){
                    AlertService.add("error", response.errorMessage,4000);
                } else {
                    vm.dataServiceResponse = ObjectDiff.objToJsonView(response);
                    vm.dataServiceResponseToDownload = JSON.stringify(response);
                }
            });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        
        vm.downloadResponseAsFile = function() {
            var link = document.createElement("a");
            link.download = vm.row.SERVICE_KEY + "_" + vm.row.SERVICE_NAME + "_response.json";
            var data = "application/json;charset=utf-8," + encodeURIComponent(vm.dataServiceResponseToDownload);
            link.href = "data:" + data;
            link.click();
        }

        vm.copyClipboard = function(){
            const copyTextarea = document.getElementById("dataServiceResponse").textContent;
            const textArea = document.createElement('textarea');
            textArea.textContent = copyTextarea;
            document.body.append(textArea);
            textArea.select();
            textArea.setSelectionRange(0,0);
            try {
                var successful = navigator.clipboard.writeText(textArea.value);
                vm.copyMessage = successful ? 'Response is successful copied to your clipboard' : 'unsuccessful';
                // textArea.remove();
                $timeout( function(){
                    vm.copyMessage = null;
                }, 3000 );
            } catch (err) {
            }

        }
    };

    return controllers;
});
