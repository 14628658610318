define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.DCLService', [])

        .factory("DCLFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
                var factory = {};
                    
                factory.saveDCLDetails = function (_callName, _data, isNew) {
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var defer = $q.defer();
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=yacpis";
                    clob_data.push(_data.rowData);
                    clob_setting = {group_trans_key:''};
                    params.process_name = "DCL_EDIT_SAVE";
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                    trans_key : _data.rowData.TRANS_KEY,
                                    tax_year: _data.rowData.TAX_YEAR,
                                    row_identifier: _data.rowData.object_id
                                };
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                        } else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    });
                }
                factory.savedcldata = function (_callName, _data, isNew) {
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var defer = $q.defer();
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=exhb8l";
                    clob_data.push(_data.rowData);
                    clob_setting = {group_trans_key:''};
                    params.process_name = "DCL_DATA_SAVE_PHASE2";
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                  //  trans_key : _data.rowData.TRANS_KEY,
                                    tax_year: _data.rowData.TAX_YEAR,
                                    row_identifier: _data.rowData.object_id
                                };
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                        } else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    });
                }
                
                
                return factory;
            }
        ])
    return services;
});