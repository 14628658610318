define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.DstStatementsFiledStatusServices', [])
        .factory("DstStatementsFiledStatusFactory", ['$http', 'GENERAL_CONFIG', 'USER_SETTINGS', 'GlobalService', function ($http, GENERAL_CONFIG, USER_SETTINGS, GlobalService) {
            const dstStatementsFiledStatusFactory = {};

            dstStatementsFiledStatusFactory.getTaxYears = function () {
                return $http.get(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32172&sso_id=${USER_SETTINGS.user.sso_id}`);
            }

            dstStatementsFiledStatusFactory.getBusinesses = function (tax_year) {
                return $http.get(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32642&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${tax_year}`);
            }

            dstStatementsFiledStatusFactory.getStatementsFiledStatusGridData = function (tax_year, business_id) {
                return $http.get(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32643&tax_year=${tax_year}&business_id=${business_id}`);
            }

            dstStatementsFiledStatusFactory.updateStatementFiledStatus = function (statement_ids, in_status_type, in_type) {
                var params = {
                    'statement_ids': statement_ids,
                    'in_status_type': in_status_type,
                    'in_type': in_type,
                    'tax_year':	GlobalService.globalParams.tax_year,
                    'scenario': GlobalService.globalParams.scenario,
                    'jcd_key': GlobalService.globalParams.jcd_key,
                    'scenarioCode': GlobalService.inputs.tax_year.selected.scenario_type_code,
                    'is_issue_key_enabled': 'N'
                };
    
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32644",
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            return dstStatementsFiledStatusFactory;
        }]);

    return services;
});
