define([
    'angular',
    'ui-tree',
    'ui-tree-filter',
    './bizzHierViewCtrl',
    './bizzHierViewServices',
], function () {
    'use strict';
    return angular.module('app.bizzHierView', ['app.bizzHierViewServices', 'app.bizzHierViewCtrl', 'ui.toggle', 'ngAnimate', 'ui.tree', 'ui.tree-filter'])
                  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {
                      $stateProvider
                          .state('business-hierarchy', {
                              url: '/business-hierarchy',
                              templateUrl: 'app/components/Custom/bizzHierView/bizz-hier.html',
                              controller: 'bizzHierViewTabsCtrl as ctrl',
                              data: {},
                              access: ""
                          })
                  }]);
});
