/**
 * @author Vijay Ramagiri This is to get and save Interest Expenses 
 * 
 * 
 */
define(
		[ 'angular',
		    './InterestRnDExpenseApprService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.TotalInterestExpenseCtrl', ['app.InterestRnDExpenseApprService'])
					.controller('totalInterestExpenseCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','USER_SETTINGS','workspaceFactory','$filter','InterestExpenseFactory','$http', totalInterestExpenseCtrl ])

			function totalInterestExpenseCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, $filter,InterestExpenseFactory,$http) {

				var vm = this;	
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Show the loading Spinner
				vm.loadingData = true;	
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//A edit modal
				vm.isEditMode = false;
				//A edit modal
				vm.isAssetEditMode = false;
				vm.page;
				vm.deleteAllFlag = false;
				//Adjustment Name
				vm.adjName;
				//Me Key
				vm.meKey;
				//Financial Corporation
				vm.finCorpKey;
				//Amount
				vm.amount;
				//Comments
				vm.comments;
				//Object_id
				vm.object_id;
				vm.assetAmtAdjList = [];
				var object = {};
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;	
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				
				//Get the Code Mast data for drop downs			
				vm.getCodeMastData = function() {
					var params = {
						"action_code" : 'dbty56',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.finCorpData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['YES_NO'], p.CODE_GROUP);  
			                		});		                	
			                	vm.fdiiData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.basketData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_CODE_NEW'], p.CODE_GROUP);  
			                		});
			                	console.log(" vm.fdiiData: ",vm.fdiiData);
			                }
						});
				}				
				vm.getCodeMastData();
				
				
				//Get the Parent ME (BSLA) Drop-Down
				vm.getParentMeDropdown = function() {
					var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user,'p_scenario' : vm.scenario, 'p_jcd_key' :vm.jcd_key };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.bslaCodeData = data.jsonObject;
							//console.log("vm.bslaCodeData : ", vm.bslaCodeData );
						});
				}
				vm.getParentMeDropdown();			
				
				//Retrieve the Modal/Popup records saved for the given Code Combination Key 
				vm.getAssetsAdjTagDtls = function(group_key) {	
					var params = {"action_code" : 'j5jtrx',"group_key":group_key,'p_scenario' : vm.scenario, 'p_jcd_key' :vm.jcd_key }; 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.assetsAdjTagDtls = data.jsonObject;
							if(vm.assetsAdjTagDtls.length > 0 ){
								for(var t in vm.assetsAdjTagDtls){
									var myObject = {};
//									object.amount = vm.assetsAdjTagDtls[t].ADJ_AMT;
//				                	object.selectedFdiiKey = ''+vm.assetsAdjTagDtls[t].FDII_KEY ;
//				                	object.adjKey = vm.assetsAdjTagDtls[t].ADJ_KEY;
									myObject["amount"] = vm.assetsAdjTagDtls[t].ADJ_AMT;
									if(vm.page == 'assetsFDII'){
										myObject["selectedFdiiKey"] = ''+vm.assetsAdjTagDtls[t].FDII_KEY ;
									}else{
										vm.selectedFinCorpKey = ''+vm.assetsAdjTagDtls[t].IS_FIN_CORP ;
										//myObject["selectedFinCorpKey"] = ''+vm.assetsAdjTagDtls[t].IS_FIN_CORP ;
										myObject["selectedSourceKey"] = ''+vm.assetsAdjTagDtls[t].SOURCE_CODE ;
									}
									myObject["adjKey"] = vm.assetsAdjTagDtls[t].ADJ_KEY;
									myObject["OPERATION_TYPE"] = 'U';	
				                	vm.assetAmtAdjList.push(myObject);
				                	
								}
//								vm.fdiiMeKey = vm.assetsAdjTagDtls[0].ME_KEY;
//								vm.assetComments = vm.assetsAdjTagDtls[0].COMMENTS;
								vm.assetAdjName = vm.assetsAdjTagDtls[0].ADJ_NAME.replace('861 Manual Asset Adjustment -','').trim();;
								vm.loadingData = false;
							}
							
					});		
					
				}
					
				
				//Adding a new row as a copy of Selected Row
				vm.addRow = function(){
					var tempObj = _.clone(vm.assetAmtAdjList[0]);
//					console.log(" tempObj.amount : ", tempObj.amount);
//					console.log(" tempObj.amount : ", tempObj.selectedFdiiKey);
					tempObj.amount = "";
					if(vm.page == 'assetsFDII'){
						tempObj.selectedFdiiKey = "";
					}else{
//						tempObj.selectedFinCorpKey = "";
						tempObj.selectedSourceKey = "";
					}
					tempObj.adjKey = "";
					tempObj.OPERATION_TYPE = "I";
					vm.assetAmtAdjList.push(tempObj);
					
				};
				
				
				//Delete the selected row 
				vm.deleteRow = function(selectedRowData){					
					var index = vm.assetAmtAdjList.indexOf(selectedRowData);
					
					if(vm.assetAmtAdjList[index].OPERATION_TYPE != undefined || vm.assetAmtAdjList[index].OPERATION_TYPE != null || vm.assetAmtAdjList[index].OPERATION_TYPE != "" ){					
						if(selectedRowData.OPERATION_TYPE == 'I'){
							vm.assetAmtAdjList.splice(index, 1);
						}else {
							vm.assetAmtAdjList[index].OPERATION_TYPE = 'D';
						}
						var countActiveRows = _.filter(vm.assetAmtAdjList, function(eachRow) { if (eachRow.OPERATION_TYPE != 'D') return eachRow }).length;
						if(countActiveRows == 0){
							var newRow = {};
							newRow.amount = '';
							if(vm.page == 'assetsFDII'){
								newRow.selectedFdiiKey = '';
							}else{
//								newRow.selectedFinCorpKey = '';
								newRow.selectedSourceKey = '';
							}
							newRow.OPERATION_TYPE = 'I';	
							vm.assetAmtAdjList.push(newRow);
						}
					} 
										
				};
				
				vm.deleteAllAdjs = function(){		
					vm.loadingData = true;
					vm.deleteAllFlag = true;
					_.forEach(vm.assetAmtAdjList, function(list){
						list.OPERATION_TYPE = 'D';
						
					});
					vm.saveAssetsAdj();
//					vm.loadingData = false;
										
				};
								
				function hasDuplicates(a) {
					return _.uniq(a).length !== a.length; 
				}
				
				vm.validateAdjAmountAndFDII = function(){
					
					var allFDIIOrBasketKeys = [];
						if(vm.page == 'assetsFDII'){
							for( var row in vm.assetAmtAdjList){
								if(vm.assetAmtAdjList[row].OPERATION_TYPE != 'D' && vm.assetAmtAdjList[row].selectedFdiiKey != ""){
									allFDIIOrBasketKeys.push(vm.assetAmtAdjList[row].selectedFdiiKey);
								}
							}
						}else{
							for( var row in vm.assetAmtAdjList){
								if(vm.assetAmtAdjList[row].OPERATION_TYPE != 'D' && vm.assetAmtAdjList[row].selectedSourceKey != ""){
									allFDIIOrBasketKeys.push(vm.assetAmtAdjList[row].selectedSourceKey);
								}
							}
							
						}
					
						if(vm.page == 'assetsFDII'){
							if(hasDuplicates(allFDIIOrBasketKeys)){
								AlertService.add("error", "Please select unique FDII.",4000);
								vm.isSaveClicked = false;
								throw new Error("Please select unique FDII.");
								return;
							}
						}else{
							if(hasDuplicates(allFDIIOrBasketKeys)){
								AlertService.add("error", "Please select unique Basket.",4000);
								vm.isSaveClicked = false;
								throw new Error("Please select unique Basket.");
								return;
							}
						}
				};
				
				
				
				vm.setSavedAssetAdjValues = function(){
					//Grid Data from Branch Tax  Screen
					if (gridData.length != 0 && gridData != null) {	
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
						
						vm.gridDataMultipleSelect = _.clone(tempGridData);
						if (vm.gridDataMultipleSelect != undefined) {	
							if (vm.gridDataMultipleSelect.rowData != undefined) {
								vm.assetAdjKey = vm.gridDataMultipleSelect.rowData.ADJ_KEY;
								vm.assetAdjName = vm.gridDataMultipleSelect.rowData.ITEM_NAME.replace('861 Manual Asset Adjustment -','').trim();							
								vm.assetComments = vm.gridDataMultipleSelect.rowData.COMMENTS;
								vm.assetGroupKey = ''+vm.gridDataMultipleSelect.rowData.GROUP_KEY;
								vm.object_id = vm.gridDataMultipleSelect.rowData.object_id;
								vm.getAssetsAdjTagDtls(vm.gridDataMultipleSelect.rowData.GROUP_KEY);
								vm.isAssetEditMode = true;
							}
//							vm.loadingData = false;
						}
					}else{
						vm.assetAdjName='';
						vm.assetComments='';
						object.amount = '';
						if(vm.page == 'assetsFDII'){
							object.selectedFdiiKey = '';
						}else{
							vm.selectedFinCorpKey = '';
							object.selectedSourceKey = '';
						}
	                	object.assetGroupKey = '';
	                	object.assetAdjKey = '';
	                	object["OPERATION_TYPE"] = 'I';	
	                	vm.assetAmtAdjList.push(object); 
	                	vm.loadingData = false;
					}
				}
				
				//Set the Saved Values to the Modal parameters
				vm.setSavedValues = function(){
					//Grid Data from Branch Tax  Screen
					if (gridData != null) {	
						//console.log("::: ->  -> gridData:: ", gridData);
						//console.log("::: ->  ->  rowData:: ", rowData);
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
						
						vm.gridDataMultipleSelect = _.clone(tempGridData);
						if (vm.gridDataMultipleSelect != undefined) {	
							//console.log("::: ->  ->  gridData:: ", vm.gridDataMultipleSelect);
							if (vm.gridDataMultipleSelect.rowData != undefined) {
								//console.log("::: ->  ->  rowData:: ", vm.gridDataMultipleSelect.rowData);
								vm.adjKey = vm.gridDataMultipleSelect.rowData.ADJUSTMENT_KEY;
								vm.adjName = vm.gridDataMultipleSelect.rowData.INT_EXP_ITEM.replace('861 Manual Interest Adjustment -','').trim();							
								vm.comments = vm.gridDataMultipleSelect.rowData.COMMENTS;
								vm.meKey = ''+vm.gridDataMultipleSelect.rowData.ME_KEY;
								vm.finCorpKey = ''+vm.gridDataMultipleSelect.rowData.SOURCE_KEY;
								vm.amount = vm.gridDataMultipleSelect.rowData.MANUAL_861_ADJUSTMENTS;
								vm.object_id = vm.gridDataMultipleSelect.rowData.object_id;
								vm.isEditMode = true;
															
							}
							vm.loadingData = false;
						}
					}
				}
				
				vm.init = function(intext){
					console.log(" ----intext --  ",intext);
					vm.page = intext;
					if(vm.page == 'interestexp'){
						vm.setSavedValues();						
					}else{
						vm.setSavedAssetAdjValues();
						
//						vm.loadingData = false;
					}
				}
				
				
				vm.saveAssetsAdj = function(){
					vm.validateAdjAmountAndFDII();
					
					var countAcct =  _.countBy(vm.assetAmtAdjList, function (rec) {
						  return rec.OPERATION_TYPE != 'D';
					});
					
					if(vm.page == 'assetsFDII'){
						if(countAcct.true > vm.fdiiData.length){
//							AlertService.add("error", "Cannot have more than "+vm.fdiiData.length+" unique FDII for this Adjustment ",4000);
							AlertService.add("error", "Please select unique FDII.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}else{
						if(countAcct.true > vm.basketData.length){
//							AlertService.add("error", "Cannot have more than "+vm.basketData.length+" unique Basket for this Adjustment ",4000);
							AlertService.add("error", "Please select unique Basket.",4000);
							vm.isSaveClicked = false;
							return;
						}
						
					}
					
					if(vm.assetAdjName == null || vm.assetAdjName == '' && vm.deleteAllFlag == false){
									//|| vm.assetComments == null || vm.assetComments == ''){
						AlertService.add("error", "Please select/enter all the fields.",4000);
						vm.loadingData = false;
						return;
					}
					
					for( var row in vm.assetAmtAdjList){	
						
						if(vm.page == 'assetsFDII'){
							if((vm.assetAmtAdjList[row].selectedFdiiKey == undefined  || vm.assetAmtAdjList[row].selectedFdiiKey == null  || vm.assetAmtAdjList[row].selectedFdiiKey == ''
								|| vm.assetAmtAdjList[row].amount == undefined  || vm.assetAmtAdjList[row].amount == null || vm.assetAmtAdjList[row].amount == '' 
									 || Math.round(vm.assetAmtAdjList[row].amount) == 0) && vm.assetAmtAdjList[row].OPERATION_TYPE != 'D'){
								AlertService.add("error", "Please select/enter all the required fields.",4000);
								vm.isSaveClicked = false;
								return;
							}
						}else{
							if((vm.selectedFinCorpKey == undefined  || vm.selectedFinCorpKey == null  || vm.selectedFinCorpKey == '' ||
								vm.assetAmtAdjList[row].selectedSourceKey == undefined  || vm.assetAmtAdjList[row].selectedSourceKey == null  || vm.assetAmtAdjList[row].selectedSourceKey == ''
								|| vm.assetAmtAdjList[row].amount == undefined  || vm.assetAmtAdjList[row].amount == null || vm.assetAmtAdjList[row].amount == '' 
									 || Math.round(vm.assetAmtAdjList[row].amount) == 0) && vm.assetAmtAdjList[row].OPERATION_TYPE != 'D'){
								AlertService.add("error", "Please select/enter all the required fields.",4000);
								vm.isSaveClicked = false;
								return;
							}
							
						}
					}
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
														
					var key = 0;															
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;					
					returnClobSettingsObj['tax_year'] = GlobalService.globalParams.tax_year;
					returnClobSettingsObj['SCREEN_KEY'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['object_id'] = vm.object_id;
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
							
					var assetDetails = [];
					for( var row in vm.assetAmtAdjList){	
						
						var returnObj = {};	
						returnObj['ADJUSTMENT_NAME'] = '861 Manual Asset Adjustment - '+vm.assetAdjName;	
						returnObj['COMMENTS'] = vm.assetComments;
						if(vm.page == 'assetsFDII'){
							returnObj["FDII_KEY"] = vm.assetAmtAdjList[row].selectedFdiiKey;
						}else{
							returnObj["FIN_CORP_KEY"] = vm.selectedFinCorpKey;
							returnObj["SOURCE_KEY"] = vm.assetAmtAdjList[row].selectedSourceKey;
						}
						returnObj['AMOUNT'] = vm.assetAmtAdjList[row].amount;
						returnObj['GROUP_KEY'] = vm.assetGroupKey;
						returnObj['ADJUSTMENT_KEY'] = vm.assetAmtAdjList[row].adjKey;//vm.assetAdjKey;
						returnObj['IS_SINGLE'] = 'M';
						returnObj["OPERATION_TYPE"] = vm.assetAmtAdjList[row].OPERATION_TYPE;
//						if((vm.assetGroupKey == undefined || vm.assetGroupKey == null  ||  vm.assetGroupKey == "") || (vm.assetAmtAdjList[row].adjKey == undefined || vm.assetAmtAdjList[row].adjKey == null || vm.assetAmtAdjList[row].adjKey == "")){
//								returnObj["OPERATION_TYPE"] = 'I';
//						}else if(vm.assetAmtAdjList[row].OPERATION_TYPE != 'D'){
//							returnObj["OPERATION_TYPE"] = 'U';
//						}else{
//							returnObj["OPERATION_TYPE"] = 'D';
//						}
						
						var message = "Assets FDII Adjustment Amount has been successfully saved/updated";
						assetDetails.push(returnObj);					
					}
						
										
					//send to save the Data
					sendDetails(returnClobSettingsObj,assetDetails, message);		
//					vm.loadingData = false;
				}
				
//				function sendFDIIDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
//					InterestExpenseFactory.saveFDIIData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
//						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
//							AlertService.add("error", result.data.errorMessage, 4000);
//							vm.isSaveClicked = false;
//						} else {
//							AlertService.add("success", message, 4000);
//							var args = {
//									object_id: returnClobSettingsObj.object_id,
//	                                gridFilter: {
//	                                	object_id: returnClobSettingsObj.object_id
//	                                }
//	                            };
//							vm.loadingData = false;
//							$uibModalInstance.dismiss('cancel');
//							$rootScope.$emit('gridUpdate', args);
//	                     	};
//					});
//				}

				
				//Delete Interest Expense Adjustment amount
				vm.removeIntExp = function(){
					vm.save('delete');
				};
				
				//Saving the Data for both Non Creditable and Creditable Tax forms 
				vm.save = function(saveFlag){					
					if(vm.adjName == null || vm.adjName == ''  || vm.meKey == null || vm.meKey == '' 
							|| vm.finCorpKey == null	|| vm.finCorpKey == '' ){
						AlertService.add("error", "Please select/enter all the required fields.",4000);
						return;
					}
					
					if(vm.amount == null || vm.amount == ''){
						AlertService.add("error", "Please enter a valid Adjustment Amount.",4000);
						return;
					}
			
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					var message = "";									
																				
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;			
					/*returnClobSettingsObj['p_tax_year'] = vm.curentyear;
					returnClobSettingsObj['p_scenario'] = vm.scenario;
					returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;*/
				///	returnClobSettingsObj['tax_year'] = GlobalService.globalParams.tax_year;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
				/*	returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					returnClobSettingsObj['scenario'] = GlobalService.globalParams.scenario;*/
					returnClobSettingsObj['object_id'] = vm.object_id;
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
										
					var returnObj = {};					
					returnObj['ADJUSTMENT_NAME'] = '861 Manual Interest Adjustment - '+vm.adjName;					
					returnObj["ME_KEY"] = vm.meKey;					
					returnObj["FIN_CORP_KEY"] = vm.finCorpKey;						
					returnObj['AMOUNT'] = vm.amount;
					returnObj['COMMENTS'] = vm.comments;
					returnObj['FORM_NO'] = ''; //'Non - 8916A';
					returnObj['IS_SINGLE'] = 'S';
					if(saveFlag == 'save') {
						if(vm.adjKey){
							if(vm.adjKey!=undefined && vm.adjKey!=null){
								returnObj["ADJUSTMENT_KEY"] = vm.adjKey; 
								returnObj["OPERATION_TYPE"] = 'U';
								returnObj["GROUP_KEY"] = vm.adjKey; 
							}else{
								returnObj["ADJUSTMENT_KEY"] = null;
								returnObj["OPERATION_TYPE"] = 'I';
								returnObj["GROUP_KEY"] = null;
							}
						}else{
							returnObj["ADJUSTMENT_KEY"] = null;
							returnObj["OPERATION_TYPE"] = 'I';
							returnObj["GROUP_KEY"] = null;
						}
						message = "Interest Expenses Adjustment Amount has been successfully saved/updated";
					}else if(saveFlag == 'delete'){
						returnObj["ADJUSTMENT_KEY"] = vm.adjKey; 
						returnObj["OPERATION_TYPE"] = 'D';
						returnObj["GROUP_KEY"] = vm.adjKey; 
						message = "Interest Expenses Adjustment Amount has been removed";
					}
					
					console.log("returnObj::FNL:: ",returnObj);
											
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);					
										
					//send to save the Data
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);										
				}

				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, interestAndExpDetails, message) {
					InterestExpenseFactory.saveData(returnClobSettingsObj,interestAndExpDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };
							vm.loadingData = false;
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
				
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//reset the form
				vm.reset = function(){	
					if(vm.isEditMode){
						vm.setSavedValues();
					}else{
						vm.adjName = '';
						vm.meKey = '';
						vm.finCorpKey = '';
						vm.amount = '';
						vm.comments = '';
					}
				};
				
				//reset the form
				vm.resetAssetAdj = function(){
					vm.assetAmtAdjList = [];
					vm.loadingData = true;
					vm.setSavedAssetAdjValues();
				};
				
				vm.settingAdjustmentAmount = function(event,temp){
					if(temp.amount){
						temp.amount = temp.amount.replace(/(?!^-)[^0-9\.]/g, '')
						let amount =  temp.amount;
						if(amount.indexOf('.') !== -1){
							let amountSp = amount.split('.'),bPre=0,aPre=0,amt = 0;
								bPre = amountSp[0];
								aPre = amountSp[1];
								if(bPre && bPre.length > 20){
										bPre = bPre.substring(0, 19)
								}
								if(aPre && aPre.length > 2){
									aPre = aPre.substring(0, 2)
								}
								temp.amount =  bPre+"."+aPre;
						}else if(amount.length > 20){
							amount = amount.substring(0, amount.length - 1)
							temp.amount = amount;
						}
					}
				}
				vm.settingAdjustmentAmountSimple = function(event){
					if(vm.amount){
						vm.amount = vm.amount.replace(/(?!^-)[^0-9\.]/g, '')
						let amount =  vm.amount;
						if(amount.indexOf('.') !== -1){
							let amountSp = amount.split('.'),bPre=0,aPre=0,amt = 0;
								bPre = amountSp[0];
								aPre = amountSp[1];
								if(bPre && bPre.length > 20){
										bPre = bPre.substring(0, 19)
								}
								if(aPre && aPre.length > 2){
									aPre = aPre.substring(0, 2)
								}
								vm.amount =  bPre+"."+aPre;
						}else if(amount.length > 20){
							amount = amount.substring(0, amount.length - 1)
							vm.amount = amount;
						}
					}
				}
				
			}			
			return controllers;

		});
	