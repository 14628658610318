define([
    'angular',
    './AdminController',
    './screens/screens',
    './client/client',
    './templates/templates',
    './goldsync-old/goldsync-old',
    './bizzHier/bizzhier',
    './processMap/procMapControl',
    './masterData/masterData',
    './notification/notification'
], function () {
    'use strict';
    return angular.module('app.admin', ['app.adminController','app.adminScreens','app.client','admin.notification'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
            $stateProvider
                .state('admin', {
                    url: '/admin',
                    templateUrl: 'app/components/admin/admin-tpl.html',
                    type:'custom',
                    api:{},
                    access: 4
                })
                // label/state/id name changed from gold sync to BU/LE
                .state('admin.bu-le', {
                    url: '/bu-le',
                    templateUrl: 'app/components/admin/goldsync-old/index.html',
                    type:'custom',
                    backdrop: 'static',
                    api:{},
                    access: 4
                })
                .state('admin.bizz-hier', {
                    url: '/bizzHier',
                    templateUrl: 'app/components/admin/bizzHier/bizz-hier-tpl.html',
                    controller: 'bizzHierCtrl as ctrl',
                    type:'custom',
                    backdrop: 'static',
                    api:{},
                    access: 4
                })

                .state('admin.gold-sync', {
                    url: '/gold-sync',
                    templateUrl: 'app/components/admin/goldSync/gold-sync.html',
                    controller: 'goldSyncCtrl as ctrl',
                    type: 'custom',
                    backdrop: 'static',
                    api: {},
                    access: 4
                })

                .state('admin.system-settings', {
                    url: '/system-settings',
                    templateUrl: 'app/components/admin/system-settings-tpl.html',
                    controller: 'SystemSettingsController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.jdc-manager', {
                    url: '/jdc-manager',
                    templateUrl: 'app/components/admin/jdcManager/jdc-manager-tpl.html',
                    controller: 'JdcManagerController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.client', {
                    url: '/client',
                    templateUrl: 'app/components/admin/client/client-tpl.html',
                    controller: 'ClientAdminController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.clob-insert', {
                    url: '/clob-insert',
                    "templateUrl": "app/components/admin/clob-insert-tpl.html",
                    "controller": "ClobInsertController as ctrl",
                    backdrop: 'static',
                    type:'grid',
                    access: 4
                })

                .state('admin.user-settings', {
                    url: '/user-settings',
                    "templateUrl": "app/components/grid/custom/grid-user-tpl.html",
                    "controller": "GirdUserController as ctrl",
                    backdrop: 'static',
                    type:'grid',
                    "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=4&grid_id=3&tax_year=2014"},
                    "noFilters":true,
                    access: 4
                })

                .state('admin.email-settings', {
                    url: '/email-settings',
                    templateUrl: ' app/components/admin/email-settings-tpl.html',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.cache-control', {
                    url: '/cache-control',
                    templateUrl: 'app/components/admin/cacheControl/cache-tabs.html',
                    controller: 'cacheController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.template-assign', {
                    url: '/template-assign',
                    templateUrl: 'app/components/admin/templateAssign/template-assign-tabs.html',
                    controller: 'templateAssignController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 3
                })

				.state('admin.admin-filters', {
					url: '/admin-filters',
					templateUrl: 'app/components/admin/adminFilters/admin-filters.html',
                    controller: 'adminFiltersController as ctrl',
                    backdrop: 'static',
					type:'grid',
					api:{"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=1102&grid_id=230","grid_id":"230"},
                    "noFilters":true,
				})

                .state('admin.tax-placemat', {
                    url: '/tax-placemat',
                    templateUrl: 'app/components/admin/processMap/process-map-tpl.html',
                    controller: 'processMapController as ctrl',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.master-data', {
                    url: '/master-data',
                    templateUrl: 'app/components/admin/masterData/index.html',
                    backdrop: 'static',
                    type:'custom',
                    api:{},
                    access: 4
                })

                 .state('admin.regeneratedata', {
                    url: '/regeneratedata',
                    templateUrl: 'app/components/admin/firForm/regenerateFirForm.html',
                    backdrop: 'static',
                    controller: 'regenerateFirFormController as ctrl',
                    type:'custom',
                    api:{},
                    access: 4
                })

                .state('admin.system-message', {
                    url: '/system-message',
                    templateUrl: 'app/components/admin/systemMessage/system-message.html',
                    backdrop: 'static',
                    controller: 'systemMessageCtrl as ctrl',
                    type:'custom',
                    api:{},
                    access: 4
                })
                /* .state('admin.aggregation-trigger', {
                    url: '/aggregation-trigger',
                    templateUrl: 'app/components/admin/aggregationTrigger/aggregation-tpl.html',
                    backdrop: 'static',
                    controller: 'aggregationController as ctrl',
                    type:'custom',
                    api:{},
                    access: 5
                }) */
                // .state('admin.notification', {
                //     url: '/notification',
                //     templateUrl: 'app/components/admin/notification/notification-tpl.html',
                //     backdrop: 'static',
                //     // controller: ' as ctrl',
                //     type:'custom',
                //     api:{},
                //     access: 4
                // })


        }]);
});
