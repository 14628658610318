define(
    ['angular'],
    function(){
        'use strict';
        let services =  angular.module('app.deltaPostingService',[])
            .factory('deltaPostingServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService', '$injector','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, $injector, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){

                let deltaPostingServiceFactory = {};
                deltaPostingServiceFactory.url = {};

                deltaPostingServiceFactory.triggerDeltaPosting = function(settings,data) {

                    deltaPostingServiceFactory.url.post = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=pl2nzf";

                    let service      = $injector.get('JsonObjectFactory');
                    let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    let jsonSettings = JSON.stringify(settings);
                    let jsonObj      = JSON.stringify(data);

                    let params          = {};

                    params.tax_year     = filterParams.tax_year;
                    params.scenario     = filterParams.scenario;
                    params.jcd_key      = GlobalService.globalParams.jcd_key;
                    params.sso_id       = settings.sso_id;
                    params = _.extend({
                        clob_data: jsonObj
                    }, params);
                    params = _.extend({
                        clob_settings: jsonSettings
                    }, params)

                    let promise = service.saveJSON(deltaPostingServiceFactory.url.post,params).then(
                        function (response) {
                            if(response.callSuccess === "1"){
                                return response;
                            }
                            else {
                                return response
                            }
                        },
                        function(response){
                            return response;
                        }
                    );
                    return promise;
                };
                return deltaPostingServiceFactory;
            }])
        return services;
    }
);