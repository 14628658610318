define([
   'angular'

], function () {
   'use strict';

    var services =  angular.module('app.Form5471-textInfoService',[])
        .factory("form5471ScheduleGGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
            ,'$q', '$http',
            '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

            ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                ,$q, $http,$stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
                var factory = {};
                var returnObj = {};
                var form5471ScheduleGGroupObjFactory ={};
                form5471ScheduleGGroupObjFactory.url ={};
                form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_NA = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=eu8fpf";
                form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_G = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vlm7h1";
                form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_I = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wo6vaz";

                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleG = function(_data,Details){

                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonSettings =  JSON.stringify(_data);
                    var jsonObj = JSON.stringify(Details);
                    //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    //               params =  _.extend({jsonSettings:jsonSettings}, _data);
                    params.process_name =  "Form_5471_Schedule_G_Save";
                    var promise = $http({
                        method: "post",
                        url: form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_G,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });

                    return promise;
                };
                
                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleNA = function(_data,Details){

                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonSettings =  JSON.stringify(_data);
                    var jsonObj = JSON.stringify(Details);
                    //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    //               params =  _.extend({jsonSettings:jsonSettings}, _data);
                    params.process_name =  "Form_5471_Schedule_NA_Save";
                    var promise = $http({
                        method: "post",
                        url: form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_NA,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });

                    return promise;
                };
                
                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleI = function(_data,Details){

                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonSettings =  JSON.stringify(_data);
                    var jsonObj = JSON.stringify(Details);
                    //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    //               params =  _.extend({jsonSettings:jsonSettings}, _data);
                    params.process_name =  "Form_5471_Schedule_I_Save";
                    var promise = $http({
                        method: "post",
                        url: form5471ScheduleGGroupObjFactory.url.save_Form_5471_Schedule_I,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });

                    return promise;
                };
                
                return form5471ScheduleGGroupObjFactory;
            }]);

   return services;
});