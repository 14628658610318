define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.taxAttributesService', [])

        .factory('TaxAttributesServiceFactory', ['$q', '$http', '$log', '$rootScope', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'GENERAL_CONFIG', function ($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG) {
            var taxAttributesServiceFactory = {};
            taxAttributesServiceFactory.url = {};
            taxAttributesServiceFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=dadsmj";
            taxAttributesServiceFactory.url.ta_entity_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=8xzfoo";
            taxAttributesServiceFactory.url.delete_util_amount = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=02dmm7";
            taxAttributesServiceFactory.url.save_posting_entity = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gh55zt";

            taxAttributesServiceFactory.saveTaxAttributes = function (_data, Details) {

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);

                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.process_name = "Tax-Attributes-grid-save";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: taxAttributesServiceFactory.url.defaults_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;

            };

            taxAttributesServiceFactory.saveTaxAttributesEntityDtls = function (_data, Details) {

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings

                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.process_name = "Tax-Attributes-entity-save";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: taxAttributesServiceFactory.url.ta_entity_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };


            taxAttributesServiceFactory.deleteUtilDtls = function (_data, Details) {

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings

                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.process_name = "Tax-Attributes-util-delete";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: taxAttributesServiceFactory.url.delete_util_amount,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            taxAttributesServiceFactory.savePostingEntity = function (_data, Details) {

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                var params = filterParams;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params["sso_id"] = _data.sso_id;
                params.process_name = "Tax-Attributes-posting-entity-save";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: taxAttributesServiceFactory.url.save_posting_entity,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            return taxAttributesServiceFactory
        }])

    return services;

});
