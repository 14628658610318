
define([
    'angular'

], function () {
    'use strict';

    var services = angular.module('app.Form1118SchdJService', [])

        .factory('Form1118SchdJFactory', ['$q', '$http', '$log', '$rootScope', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'GENERAL_CONFIG',
        function ($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG) {

            var Form1118SchdJFactory = {};
            Form1118SchdJFactory.url = {};
            Form1118SchdJFactory.url.amounts_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ng6tfg";

            Form1118SchdJFactory.saveBasketAmounts = function (_data, Details, tax_year, scenario, filer_le_key, filer_combination_key, jcd_key) {
                console.log("===============     Calling Service using Form 1118 Schd-J Factory  =========================", _data);

                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                var isIssueIdEnabled =  GlobalService.isIssueIDEnabled(tax_year,scenario);      
                console.log(jsonSettings);

                setLockObj(filer_combination_key);

                var params = {};
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.merge({}, isIssueIdEnabled, params);    
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.jcd_key = jcd_key;
                params.tax_year = tax_year;
                params.scenario = scenario;
                params.filer_entity_key = filer_le_key;
                console.log("Save params :", params);

                var promise = $http({
                    method: "post",
                    url: Form1118SchdJFactory.url.amounts_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            function setLockObj(filer_combination_key) {
                var checkLockObj =[];
                checkLockObj.push({combination_key:filer_combination_key});
                var lockObj = {};
                lockObj.lockingObj=checkLockObj;	
                if(lockObj){
                    GlobalService.modalLockFilters(lockObj.lockingObj); /*  for locking Object */
                }
            }
            return Form1118SchdJFactory;
        }])

    return services;



});
