define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.trialBalanceService', [])
    .factory("TbFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
        var tblFactory = {};
        tblFactory.url = {};
        tblFactory.url.savetrialbalance  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=glr5xa";//"saveJsonObject.ge?action_code=glr5xa";

       
        // reclass changes start
        
        tblFactory.getActiveScreen = function() {
            return workspaceFactory.activeScreen.screen_key;
        };
        
        tblFactory.getData = function (_url,_params) {
            console.log("TEST/////////////////////////////////////////////////");
        };

        
        
        var reclassFactory = {};
        reclassFactory.url = {};
        tblFactory.url.saveReclass  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=xqtzyo";
        tblFactory.url.deleteReclass = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=aemd60";
        
        
        
        tblFactory.saveReclass = function(_data,_accounts){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(_accounts);
            var jsonSettings =  JSON.stringify(_data);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            //params =  _.extend({jsonSettings:jsonSettings}, _data);
            params.process_name =  "RECLASS_SAVE_LOCAL_ACCT";
            console.log("reclass params to save");
            console.log(params);
            var promise = $http({
                method: "post",
                url: tblFactory.url.saveReclass,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response.data;
            });

            return promise;

        };
        
        
        
        tblFactory.deleteReclass = function(_data, deleteObj) {
            //94   reclassFactory.url.deleteReclass
            console.log('_data passed to delete reclass function');
            console.log(_data);
            var jsonSettings = {};
            var jsonObj = JSON.stringify(deleteObj);
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

              var params = filterParams;
              params = _.merge({},  GlobalService.globalParams , params);
              params =  _.extend({}, _data, params);
              params =  _.extend({jsonSettings:jsonSettings}, params);
              params =  _.extend({jsonObj:jsonObj}, params);
              
              console.log("reclass params to delete");
              console.log(params);
              var promise = $http({
                  method: "post",
                  url: tblFactory.url.deleteReclass,
                  data: params
              }).then(function (response) {
                  var status = response.status;
                  return response.data;
              });

              return promise;

          };
        
        // reclass changes end
        
        
        
        
        
        
        tblFactory.saveTb = function(_data,_accounts, sourceSystem){
           if(sourceSystem == 'FIR')
        	   tblFactory.url.savetrialbalance  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=iv9fni";
           else
        	   tblFactory.url.savetrialbalance  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=glr5xa";
        		   
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(_accounts);
            var jsonSettings =  JSON.stringify(_data);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            //params =  _.extend({jsonSettings:jsonSettings}, _data);
            params.process_name =  "TB_SAVE";
            console.log(params);
            var promise = $http({
                method: "post",
                url: tblFactory.url.savetrialbalance,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response.data;
            });

            return promise;

        };
        
      

        return tblFactory;
    }]);


return services;


});
