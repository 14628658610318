define([
    'angular'

], function () {
    'use strict';


    var controllers = angular.module('app.F4626NewController', [])
        .controller('F4626NewController', ['GlobalService', 'JsonObjectFactory', '$parse', '$scope', '$rootScope', '$timeout',
            'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4626NewFactory', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', F4626NewController])
        .controller('F4626NewControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse', '$scope', '$rootScope', '$timeout',
            'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4626NewFactory', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', F4626NewControllerModal]);

    function F4626NewController(GlobalService, JsonObjectFactory, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $filter, $q, AlertService, USER_SETTINGS, GENERAL_CONFIG, F4626NewFactory, ModalFactory, uibModalInstance, rowData, colData, gridData) {
        F4626NewControllerModal(GlobalService, JsonObjectFactory, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $filter, $q, AlertService, USER_SETTINGS, GENERAL_CONFIG, F4626NewFactory, ModalFactory, uibModalInstance, rowData, colData, gridData, this);
    }


    function F4626NewControllerModal(GlobalService, JsonObjectFactory, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $filter, $q, AlertService, USER_SETTINGS, GENERAL_CONFIG, F4626NewFactory, ModalFactory, $uibModalInstance,
        rowData, colData, gridData, thisObj) {
        console.log("------------At the start-----------");


        var vm = this || thisObj;
        vm.f4626Header = [];
        vm.f4626List = [];
        vm.f4626OriginalData = [];
        vm.f4626FormData = [];
        vm.f4626ColumnHeader = [];
        vm.f4626FinalData = [];
        vm.f4626LHData = [];
        var LH_index = 0;
        vm.unAllocatedAmt = 0;
        vm.crudLoading = false;
        vm.f4626CorpDetails = [];
        vm.selectedTabName = "Part I";
        vm.currentPart = "P1";
        vm.currentSchedule = "N/A";
        vm.rowData = rowData;
        var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.curentyear = filterParams.tax_year;
        vm.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };
        vm.startDate = new Date("01/01/" + "2019");
        vm.endDate = new Date("12/31/" + vm.curentyear);
        var data = vm.rowData;
        vm.loading = false;
        vm.onTabChange = function (e) {
            vm.selectedTabName = e;
            switch (e) {
                case "Part I":
                    vm.currentPart = "P1";
                    vm.currentSchedule = "N/A";
                    break;

                case "Part II":
                    vm.currentPart = "P2";
                    vm.currentSchedule = "N/A";
                    break;
                case "Part III":
                    vm.currentPart = "P3";
                    vm.currentSchedule = "N/A";
                    break;
                case "Section 1":
                    vm.currentPart = "P4";
                    vm.currentSchedule = "1";
                    break;

                case "Section 3":
                    vm.currentPart = "P4";
                    vm.currentSchedule = "3";
                    break;
                case "Schedule B":
                    vm.currentPart = "NA";
                    vm.currentSchedule = "B";
                    break;
                case "Schedule C":
                    vm.currentPart = null;
                    vm.currentSchedule = "C";
                    break;

                default:
                    break;
            }
            vm.f4626List = [];
            vm.f4626OriginalData = [];
            vm.f4626FormData = [];
            vm.f4626ColumnHeader = [];
            vm.f4626FinalData = [];
            vm.f4626LHData = [];
            vm.CountriesList = {};
            vm.unAllocatedAmt = 0;
            if (vm.currentSchedule === "B") {
                fetchCountryList();
            }

            getf4626Data();
            $timeout(function () {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 50);
        };
        getf4626Data();
        function getf4626Data() {
            vm.loading = true;
            F4626NewFactory.fetch4626Details(data, vm.currentPart, vm.currentSchedule).then((data) => {
                if (data.length > 0) {

                    if (data[0].ROW_TYPE === 'FH') {
                        vm.f4626Header = data[0];
                        vm.f4626List = data.slice(1);
                    }
                    else {
                        vm.f4626List = data;
                    }
                    vm.formHeader = $filter("filter")(data, { ROW_TYPE: "FH" });
                    console.log(vm.formHeader)

                    var o = vm.f4626List.reduce((a, b) => {
                        a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                        a[b.LINE_ORDER].push(b);
                        return a;
                    }, {});

                    var a = Object.keys(o).map(function (k) {
                        return { ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k] };
                    });

                    vm.f4626FormData = a;
                    console.log(vm.f4626FormData);
                    LH_index = vm.f4626FormData.findIndex(x => x.ROW_TYPE == "LH");
                    console.log("The index of the LH value is ::::::::::::::::::::", LH_index);
                    var n = vm.f4626FormData.reduce((a, b) => {
                        //console.log("The value of ROW_TYPE is :::::::::::::::::::::::", a[b.ROW_TYPE], b.ROW_TYPE);
                        //if(b.ROW_TYPE == "LH" && a[b.ROW_TYPE] != undefined){
                        a[b.ROW_TYPE] = a[b.ROW_TYPE] || [];
                        a[b.ROW_TYPE].push(b);
                        return a;
                        //}
                    }, {});


                    console.log("The value before making keys and after reduce is :::::::::", n);

                    var b = Object.keys(n).map(function (k) {
                        //console.log("The value of k after reduce before Map is :::::::::::::::::::::::", k, n[k]);
                        if (k == 'LH') {
                            return { ROW_TYPE: n[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: n[k] };
                        }
                    });
                    console.log("The value of form data after reducing LH data was ::::::::::", b);
                    console.log("The 4626 form data before modifications was :::::::::::::", vm.f4626FormData);

                    for (var i = vm.f4626FormData.length - 1; i >= 0; i--) {
                        if (vm.f4626FormData[i].ROW_TYPE == 'LH') {
                            vm.f4626FormData.splice(i, 1);
                        }
                    }

                    console.log("The 4626 form data in between modifications was :::::::::::::", vm.f4626FormData);

                    for (var g = 0; g < b.length; g++) {
                        if (b[g] != undefined) {
                            for (var h = 0; h < b[g].Line_data.length; h++) {
                                if (b[g].Line_data[h].Line_data[0].OCCURRENCE != null) {
                                    var z = b[g].Line_data[h].Line_data.reduce((a, b) => {
                                        a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
                                        a[b.OCCURRENCE].push(b);
                                        return a;
                                    }, {});

                                    var y = Object.keys(z).map(function (k) {
                                        return { ROW_TYPE: z[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: z[k] };
                                    });

                                    //b[g].Line_data[h].Line_data = angular.copy(y);
                                    b[g].Line_data.splice(h, 1, y[0]);
                                    if (y.length > 0) {
                                        for (var a = 1; a < y.length; a++) {
                                            b[g].Line_data.splice(h + a, 0, y[a]);
                                        }
                                    }
                                }
                            }
                            vm.f4626LHData = b[g];
                        }
                    }

                    vm.f4626FormData.splice(LH_index, 0, vm.f4626LHData);
                    console.log("The 4626 form data after modifications was :::::::::::::", vm.f4626FormData);

                    var c = [];

                    for (var i = 0; i < vm.f4626FormData.length; i++) {
                        if (vm.f4626FormData[i].ROW_TYPE == 'DT' || vm.f4626FormData[i].ROW_TYPE == 'TH') {
                            for (var j = 0; j < vm.f4626FormData[i].Line_data.length; j++) {
                                if (vm.f4626FormData[i].Line_data[j].DATA_TYPE == "dateVarious" && vm.f4626FormData[i].Line_data[j].EDIT_TYPE == "custom_date_various") {
                                    vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = new Date(vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE);
                                }
                                if (vm.f4626FormData[i].Line_data[j].EDIT_TYPE == 'label' && vm.f4626FormData[i].Line_data[j].DATA_TYPE == 'string' && vm.f4626FormData[i].Line_data[j].IS_EDITABLE == 'Y') {
                                    vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = vm.f4626FormData[i].Line_data[j].ATTRIB_DESCRIPTION;
                                }
                                if (vm.f4626FormData[i].Line_data[j].EDIT_TYPE == 'checkbox' && vm.f4626FormData[i].Line_data[j].DATA_TYPE == 'string' && vm.f4626FormData[i].Line_data[j].IS_EDITABLE == 'Y') {
                                    if (vm.f4626FormData[i].Line_data.length > 3) {
                                        // vm.f4626FormData[i].Line_data[j].CHECKBOX_LABELS = [vm.f4626FormData[i].Line_data[j].ATTRIB_DESCRIPTION, vm.f4626FormData[i].Line_data[j + 1].ATTRIB_DESCRIPTION];
                                        // var temp = vm.f4626FormData[i].Line_data.filter(item => (item.EDIT_TYPE !== 'text' && item.DATA_TYPE !== 'string'));
                                        // vm.f4626FormData[i].Line_data = vm.f4626FormData[i].Line_data.splice(0, 3);
                                        // if (temp.length) {
                                        //     vm.f4626FormData[i].Line_data.push(temp[0]);
                                        // }

                                        // console.log(vm.f4626FormData[i].Line_data);
                                        continue;
                                    }

                                }
                                if (vm.f4626FormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.f4626FormData[i].Line_data[j].DATA_TYPE == 'number' && vm.f4626FormData[i].Line_data[j].IS_EDITABLE == 'Y') {
                                    vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE);
                                }
                            }
                        } else if (vm.f4626FormData[i].ROW_TYPE == 'LH') {
                            for (var j = 0; j < vm.f4626FormData[i].Line_data.length; j++) {
                                for (var l = 0; l < vm.f4626FormData[i].Line_data[j].Line_data.length; l++) {
                                    if (vm.f4626FormData[i].Line_data[j].Line_data[l].IS_EDITABLE == 'Y' && (vm.f4626FormData[i].Line_data[j].Line_data[l].EDIT_TYPE == 'label' || vm.f4626FormData[i].Line_data[j].Line_data[l].EDIT_TYPE == 'text') && vm.f4626FormData[i].Line_data[j].Line_data[l].DATA_TYPE == 'string' && vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE === undefined) {
                                        vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE = vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_DESCRIPTION;
                                    }
                                    if (vm.f4626FormData[i].Line_data[j].Line_data[l].IS_EDITABLE == 'Y' && vm.f4626FormData[i].Line_data[j].Line_data[l].EDIT_TYPE == 'currency' && vm.f4626FormData[i].Line_data[j].Line_data[l].DATA_TYPE == 'number') {
                                        vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE = parseInt(vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE);
                                    }
                                    if (vm.f4626FormData[i].Line_data[j].Line_data[l].IS_EDITABLE == "Y" && vm.f4626FormData[i].Line_data[j].Line_data[l].DATA_TYPE == "dateVarious" && vm.f4626FormData[i].Line_data[j].Line_data[l].EDIT_TYPE == "custom_date_various") {
                                        vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE = new Date(vm.f4626FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE);
                                    }
                                }
                            }
                        }
                    }

                    if (vm.f4626FormData[0].Line_data && vm.f4626FormData[0].Line_data[0].ROW_TYPE === 'PH') {
                        vm.f4626CorpDetails = vm.f4626FormData[0];
                        vm.f4626FormData.splice(i, 1);
                    }

                    vm.f4626OriginalData = angular.copy(vm.f4626FormData);
                    vm.loading = false;
                    console.log("Copy of original data to a variable :::::::::::", vm.f4626OriginalData);
                }
                else {
                    vm.loading = false;
                    console.log("No data found");
                    AlertService.add("error", "No data found!");
                }
            })
        }

        vm.cancel = function () {
            console.log("Form 4626 data from DB is :::::::::::", vm.f4626FormData);
            console.log("Form 4626 data from original Data is saved in :::::::::::", vm.f4626OriginalData);

            vm.f4626FormJSONParseData = JSON.parse(angular.toJson(vm.f4626FormData));
            console.log("Form 4626 data after JSON is :::::::::::", vm.f4626FormJSONParseData);
            if (_.isEqual(vm.f4626OriginalData, vm.f4626FormJSONParseData)) {
                $uibModalInstance.dismiss('cancel');
            } else {
                if (vm.crudLoading) {
                    AlertService.add("info", "The transaction is still in progress", 4000);
                    vm.cancelModal();
                }
                else if (confirm("Tax on Base Erosion Payments of Taxpayers with Substantial Gross Receipts is not saved. Do you want to close Form without saving changes?")) {
                    $uibModalInstance.dismiss('cancel');
                }
            }
        };

        vm.valuechanged = function (parent_index, occurrence, line_no, index) {
            console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index);
            for (var i = 0; i < vm.f4626FormData.length; i++) {
                if (vm.f4626FormData[i].ROW_TYPE == 'CH') {
                    if (vm.f4626FormData[i].Line_data[parent_index] != null && vm.f4626FormData[i].Line_data[parent_index] != undefined) {
                        if (vm.f4626FormData[i].Line_data[parent_index].Line_no == line_no && vm.f4626FormData[i].Line_data[parent_index].Occurrence == occurrence) {
                            vm.f4626FormData[i].Line_data[parent_index].Line_data_occ[index + 1].IS_CHANGED_FLAG = "Y";
                            vm.f4626FormData[i].Line_data[parent_index].Line_data_occ[index + 1].HAS_ERROR = "N";
                        }
                    }
                } else if (vm.f4626FormData[i].ROW_TYPE == 'DT' || vm.f4626FormData[i].ROW_TYPE == 'RT' || vm.f4626FormData[i].ROW_TYPE == 'TH' || vm.f4626FormData[i].ROW_TYPE == 'MD') {
                    if (i == parent_index && vm.f4626FormData[i].Line_data[index] != null && vm.f4626FormData[i].Line_data[index] != undefined) {
                        vm.f4626FormData[i].Line_data[index].IS_CHANGED_FLAG = "Y";
                    }
                } else if (vm.f4626FormData[i].ROW_TYPE == 'LH') {
                    if (vm.f4626FormData[i].Line_data[parent_index] != null && vm.f4626FormData[i].Line_data[parent_index] != undefined) {
                        if (vm.f4626FormData[i].Line_data[parent_index].Line_data[index] != null && vm.f4626FormData[i].Line_data[parent_index].Line_data[index].LINE_NO == line_no) {
                            vm.f4626FormData[i].Line_data[parent_index].Line_data[index].IS_CHANGED_FLAG = "Y";
                        }

                    }
                }
            }
            console.log(vm.f4626FormData);
        }

        vm.updateAmount = function (index, line_no) {
            vm.calcFinalAmounts();
        }

        vm.calcFinalAmounts = function () {
            for (var i = 0; i < vm.f4626FormData.length; i++) {
                if (vm.f4626FormData[i].ROW_TYPE == 'LH') {
                    for (var j = 0; j < vm.f4626FormData[i].Line_data.length; j++) {
                        for (var k = 0; k < vm.f4626FormData[i].Line_data[j].Line_data.length; k++) {
                            if (vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_CALC_FORMULA != null) {
                                vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_VALUE = 0;
                                // vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_VALUE = vm.calcAmt(vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_CALC_FORMULA, vm.f4626FormData[i].Line_data[j].Line_data[k], vm.f4626FormData[i].Line_data[j].Line_data[k].LINE_ATTRIB_KEY, vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_COL, vm.f4626FormData[i].Line_data[j].Line_data[k].EDIT_TYPE);
                                vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_VALUE = vm.calc_final_amt(vm.f4626FormData[i].Line_data[j].Line_data[k].ATTRIB_CALC_FORMULA);
                                vm.f4626FormData[i].Line_data[j].Line_data[k].IS_CHANGED_FLAG = "Y";
                            }
                        }

                    }
                }
                if (vm.f4626FormData[i].ROW_TYPE == 'DT' || vm.f4626FormData[i].ROW_TYPE == 'MD') {
                    for (var j = 0; j < vm.f4626FormData[i].Line_data.length; j++) {
                        if (vm.f4626FormData[i].Line_data[j].ATTRIB_CALC_FORMULA != null) {
                            vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = 0;
                            // vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = vm.calcAmt(vm.f4626FormData[i].Line_data[j].ATTRIB_CALC_FORMULA, vm.f4626FormData[i].Line_data[j], vm.f4626FormData[i].Line_data[j].LINE_ATTRIB_KEY, vm.f4626FormData[i].Line_data[j].ATTRIB_COL, vm.f4626FormData[i].Line_data[j].EDIT_TYPE);
                            vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = vm.calc_final_amt(vm.f4626FormData[i].Line_data[j].ATTRIB_CALC_FORMULA);
                            vm.f4626FormData[i].Line_data[j].IS_CHANGED_FLAG = "Y";
                        }
                    }
                }
            }
        }

        vm.calc_final_amt = function (formula) {
            formula = formula.replace(/\s+/g, '');
            //check if formula has multiplication
            const regex = /([A-Z0-9_]+)\*([0-9]+\/[0-9]+)/i;

            const match = formula.match(regex);

            if (match) {
                const extractedPart1 = match[1];
                const extractedPart2 = match[2];
                for (var k = 0; k < vm.f4626FormData.length; k++) {
                    if (vm.f4626FormData[k].ROW_TYPE == 'LH') {
                        for (var j = 0; j < vm.f4626FormData[k].Line_data.length; j++) {
                            var row_data = vm.f4626FormData[k].Line_data[j].Line_data;
                            var currentTabAtrribVal = $filter("filter")(row_data, { ATTRIB_SHORT_CODE: extractedPart1 });
                            if (null != currentTabAtrribVal && currentTabAtrribVal.length > 0) {
                                return currentTabAtrribVal[0].ATTRIB_VALUE * eval(extractedPart2);
                            }
                        }
                    } else {
                        var row_data = vm.f4626FormData[k].Line_data;
                        var currentTabAtrribVal = $filter("filter")(row_data, { ATTRIB_SHORT_CODE: extractedPart1 });
                        if (null != currentTabAtrribVal && currentTabAtrribVal.length > 0) {
                            if(currentTabAtrribVal[0].IS_ZERO_OR_GREATER == 'Y' && currentTabAtrribVal[0].ATTRIB_VALUE < 0){
                                return 0;
                            }
                            return currentTabAtrribVal[0].ATTRIB_VALUE * eval(extractedPart2);
                        }
                    }
                }

            }
            else {
                var lineFormulaArray = formula.split(/[+-/\\*\\]/);
                for (var i = 0; i < lineFormulaArray.length; i++) {
                    var lineAmt = 0;
                    for (var k = 0; k < vm.f4626FormData.length; k++) {
                        if (vm.f4626FormData[k].ROW_TYPE == 'LH') {
                            for (var j = 0; j < vm.f4626FormData[k].Line_data.length; j++) {
                                var row_data = vm.f4626FormData[k].Line_data[j].Line_data;
                                var currentTabAtrribVal = $filter("filter")(row_data, { ATTRIB_SHORT_CODE: lineFormulaArray[i].trim() });
                                if (null != currentTabAtrribVal && currentTabAtrribVal.length > 0) {
                                    lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
                                }
                            }
                        } else {
                            var row_data = vm.f4626FormData[k].Line_data;
                            var currentTabAtrribVal = $filter("filter")(row_data, { ATTRIB_SHORT_CODE: lineFormulaArray[i].trim() });
                            if (null != currentTabAtrribVal && currentTabAtrribVal.length > 0) {
                                lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
                            }
                        }
                    }
                    if (parseInt(lineAmt) > 0) {
                        formula = formula.replace(lineFormulaArray[i].trim(), lineAmt);
                    } else {
                        formula = formula.replace(lineFormulaArray[i].trim(), lineAmt);
                    }

                }
    
                if (null != eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {
                    console.log(eval(formula));
                    return eval(formula);
                } else return 0;
            }
            
        }

        vm.checkBoxHandler = function(item, row, index) {
            switch (index) {
                case 2:
                    if(item.ATTRIB_VALUE == "Y") {
                        row.Line_data[3].ATTRIB_VALUE = "N"
                        row.Line_data[3].IS_CHANGED_FLAG = "Y"
                    } else {
                        row.Line_data[3].ATTRIB_VALUE = "Y"
                        row.Line_data[3].IS_CHANGED_FLAG = "Y"
                    }

                    break;
                case 3:
                    if(item.ATTRIB_VALUE == "Y") {
                        row.Line_data[2].ATTRIB_VALUE = "N"
                        row.Line_data[2].IS_CHANGED_FLAG = "Y"
                    } else {
                        row.Line_data[2].ATTRIB_VALUE = "Y"
                        row.Line_data[2].IS_CHANGED_FLAG = "Y"
                    }
                    break
                default:
                    break;
            }
        }

        vm.save = function () {
            vm.finalData = [];
            vm.clob_settings = [];
            console.log(vm.f4626FormData);
            console.log("Original Data was ::::::::==========", vm.f4626OriginalData);
            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.f4626FinalData = angular.copy(vm.f4626FormData);
            if (angular.equals(vm.f4626FormData, vm.f4626OriginalData)) {
                vm.cancel();
            } else {
                if (vm.unAllocatedAmt != 0) {
                    if (!confirm("Total Depreciation Expense Per Tax Return is not fully adjusted. Confirm to save the changes.")) {
                        return false;
                    }
                }
                for (var x = 0; x < vm.f4626FinalData.length; x++) {
                    if (vm.f4626FinalData[x].ROW_TYPE == 'TH' || vm.f4626FinalData[x].ROW_TYPE == 'DT' || vm.f4626FinalData[x].ROW_TYPE == 'RT' || vm.f4626FinalData[x].ROW_TYPE == 'MD') {
                        for (var j = 0; j < vm.f4626FinalData[x].Line_data.length; j++) {
                            if (vm.f4626FinalData[x].Line_data[j].ATTRIB_VALUE && vm.f4626FinalData[x].Line_data[j].EDIT_TYPE === 'custom_date_various' && vm.f4626FinalData[x].Line_data[j].DATA_TYPE === 'dateVarious') {
                                vm.f4626FinalData[x].Line_data[j].ATTRIB_VALUE = getDateFormat(vm.f4626FinalData[x].Line_data[j].ATTRIB_VALUE);
                            }
                            vm.finalData.push(vm.f4626FinalData[x].Line_data[j]);
                        }
                    }
                    if (vm.f4626FinalData[x].ROW_TYPE == 'LH') {
                        for (var j = 0; j < vm.f4626FinalData[x].Line_data.length; j++) {
                            for (var l = 0; l < vm.f4626FinalData[x].Line_data[j].Line_data.length; l++) {
                                if (vm.f4626FinalData[x].Line_data[j].Line_data[l].ATTRIB_VALUE && vm.f4626FinalData[x].Line_data[j].Line_data[l].EDIT_TYPE === 'custom_date_various' && vm.f4626FinalData[x].Line_data[j].Line_data[l].DATA_TYPE === 'dateVarious') {
                                    vm.f4626FinalData[x].Line_data[j].Line_data[l].ATTRIB_VALUE = getDateFormat(vm.f4626FinalData[x].Line_data[j].Line_data[l].ATTRIB_VALUE);
                                }
                                vm.finalData.push(vm.f4626FinalData[x].Line_data[j].Line_data[l]);
                            }
                        }
                    }
                }
                vm.finalData = vm.finalData.filter(function (el) {
                    return el.IS_CHANGED_FLAG === "Y";
                });
                console.log("After changes, the final data was :::::::::::=========", vm.f4626FinalData);
                console.log("After changes, the manipulated data was :::::::::::=========", vm.f4626FormData);
                console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
                vm.clob_settings = [{ sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key }];
                sendDetails(vm.finalData, vm.clob_settings);
            }
        }

        function sendDetails(final_data, clob_settings) {
            var message = "Form 4626 Details are successfully updated."
            vm.crudLoading = true;
            F4626NewFactory.saveForm4626(final_data, clob_settings, vm.currentPart, vm.currentSchedule, vm.rowData).then(function (data) {
                console.log("Result from data was ---------------::::", data);
                if (data.errorMessage != 'null') {
                    vm.crudLoading = false;
                    console.log("There was the error message and the message is ", data.errorMessage);
                    AlertService.add("error", data.errorMessage, 4000);
                } else {
                    vm.crudLoading = false;
                    console.log("There was no error message and the message was ", data.errorMessage);
                    AlertService.add("success", message, 4000);
                    $uibModalInstance.dismiss('cancel');
                    var args = {};
                    $rootScope.$emit('gridUpdate', args);
                }
            });
        }

        function fetchCountryList() {
            var params = {
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=kgdgoh').then(function (data) {
                vm.allCountries = [];
                vm.CountriesList = {};
                data.jsonObject.forEach(function (country, index) {
                    if (country.COUNTRY_CODE == "US") {
                        vm.USCountry_index = index;
                    }
                    var obj = {}
                    obj.LABEL = country.COUNTRY_DESC;
                    obj.VALUE = country.COUNTRY_CODE;
                    vm.allCountries.push(obj);
                    //vm.allCountries.push({"id":country.COUNTRY_CODE,"label":country.COUNTRY_DESC});
                });
                vm.CountriesList = {
                    param_name: "Countries",
                    values: vm.allCountries,
                    label_name: "label",
                    value_name: "value",
                    label: "Countries",
                    selected: []
                };
                console.log("vm.CountriesList**** ", vm.CountriesList);
            });
        }

        function getDateFormat(date_value) {
            console.log("The value of the date for the row that needs to convert is ::::::", date_value);
            if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date_value))
                return date_value;
            var date_selected = null;
            if (date_value != "VARIOUS") {
                date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
            } else date_selected = "VARIOUS";

            return date_selected;
        }
    }

});
