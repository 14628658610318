define([
    'angular',
    './ActionsCtrl'


], function () {
    'use strict';
    return angular.module('app.adminActions', ['app.actionCtrl'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
                .state('admin.actions-list', {
                    url: '/actions-list',
                    templateUrl: ' app/components/admin/actions/action-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=79&grid_id=33","grid_id":"33"},
                    access: "4"
                });
        }]);
});