define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.submissionDashboardService',[])
        .factory("submissionDashboardFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService',
        'DataFiltersFactory','JsonObjectFactory', 'GENERAL_CONFIG', 'workspaceFactory','USER_SETTINGS','AlertService' ,
         function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, JsonObjectFactory, GENERAL_CONFIG, workspaceFactory, USER_SETTINGS, AlertService) {
            var submissionDashboardFactory = {};
            submissionDashboardFactory.saveSignOff = function (_name,_data) {
                console.log("_data " , _data );
                console.log("USER_SETTINGS" , USER_SETTINGS.user.display_name)
                var rowData = _data.rowData;
                var clonedRowData = _.cloneDeep(_data.rowData);
                var today = new Date();
                var newDate = (today.getMonth()+1)+'/'+ today.getDate()+'/'+today.getFullYear();
                if( rowData.SIGNED_OFF === 1){
                    rowData.SIGNED_OFF_TI = rowData.TOTAL_TI_LINE_30;
                    rowData.SIGNED_OFF_TAX_ADJ = rowData.TOTAL_TAX_ADJ;
                    rowData.SIGNED_OFF_BY = USER_SETTINGS.user.display_name;
                    rowData.SIGNED_OFF_ON = newDate;
                }else{
                   rowData.SIGNED_OFF_TI = null;
                   rowData.SIGNED_OFF_TAX_ADJ = null;
                   rowData.SIGNED_OFF_BY = null;
                   rowData.SIGNED_OFF_ON = null;
                    rowData.SIGNED_OFF_DIFFRENCE = null;
                   // rowData.SIGNED_OFF_TI = clonedRowData.TOTAL_TI_LINE_30;
                   // rowData.SIGNED_OFF_TAX_ADJ = clonedRowData.TOTAL_TAX_ADJ;
                  //  rowData.SIGNED_OFF_BY = clonedRowData.SIGNED_OFF_BY;
                  //  rowData.SIGNED_OFF_ON = clonedRowData.SIGNED_OFF_ON;
                }
                //var data = {jsonObj:JSON.stringify(_schdMs)}
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=22l1fp";
                var data = {}
                data.combination_key = rowData.COMBINATION_KEY;
                data.sign_off = rowData.SIGNED_OFF;
                data.ti_amount = rowData.TOTAL_TI_LINE_30;
                data.ta_amount = rowData.TOTAL_TAX_ADJ;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , data);
                var paramsMerge= _.merge({},  GlobalService.globalParams , params);
                console.log("params " , paramsMerge);
                $http({
                    method: "post",
                    url: url,
                    data: paramsMerge
                }).then(function (response) {
                    var status = response.status;
                    if(response.data.callSuccess !== "1" ){
                        //AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                        AlertService.add("error", response.data.errorMessage, 4000);
                        //RESET VALUES
                        rowData.SIGNED_OFF_TI = clonedRowData.TOTAL_TI_LINE_30;
                        rowData.SIGNED_OFF_TAX_ADJ = clonedRowData.TOTAL_TAX_ADJ;
                        rowData.SIGNED_OFF_BY = clonedRowData.SIGNED_OFF_BY;
                        rowData.SIGNED_OFF_ON = clonedRowData.SIGNED_OFF_ON;
                        rowData.SIGNED_OFF = null;
                        $rootScope.$broadcast('gridRefresh', true);
                    }
                    return response.data;
                });
            };
            submissionDashboardFactory.addDormat =function(url,params){
               var promise= $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    return response;
                });
                return promise;
            }
            submissionDashboardFactory.removeDormat =function(_name,_data){
                console.log("I am on on remove",_name + "data",_data);
            }
            submissionDashboardFactory.getSummaryDiagList = function(taxYear, scenario, filingKey, parentMeString, system) {
                var promise = $http({
                    method: 'post',
                    url: GENERAL_CONFIG.pdf_engine_api_url + '/getDiagStatus?taxYear=' + taxYear + '&scenario=' + scenario + '&jcdKey=250' + '&filingKey=' + filingKey + '&meString=' + parentMeString + '&systemFlag=' + system + '&sso_id=' + USER_SETTINGS.user.sso_id
                });
                return promise;
            };
            return submissionDashboardFactory;
        }]);
    return services;
});
