define([
    'angular'

], function () {
    'use strict';

    let controllers = angular.module('app.Form8865SchdKControllers', [])
        .controller('Form8865SchdKController', ['GlobalService', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'JsonObjectFactory', '$filter', 'AlertService', '$timeout', '$uibModal', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'Form8865SchdKServicesFactory', '$uibModalInstance', 'rowData', Form8865SchdKController]);

    function Form8865SchdKController(GlobalService, $rootScope, $scope, $state, $log, GridFactory, JsonObjectFactory, $filter, AlertService, $timeout, $uibModal, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, Form8865SchdKServicesFactory, $uibModalInstance, rowData) {

        let vm = this;
        vm.title = "8865 Schedule K - Partners' Distributive Share Items";
        vm.showFormLoader = true;
        vm.F8865SchdKWPDDetailForEntity;

        let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        vm.userSettings = USER_SETTINGS;
        vm.sso_id = vm.userSettings.user.sso_id;

        vm.tax_year = rowData.TAX_YEAR;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;
        vm.leid = rowData.LEID;
        vm.le_name = rowData.LE_NAME;
        vm.combination_key = rowData.COMBINATION_KEY;
        vm.issue_key       = rowData.ISSUE_KEY;

        vm.userType = vm.userSettings.user.client.user_type_key;

        vm.entityList = {
            "Tax Year": vm.tax_year,
            "LEID": vm.leid,
            "LE Name": vm.le_name
        };

        vm.load8865SchdKWPDForEntity = function() {
            let params = {"action_code" : '54528e', "tax_year" : vm.tax_year, "scenario" : vm.scenario, "jcd_key": vm.jcd_key, "combination_key" : vm.combination_key};
            Form8865SchdKServicesFactory.load8865SchdKWPDForEntity(params).then(function(response){
                vm.F8865SchdKWPDDetailForEntity = response.jsonObject;
                console.log('vm.F8865SchdKWPDDetailForEntity = ', vm.F8865SchdKWPDDetailForEntity);
            });

        }

        vm.load8865SchdKWPDForEntity();

        vm.showFormLoader = false;

        vm.save = function() {
            let message;

            vm.clob_settings = {
                "tax_year": vm.tax_year,
                "scenario": vm.scenario,
                "jcd_key": vm.jcd_key,
                "combination_key": vm.combination_key,
                "sso_id": vm.sso_id,
                "issue_key": vm.issue_key
            };

            vm.clob_data = [];

            console.log('vm.clob_settings = ', vm.clob_settings);
            for(let x = 0; x < vm.F8865SchdKWPDDetailForEntity.length; x++) {
                let row = vm.F8865SchdKWPDDetailForEntity[x];
                if(row.IS_CHANGED == "Y") {
                    vm.clob_data.push(row);
                }
            }
            console.log('vm.clob_data = ', vm.clob_data);

            if(vm.clob_data.length > 0 ) {
                message = '8865 Schedule K white paper details saved successfully.';
                sendDetails(vm.clob_settings,vm.clob_data,message);
            }


        }

        function sendDetails(clobSettings, clobData, message) {

            Form8865SchdKServicesFactory.save8865SchdKWPDForEntity (clobSettings,clobData).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {
                    //vm.crudLoading = false;
                    AlertService.add("success", message, 4000);
                    var args = {
                        pship_leid: vm.PSHIP_LEID,
                        gridFilter: {
                            pship_leid: vm.PSHIP_LEID
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                };

            });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        vm.setChange = function(row) {
            row.IS_CHANGED = "Y";
        }
    }
});