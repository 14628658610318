define([
    'angular',
    './UsersCtrl',
    './UserAdminService',
    './UserAdminDirectives'


], function () {
    'use strict';
    return angular.module('app.adminUsers', ['app.usersCtrl','app.userAdminServices','app.userAdminDirectives'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
                .state('admin.users-list', {
                    url: '/users-list',
                    templateUrl: ' app/components/admin/users/users-list-tpl.html',
                    type:'',
                    backdrop: 'static',
                    onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                    api: {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=40&grid_id=3&tax_year=2014","grid_id":"3"},
                    access: 3
                })

                .state('admin.all-users-list', {
                    url: '/all-users-list',
                    templateUrl: 'app/components/admin/users/all-users-list-tpl.html',
                    type:'',
                    backdrop: 'static',
                    onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                    api: {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32994&grid_id=6042","grid_id":"3"},
                    access: 3
                })

                .state('admin.user-expiry-report', {
                    url: '/user-expiry-report',
                    templateUrl: 'app/components/admin/users/user-expiry-report-tpl.html',
                    type:'',
                    backdrop: 'static',
                    onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                    api: {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=33298&grid_id=6129","grid_id":"3"},
                    access: 3
                })

               .state("admin.users-list.user", {
                   params: {
                       data: null
                   },
                   onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
                       $uibModal.open({
                           templateUrl: 'app/components/admin/users/user-tpl.html',
                           controller: 'userCtrl as ctrl',
                           backdrop: 'static',
                           size:"lg",
                           windowClass: 'right fade'
                       }).result.finally(function() {
                           $state.go('admin.users-list');
                           $rootScope.$emit('gridRefresh');
                       });
                   }],
                    abstract: true
                })




                .state("admin.users-list.user.details", {
                    parent: 'admin.users-list.user',
                    access: 3,
                    views:{
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-details-tpl.html",
                            controller: 'userDetailCtrl as ctrl',
                            backdrop: 'static'
                        }
                    }

                })


            .state("admin.users-list.user.modules", {
                    access: 3,
                    views:{
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-modules-tpl.html",
                            controller: 'userModuleCtrl as ctrl',
                            backdrop: 'static'
                        }
                    }
            })


            .state("admin.users-list.user.business", {

                views:{
                    access: 3,
                    "modal@": {
                        templateUrl: "app/components/admin/users/user-business-tpl.html",
                        controller: 'userBusinessCtrl as ctrl',
                        backdrop: 'static'
                    }
                }
            })

            .state("admin.users-list.user.audit", {
                views:{
                    access: 3,
                    "modal@": {
                        templateUrl: "app/components/admin/users/user-audit-tpl.html",
                        controller: 'userAuditCtrl as ctrl',
                        backdrop: 'static',
                    }
                }
            })

            .state("admin.users-list.user.settings", {
                views:{
                    access: 3,
                    "modal@": {
                        templateUrl: "app/components/admin/users/user-settings-tpl.html",
                        controller: 'userSettingsCtrl as ctrl',
                        backdrop: 'static',
                    }
                }
            })


          //-------USER MODULES


            // .state('admin.users-list-module', {
            //     url: '/users-list-modul',
            //     templateUrl: ' app/components/admin/users/users-list-tpl.html',
            //     type:'',
            //     onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
            //     }],
            //     api: {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=74&grid_id=28","grid_id":"28"},
            //     access: 3
            // })

            .state('admin.users-list-module', {
                url: '/users-list-module',
                templateUrl: ' app/components/admin/users/users-list-new-tpl.html',
                type:'',
                onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
                }],
                api: {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32938&grid_id=6013","grid_id":"6013"},
                access: 3
            })



                .state("admin.users-list-module.user", {
                    params: {
                        data: null
                    },
                    onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
                        $uibModal.open({
                            templateUrl: 'app/components/admin/users/user-tpl.html',
                            controller: 'userCtrl as ctrl',
                            backdrop: 'static',
                            size:"lg",
                            windowClass: 'right fade'
                        }).result.finally(function() {
                            $state.go('admin.users-list-module');
                            $rootScope.$emit('gridRefresh');
                        });
                    }],
                    abstract: true
                })




                .state("admin.users-list-module.user.details", {
                    parent: 'admin.users-list-module.user',
                    access: 3,
                    views:{
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-details-tpl.html"+ "?bust=" + Math.random().toString(36).slice(2),
                            controller: 'userDetailCtrl as ctrl',
                            backdrop: 'static'

                        }
                    }

                })


                .state("admin.users-list-module.user.modules", {
                    parent: 'admin.users-list-module.user',
                    access: 3,
                    views:{
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-modules-tpl.html",
                            controller: 'userModuleCtrl as ctrl',
                            backdrop: 'static'
                        }
                    }
                })


                .state("admin.users-list-module.user.business", {
                    parent: 'admin.users-list-module.user',
                    views:{
                        access: 3,
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-business-tpl.html",
                            controller: 'userBusinessCtrl as ctrl',
                            backdrop: 'static'
                        }
                    }
                })


                .state("admin.users-list-module.user.settings", {
                    parent: 'admin.users-list-module.user',
                    views:{
                        access: 3,
                        "modal@": {
                            templateUrl: "app/components/admin/users/user-settings-tpl.html",
                            controller: 'userSettingsCtrl as ctrl',
                            backdrop: 'static'
                        }
                    }
                });




        }]);
});