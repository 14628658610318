/**
 * Created by 212544474 on 4/1/2016.
 */
define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.tagging163jController',[])
        .controller('tagging163jController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','Tagging163jFactory','GENERAL_CONFIG', tagging163jController])
        
        .controller('default163jActionsCtrl',['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory','USER_SETTINGS' ,'Tagging163jFactory', '$timeout', 'TbbsService', 'GENERAL_CONFIG', 'workspaceFactory', default163jActionsCtrl ]);

    function tagging163jController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory,$timeout,Tagging163jFactory,GENERAL_CONFIG){

        $scope.crudLoading = false;
        var vm = this;
        vm.title = "163j Project Tag";
        vm.entities = [];
        vm.projectList = [];
        vm.projectStepList = [];
        vm.project = null;
        vm.projectStep = null;
        vm.projectLoading = true;
        vm.sysProjectLoading = true;
        vm.tagProjectLoading = false;
        var sendobj = [];
        vm.oldProject_id = "";
        var projectTagjson = {};
        var projectTagSysjson = {};
        var transdetailskey = [];
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        if(ModalFactory.response.data.modalScreen.modal_name === 'taggingsys163jModal')
        {
        	vm.sysProjectLoading = false;
        	vm.tagProjectLoading = true;
        }
        
        function init(){
        	 $scope.crudLoading = false;

            if( !_.isArray(gridData)){

                var obj  = {}
                obj.row_id = gridData.rowData.object_id;
                obj.data = gridData.rowData;
                gridData = [];

                gridData.push(obj);

            }

            if (typeof rowData !== 'undefined') {
				vm.rowData = rowData;
			}
            loadProjects();
            vm.entities  = _.clone(gridData);


        }


       function loadProjects() {
            var params = {
                "action_code": 'r5ixq2',
            }

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                   if (data.callSuccess !== "1") {
                       AlertService.add("error", data.errorMessage);
                       vm.projectLoading = false;
                   } else {
                      if(vm){
                          vm.projectList = data.jsonObject;
                          vm.projectLoading = false;
                          var untag = {ACCT_KEY:null,TAG_163J_DESC:"Remove 163j Project Tag"}
                          vm.projectList.unshift(untag);
                          setProject();
                      }else{
                          console.log("element closed before ajax call")
                      }


                   }


            });
        };


       function setProject(){
           try {
               var projectArray = [];
               _.forEach(vm.entities, function (value, key) {
                   var acct_key = value.data.ACCT_KEY;
                   if (!_.includes(projectArray, acct_key)) {
                       projectArray.push(acct_key);
                   }
               });
               if (projectArray.length === 1) {
                   var project = projectArray[0];
                   vm.project = _.find(vm.projectList, {'ACCT_KEY': project});
                   vm.oldProject_id =vm.project;

               }
           }
           catch (e) {
               // statements to handle any exceptions
               console.info(e); // pass exception object to error handler
           }
       }
	      vm.save = function(){
	    	  
	    	  if(ModalFactory.response.data.modalScreen.modal_name === 'taggingsys163jModal')
	          {
	    		  if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
		                return;
		            }

		            $scope.crudLoading = true;
		            addSysToJson();
		        	 var dummyJson = {}
		        	 
		        	 Tagging163jFactory.saveSystemProjectTag(sendobj).then(function (result) {
		        		 for(var a = 0 ; a<sendobj.length; a++ ){
		        			 transdetailskey[a] = sendobj[a].SYS_CHART_KEY;
		        		 }
		        		 transdetailskey = _.uniq(transdetailskey,
									JSON.stringify)

								transdetailskey =transdetailskey.join();

		                if(result.callSuccess !== "1" ){
							$scope.crudLoading = false;
		                    AlertService.add("error", result.errorMessage, 4000);
		                    sendobj = [];

		                }else{
		                        AlertService.add("success", "Data has been saved successfully " , 4000);
		                        $uibModalInstance.dismiss('cancel');
		                        $timeout(function(){ 
		                        	var args = {
		                        			sys_chart_filter:transdetailskey,
		                                    updatekey:transdetailskey,
											gridFilter : {
												sys_chart_filter:transdetailskey,object_id:vm.rowData.object_id
											}
										};
										$rootScope.$emit('gridUpdate', args);
								});

		                }
		            });
		        	 
	          }
	    	  else {
	    		  
	    		  if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
		                return;
		            }

		            $scope.crudLoading = true;
		        	addToJson();
		           // vm.crudLoading = true;
		        	 var dummyJson = {}
		        	 Tagging163jFactory.saveProjectTag(sendobj).then(function (result) {
		        		 for(var a = 0 ; a<sendobj.length; a++ ){
		        			 transdetailskey[a] = sendobj[a].COMBINATION_KEY;
		        		 }
		        		// projectTagjsonSend.push(projectTagjson);
		        		 transdetailskey = _.uniq(transdetailskey,
									JSON.stringify)

								transdetailskey =transdetailskey.join();

		                if(result.callSuccess !== "1" ){
							$scope.crudLoading = false;
		                    AlertService.add("error", result.errorMessage, 4000);
		                    sendobj = [];

		                }else{
		                        AlertService.add("success", "Data has been saved successfully " , 4000);
		                        $uibModalInstance.dismiss('cancel');
		                        $timeout(function(){ 
		                        	var args = {
		                        			combination_keys:transdetailskey,
		                                    updatekey:transdetailskey,
											gridFilter : {
												COMBINATION_KEY:transdetailskey,object_id:vm.rowData.object_id
											}
										};
										$rootScope.$emit('gridUpdate', args);
								});

		                }
		            });
	    	  }
	
	       }

	        function addToJson(){
	        	if(vm.entities.length >0){
	        		   angular.forEach(vm.entities, function (row, key) {
	        	           buildJson(row.data);
	        	   });

	        	}
	        }


	        function buildJson(dataObj){
		       	  projectTagjson = {};
			            projectTagjson['COMBINATION_KEY'] = dataObj.COMBINATION_KEY;
			            projectTagjson['TAX_YEAR'] = dataObj.TAX_YEAR;
			            projectTagjson['SCENARIO'] = GlobalService.globalParams.scenario;
			            projectTagjson['JCD_KEY'] = GlobalService.globalParams.jcd_key;
			            if(vm.project != null){
			            	projectTagjson['NEW_163J_TAG'] = vm.project.TAG_163J;
			            	projectTagjson['NEW_163J_ACCT_KEY'] = vm.project.ACCT_KEY;
			            }
			            projectTagjson['TRANSHEADER_KEY'] = dataObj.TRANS_HEADER_KEY;
			            projectTagjson['TRANSDETAILS_KEY'] = dataObj.TRANS_DETAILS_KEY;
			            projectTagjson['LOCAL_ACCT_KEY'] = dataObj.LOCAL_ACCT_KEY;
			          
	                    sendobj.push(projectTagjson);

		       }
	        
	        function addSysToJson(){
	        	if(vm.entities.length >0){
	        		   angular.forEach(vm.entities, function (row, key) {
	        			   buildSysJson(row.data);
	        	   });

	        	}
	        }
	        
	        function buildSysJson(dataObj){
	        	projectTagSysjson = {};
	        			projectTagSysjson['TAX_YEAR'] = dataObj.TAX_YEAR;
	        			projectTagSysjson['SCENARIO'] = GlobalService.globalParams.scenario;
			            projectTagSysjson['JCD_KEY'] = GlobalService.globalParams.jcd_key;
			            if(vm.project != null){
			            	projectTagSysjson['NEW_163J_TAG'] = vm.project.TAG_163J;
			            	projectTagSysjson['NEW_163J_ACCT_KEY'] = vm.project.ACCT_KEY;
			            }
			            projectTagSysjson['SYSTEM_ACCT_KEY'] = dataObj.SYS_ACCT_KEY;
			            projectTagSysjson['SYS_CHART_CODE'] = dataObj.SYS_CHART_CODE;
			            projectTagSysjson['SYS_CHART_KEY'] = dataObj.SYS_CHART_KEY;
			          
	                    sendobj.push(projectTagSysjson);

		       }


        vm.removeEntity = function(_index){
            vm.entities.splice(_index,1);
            if( vm.entities.length === 0 ){
                $uibModalInstance.dismiss('cancel');
            }
        }


        init();

        $scope.$on("$destroy", function() {
            //console.log("DESTROY ME")
            vm = null;
        })


    }///////////////END CTRL
    
    
    function default163jActionsCtrl($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, USER_SETTINGS, Tagging163jFactory, $timeout, GENERAL_CONFIG,workspaceFactory) {

		var vm = this;
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		vm.defaultsMultipleSelected = [];
		vm.gridData = {};
		vm.syncSaveDtls = [];
		vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
		vm.isSaveClicked = false;
		
		
		vm.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};

		if (gridData != null) {

			var tempGridData = _.clone(gridData), groups = Object
					.create(null), result, finalKeyCheck = 0;
			
			vm.gridDataMultipleSelect = _.clone(tempGridData);
			
			if (vm.gridDataMultipleSelect != undefined) {
						for(var key in vm.gridDataMultipleSelect){
							var gridObj = {};
							
							gridObj["object_id"] = vm.gridDataMultipleSelect[key].data.object_id;
							gridObj["system_acct"] = vm.gridDataMultipleSelect[key].data.SYS_ACCT;
							gridObj["system_acct_desc"] = vm.gridDataMultipleSelect[key].data.SYS_ACCT_DESC;
							gridObj["system_acct_key"] = vm.gridDataMultipleSelect[key].data.SYS_ACCT_KEY;

							vm.syncSaveDtls.push(gridObj);
							
						}
			}

		}
		vm.confirm_sync = function() {
			
			 
			var returnClobSettingsObj = {};
			var ecSaveDtls = [];
			var returnObj = {};
			
			var sysacct = vm.syncSaveDtls.map(item => item.system_acct)
			var sysacctString = sysacct.join(',');
		
			returnClobSettingsObj['sso_id'] = vm.logged_in_user;
			returnClobSettingsObj['system_acct'] = sysacctString;

			var message = "Default 163j Tags will be applied";
			sendDetails(returnClobSettingsObj,ecSaveDtls, message);

		};

		function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
			$rootScope.$broadcast("showLoader", true);
			$uibModalInstance.dismiss('cancel');
			
			$rootScope.$broadcast("showLoader", false);
			AlertService.add("success", message, 4000);
			Tagging163jFactory.applyDefaults(returnClobSettingsObj,ecSaveDtls).then(function(result) {

			});
	   }
		
		vm.confirm_reset_sync = function() {
			
			 
			var returnClobSettingsObj = {};
			var ecSaveDtls = [];
			var returnObj = {};
			
			var sysacct = vm.syncSaveDtls.map(item => item.system_acct)
			var sysacctString = sysacct.join(',');
		
			returnClobSettingsObj['sso_id'] = vm.logged_in_user;
			returnClobSettingsObj['system_acct'] = sysacctString;

			var message = "163j Tags will be Reset";
			sendResetDetails(returnClobSettingsObj,ecSaveDtls, message);

		};

		function sendResetDetails(returnClobSettingsObj, ecSaveDtls, message) {
			$rootScope.$broadcast("showLoader", true);
			$uibModalInstance.dismiss('cancel');
			
			$rootScope.$broadcast("showLoader", false);
			AlertService.add("success", message, 4000);
			Tagging163jFactory.resetDefaults(returnClobSettingsObj,ecSaveDtls).then(function(result) {

			});
	   }
		
		vm.confirm_form_sync = function() {
			
			 
			var returnClobSettingsObj = {};
			var ecSaveDtls = [];
			var returnObj = {};
			
			var sysacct = vm.syncSaveDtls.map(item => item.system_acct)
			var sysacctString = sysacct.join(',');
		
			returnClobSettingsObj['sso_id'] = vm.logged_in_user;
			returnClobSettingsObj['system_acct'] = sysacctString;

			var message = "163j will be synced to Form 8990";
			sendFormSyncDetails(returnClobSettingsObj,ecSaveDtls, message);

		};

		function sendFormSyncDetails(returnClobSettingsObj, ecSaveDtls, message) {
			$rootScope.$broadcast("showLoader", true);
			$uibModalInstance.dismiss('cancel');
			
			$rootScope.$broadcast("showLoader", false);
			AlertService.add("success", message, 4000);
			Tagging163jFactory.syncForm(returnClobSettingsObj,ecSaveDtls).then(function(result) {

			});
	   }
   
	}
    
    return controllers;


});