define([
    'angular'
], function () {
    'use strict';


var services = angular.module('app.gridPivotServices', [])

.factory("GridPivotFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', 
'$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG',
function ($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, 
    DataFiltersFactory, workspaceFactory, GENERAL_CONFIG) {
    var gridPivotFactory = {};

        gridPivotFactory.applyGroupPivot = function(pivotGroup){
          //  var def = $q.defer();
                console.log('I am pivot Service');
                var data = workspaceFactory.activeScreen.data;
                if(workspaceFactory.activeScreen.data.tableState && workspaceFactory.activeScreen.data.tableState.colSearch.length>0){
                    
                }
           return new Promise(function (resolve, reject) {
                 workspaceFactory.activeScreen.data.pivotheaderArray = workspaceFactory.activeScreen.data.header
                 if(data.pivotGroup && !_.isEmpty(data.pivotGroup) && data.pivotGroup.columnToRows && data.pivotGroup.columnToRows.length>0
                    && data.pivotGroup.isPivotEnabled){
 
                     var start = new Date().getTime();
                     console.log('colgGroup start time',start)
                    /*  get the group and column rows for aggregation*/
                     var colRowsmerge = _.concat(data.pivotGroup.columnToRows,data.pivotGroup.groupRows[0]);
                     var filterDataColRows = groupByCategories(data.data,colRowsmerge);
                     var flattefilterDataColRows = flattenObject(filterDataColRows);
                    /*  */
                    var colGroupArray = groupByCategories(data.data,data.pivotGroup.columnToRows);
                    if(colGroupArray && _.isEmpty(colGroupArray)){
                        AlertService.add('warning','Please valid selection for Pivoting',4000);
                        return;
                    }
                    //   console.log('colGroupArraycolGroupArray,',colGroupArray);
                     
                      function flattenObject(oldObject) {
                         const newObject = {};
                     
                         flattenHelper(oldObject, newObject, '');
                     
                         return newObject;
                     
                         function flattenHelper(currentObject, newObject, previousKeyName) {
                             for (let key in currentObject) {
                                 let value = currentObject[key];                         
                                 if (value.constructor !== Object) {
                                 if (previousKeyName == null || previousKeyName == '') {
                                     newObject[key] = value;
                                 } else {
                                     if (key == null || key == '') {
                                     newObject[previousKeyName] = value;
                                     } else {
                                     newObject[previousKeyName + '_' + key] = value;
                                     }
                                 }
                                 } else {
                                 if (previousKeyName == null || previousKeyName == '') {
                                     flattenHelper(value, newObject, key);
                                 } else {
                                     flattenHelper(value, newObject, previousKeyName + '_' + key);
                                 }
                                 }
                             }
                         }
                       }
                       var testarr1 = [];
                       var flatteArray = flattenObject(colGroupArray);
                        // console.log('flatteArrayflatteArray',flatteArray);
                       workspaceFactory.activeScreen.data.pivotheaderArray = [];
                       var findOBJEID = _.filter(data.header,function(i){return i.map == 'OBJECT_ID' ||i.map == 'object_id'});
                       var mergeHeadrRows =_.concat(data.pivotGroup.groupRows,findOBJEID[0].map) ;//_.concat(data.pivotGroup.groupRows,"object_id");
                       var groupHeaderRows = data.header.filter( i => mergeHeadrRows.includes( i.map ));
                       workspaceFactory.activeScreen.data.pivotheaderArray = _.clone(groupHeaderRows);
                       console.log(groupHeaderRows);
                       var colDimAgg = {};
                       var result = [];
                         /* preparing column dimension */
                         var uniqKeys = _.keys(flatteArray); /* uniquekEYS from column dimension */
                         console.log('uniqkeys',uniqKeys.length);
                             _.each(uniqKeys,function(keys){
                                 _.keys(data.pivotGroup.agg).forEach(function(aggKey){
                                     var tempHeader = _.filter(data.header,{map:aggKey});
                                     var obj = _.cloneDeep(tempHeader[0]);
                                     obj.map =  keys.replace(/[^a-zA-Z0-9]/g,'_')+aggKey;
                                     obj.label = keys + '_' + obj.label;///aggKey;
                                     obj.object_id = obj.map;
                                     var removeProps = {
                                        //  "call_back": null,
                                        //  "display": "y",
                                        //  "drilldown": null,
                                        //  "drilldown_filter_refresh": 0,
                                        //  "drilldown_rule": null,
                                        //  "edit_icon": null,
                                        //  "edit_rule_message": null,
                                        //  "edit_rules": "null",
                                         "edit_type": "text",
                                         "editable": "n",
                                        // "enable_cell_edit": false
                                     }
                                     obj = _.merge({}, obj, removeProps);
                                     if(_.filter(workspaceFactory.activeScreen.data.pivotheaderArray,{map:obj.map}).length==0){
                                         workspaceFactory.activeScreen.data.pivotheaderArray.push(obj);
                                     };
                                 });
                             })
                                
                             
                                /*  console.log('pivot headers',workspaceFactory.activeScreen.data.pivotheaderArray);     */
                                 /* do process for row Dimension/row Grouping  */    
                                if(data.pivotGroup.groupRows && data.pivotGroup.groupRows.length && 
                                     data.pivotGroup.groupRows.length>=1){
                                         if(data.settings && data.settings.fixed_columns == 2){
                                             data.settings.fixed_columns = 3;
                                         }
                                         var pivotGroup = {
                                             map: "group",
                                             display: "y",
                                             col_class: "groupRow",
                                             call_back: "",
                                             editable: "n",
                                             edit_type: "groupRow",
                                             label: "Group",
                                             width: 140,
                                             format: "groupRow",
                                             object_id: "groupRow",
                                             header_class: "endCap",
                                             data_type:"string"
                                         }
                                         if(data.settings && data.settings.batch_select == 1){
                                         if(_.filter(workspaceFactory.activeScreen.data.pivotheaderArray,{map:'group'}).length==0){
                                             workspaceFactory.activeScreen.data.pivotheaderArray.splice(2,0,pivotGroup);
                                         }
             
                                         }else{
                                         if(_.filter(workspaceFactory.activeScreen.data.pivotheaderArray,{map:'group'}).length==0){
                                             workspaceFactory.activeScreen.data.pivotheaderArray.splice(1,0,pivotGroup);
                                         }
             
                                         }
                                         var arr = {};
                                         arr = groupByCategories(data.data,data.pivotGroup.groupRows);

                                       /*   var end = new Date().getTime()-start;
                                        console.log('test row dim end time',end/1000); */
                                        if(!_.isEmpty(arr)){
                                            prepareParentChildren(arr);
                                        }
                         
                                        function prepareParentChildren(arr){
                                            var tempResult = [];
                                            var start = new Date().getTime();
                                            console.log('test start time',start);
                                            _.map(arr,function(value,key){
                                                var temp = {children:[]}
                                                var arrDepthKey = [];
                                                var calChild = function(key,arr,level,arrByKey){                                   
                                                    if(_.isArray(arr)){
                                                        // temp.children = arr;
                                                    }else if(_.isObject(arr)){ 
                                                        level++;   
                                                        _.map(arr,function(value,ii){
                                                            var ob = {children:[]};
                                                            ob.label = ii;
                                                            ob.pivot_parent = key.object_id +ii.replace(/[ ,./&%():#?]/g,'')//replace(/\s/g, '');
                                                            ob.object_id = ob.pivot_parent;
                                                            ob.map = "group";
                                                            ob.group = ii.replace(/[ ,./&%():#?]/g,'')//replace(/\s/g, '');
                                                            
                                                            ob.padLeft = level==0 ?  0 : level * 10;   
                                                            ob.level = level;
                                                            ob.groupKey = data.pivotGroup.groupRows[level];
                                                            var  childArrByKey=[];
                                                            if(!_.isEmpty(data.pivotGroup.agg) ){
                                                            }
                                                            if(_.isArray(value)){
                                                              //////////   ob.children = value//arr[ii];
                                                                key.children.push(ob);
                                                            }else{
                                                                key.children.push(ob);
                                                                calChild(ob, value,level,arrDepthKey);
                                                            }
                                                        }     
                                                        )                                  
                                                    }
                                                }
                                                //if(arr.hasOwnProperty(key) && (typeof arr[key] === "object")){
                                                if(typeof value === "object"){
                                                    var level = 0;
                                                    temp.label = key;
                                                    temp.object_id = key.replace(/[ ,./&%():#?]/g,'');
                                                    temp.map = "group";
                                                    temp.group = key.replace(/[ ,./&%():#?]/g,'');
                                                    
                                                    temp.padLeft = level==0 ?  0 : level * 10;   
                                                    temp.level = level;
                                                    temp.groupKey = data.pivotGroup.groupRows[level];
                                                    tempResult.push(temp);
                                                    calChild(temp, arr[key],level,arrDepthKey);
                                                }                                            
                                            });
                                            // console.log("tempTResult Array",tempResult);
                                           /*  var end = new Date().getTime()-start;
                                            console.log('test end time',end/1000); */
                                            workspaceFactory.activeScreen.data.pivotdataArr = tempResult;
                                        }
                                        
                                /* coulumn level aggregation workspaceFactory.activeScreen.data.pivotdataArr*/

                               var aggPivotData = workspaceFactory.activeScreen.data.pivotdataArr;
                                        // console.log('aggPivotData',aggPivotData);
                                        _.each(uniqKeys,function(key){
                                            _.each(aggPivotData,function(ai){   
                                                var obj ={};
                                                var coluArray = [];
                                                obj[ai.groupKey] = ai.label;
                                                coluArray =  flattefilterDataColRows[key+'_' +ai.label]
                                                if(coluArray && coluArray.length>0){
                                                    _.map(data.pivotGroup.agg, function(value, aggKey) {
                                                        var tt = key.replace(/[^a-zA-Z0-9]/g,'_') + aggKey;
                                                        if(ai.hasOwnProperty(tt)){
                                                            ai[tt] = ai[tt] + callRowAggregationByKey(coluArray,aggKey,value);
                                                        }else{
                                                            ai[tt] = callRowAggregationByKey(coluArray,aggKey,value);
                                                        }
                                                    })
                                                }
                                                if(ai.children && ai.children.length>0){
                                                    var callChildren = function(chilA){
                                                        var isLeastChild = chilA && chilA.length>0 ? chilA[0].hasOwnProperty('level'):false;
                                                        if(isLeastChild){
                                                            _.each(chilA,function(ii){
                                                                var obj ={};
                                                                obj[ii.groupKey] = ii.label;
                                                             
                                                               // var childrenColumn = _.filter(flatteArray[key],obj);
                                                               var childrenColumn =  _.filter(coluArray,obj)
                                                                if(childrenColumn && childrenColumn.length>0){
                                                                    _.map(data.pivotGroup.agg, function(value, aggKey) {
                                                                        var tt = key.replace(/[^a-zA-Z0-9]/g,'_') + aggKey;
                                                                        if(ii.hasOwnProperty(tt)){
                                                                            ii[tt] = ii[tt] + callRowAggregationByKey(childrenColumn,aggKey,value);

                                                                        }else{
                                                                            ii[tt] = callRowAggregationByKey(childrenColumn,aggKey,value);
                                                                        }
                                                                    })
                                                                }
                                                               
                                                                if(ii.children){
                                                                    callChildren(ii.children);
                                                                }
                                                            })
                                                        }
                                                    }
                                                    callChildren(ai.children);
                                                }
                                               
                                            })
                                        })
                                        // console.log('aggPivotDataaggPivotDataaggPivotData',aggPivotData);
                                var end = new Date().getTime() - start;
                                console.log('after column dimensions start time',end/1000);
                                 }                              
                                else{
                                    colDimAgg.object_id = 1;
                                    _.map(data.pivotGroup.agg,function(value,aggKey){
                                         _.each(uniqKeys,function(ik){
                                             var tt = ik.replace(/[^a-zA-Z0-9]/g,'_') + aggKey;
                                             colDimAgg[tt] = callRowAggregationByKey(flatteArray[ik],aggKey,data.pivotGroup.agg[aggKey]);
 
                                         });
                                     })
                                    if(testarr1.length==0){
                                     testarr1.push(colDimAgg);
                                    }
                                    workspaceFactory.activeScreen.data.pivotdataArr = testarr1;
                                }
 
                                var endcoluGroup = new Date().getTime() - start;
                             console.log('after column dimensions start time',endcoluGroup/1000);
                 }
 
                 /*case 3:  row Grouping and Aggregation with expand and collapse */
                else if(data.pivotGroup && !_.isEmpty(data.pivotGroup) && data.pivotGroup.groupRows && data.pivotGroup.groupRows.length>0){
                     prepareGroupData(data);
                 }else{
                    workspaceFactory.activeScreen.data.pivotdataArr = [];
                    if(_.filter( workspaceFactory.activeScreen.data.pivotheaderArray,{map:'group'}).length>=0){
                        _.remove( workspaceFactory.activeScreen.data.pivotheaderArray,function(item){
                            return item.map == 'group';
                        });
                    }
                 }
                
 
                   /* aggregation on pivot Rows */
               
                
                 function prepareGroupData(dataArray,fromColRows){
                     if(data && data.pivotGroup != undefined && data.pivotGroup.groupRows && data.pivotGroup.groupRows.length>0){
                         if(data.settings && data.settings.fixed_columns == 2){
                             data.settings.fixed_columns = 3;
                         }
                         var pivotGroup = {
                             map: "group",
                             display: "y",
                             col_class: "groupRow",
                             call_back: "",
                             editable: "n",
                             label: "Group",
                             width: 200,
                             format: "text",
                             object_id: "groupRow",
                             header_class: "endCap"
                         }
                        if(data.settings && data.settings.batch_select == 1){
                         if(_.filter( workspaceFactory.activeScreen.data.pivotheaderArray,{map:'group'}).length==0){
                             workspaceFactory.activeScreen.data.pivotheaderArray.splice(1,0,pivotGroup);
                         }
 
                         }else{
                            if(_.filter(workspaceFactory.activeScreen.data.pivotheaderArray,{map:'group'}).length==0){
                                workspaceFactory.activeScreen.data.pivotheaderArray.splice(1,0,pivotGroup);
                            }
 
                         }
                         /* remove all editable feature while doing the grouping */

                       /* var removeEditableHeader =  _.each(workspaceFactory.activeScreen.data.pivotheaderArray,function(obj){
                            if(obj.edit_type != 'text'){
                                obj.edit_type = "text";
                                obj.editable = "n";
                            }
                        }) */
                      //  workspaceFactory.activeScreen.data.pivotheaderArray = removeEditableHeader;
                        // console.log('workspaceFactory.activeScreen.data',workspaceFactory.activeScreen.data);

                         var start = new Date().getTime();
                         console.log('test start time',start);
                         var arr = {};
                         arr = groupByCategories(data.data,data.pivotGroup.groupRows);
                         var groupByoneCatForAgg = {};
                         groupByoneCatForAgg = groupByCategories(data.data,[data.pivotGroup.groupRows[0]])
                         var end = new Date().getTime()-start;
                         console.log('test end time',end/1000);
                         if(!_.isEmpty(arr)){
                             prepareParentChildren(arr);
                         }
                         
                         
                         function prepareParentChildren(arr){
                             var tempResult = [];
                             var start = new Date().getTime();
                             console.log('test start time',start);
                             _.map(arr,function(value,key){
                                 var temp = {children:[]}
                                 var arrDepthKey = [];
                                 var calChild = function(key,arr,level,arrByKey){                                   
                                     if(_.isArray(arr)){
                                         temp.children = arr;
                                     }else if(_.isObject(arr)){ 
                                         level++;   
                                         _.map(arr,function(value,ii){
                                             var ob = {children:[]};
                                             ob.label = ii;
                                             ob.pivot_parent = key.object_id +ii.replace(/[ ,./&%():#?]/g,'');
                                             ob.object_id = ob.pivot_parent;
                                             ob.map = "group";
                                             
                                             ob.group = ii.replace(/[ ,./&%():#?]/g,'');
                                            
                                             ob.padLeft = level==0 ?  0 : level * 10;   
                                             ob.level = level;
                                             ob.groupKey = data.pivotGroup.groupRows[level];
                                             var  childArrByKey=[];
                                            /*  if(!_.isEmpty(data.pivotGroup.agg) ){
                                                 childArrByKey =_.findByValues(arrByKey, ob.groupKey ,[ob.label])
                                                 if(childArrByKey && childArrByKey.length>0){
                                                     _.map(data.pivotGroup.agg, function(value, key) {
                                                         ob[key] = callRowAggregationByKey(childArrByKey,key,value);//_.sumBy(childArrByKey,key);// 
                                                      })
                                                 }
                                             } */
                                             if(_.isArray(value)){
                                                if(!_.isEmpty(data.pivotGroup.agg) ){
                                                   childArrByKey = value;
                                                    if(childArrByKey && childArrByKey.length>0){
                                                        _.map(data.pivotGroup.agg, function(value, key) {
                                                            ob[key] = callRowAggregationByKey(childArrByKey,key,value);//_.sumBy(childArrByKey,key);// 
                                                         })
                                                    }
                                                }
                                                 ob.children = value;
                                                 key.children.push(ob);
                                             }else{
                                                if(!_.isEmpty(data.pivotGroup.agg) ){
                                                      childArrByKey = _.values(value);
                                                     if(childArrByKey && childArrByKey.length>0){
                                                         _.each(childArrByKey,function(chilarr){
                                                            _.map(data.pivotGroup.agg, function(value, key) {
                                                                if(ob[key] == undefined){
                                                                   ob[key] = callRowAggregationByKey(chilarr,key,value);//_.sumBy(childArrByKey,key);// 
   
                                                                }else{
                                                                   ob[key] = ob[key] + callRowAggregationByKey(chilarr,key,value);//_.sumBy(childArrByKey,key);// 
    
                                                                }
                                                             })
                                                         })
                                                         
                                                     }
                                                 }
                                                 key.children.push(ob);
                                                 calChild(ob, value,level,arrDepthKey);
                                             }
                                           }     
                                         )                                  
                                     }
                                 }
                                 //if(arr.hasOwnProperty(key) && (typeof arr[key] === "object")){
                                 if(typeof value === "object"){
                                     var level = 0;
                                     temp.label = key;
                                     temp.object_id = key.replace(/[ ,./&%():#?]/g,'');
                                     temp.map = "group";
                                    
                                     temp.group = key.replace(/[ ,./&%():#?]/g,'');
                                     
                                     temp.padLeft = level==0 ?  0 : level * 10;   
                                     temp.level = level;
                                     temp.groupKey = data.pivotGroup.groupRows[level];
                                     tempResult.push(temp);
                                     if(!_.isEmpty(data.pivotGroup.agg) ){
                                         if(_.isArray(value)){
                                             arrDepthKey = value;
                                         }else{
                                            // arrDepthKey = getDepthArray(value);
                                            arrDepthKey = groupByoneCatForAgg[key];
                                         }
                                        // temp.label = key + '('+arrDepthKey.length+')';
                                         if(arrDepthKey && arrDepthKey.length>0){
                                            _.map(data.pivotGroup.agg, function(value, key) {
                                                 temp[key] = callRowAggregationByKey(arrDepthKey,key,value);//_.sumBy(arrDepthKey,key);
                                             })
                                         }
                                     }
                                    
                                     calChild(temp, arr[key],level,arrDepthKey);
                                 }
                               
                             });
                             console.log("tempTResult Array",tempResult);
                             var end = new Date().getTime()-start;
                             console.log('test end time',end/1000);
                             workspaceFactory.activeScreen.data.pivotdataArr = tempResult;
                         }
                        //  console.log('ggData.headerArry',workspaceFactory.activeScreen.data.pivotheaderArray);
                         var start = new Date().getTime();
 
                         console.log('start time',start);
                        
                         
                     }else{
                         
                     }
                
                 }
                 if (workspaceFactory.activeScreen.screen_key === data.screen_key) {
                     $rootScope.$broadcast("dataLoaded", workspaceFactory.activeScreen.data);
 
                 }
                //   def.resolve(workspaceFactory.activeScreen.data);
                //      return def.promise; 
                
                    resolve('done');
                });
        };       
            return gridPivotFactory;
         }]);

        var groupByCategories = function (seq, keys) {
            if (!keys.length)
                return seq;
            var first = keys[0];
            var rest = keys.slice(1);
            return _.mapValues(_.groupBy(seq, first), function (value) { 
                return groupByCategories(value, rest)
            });
        }; 
        function callRowAggregationByKey(arr,key,type){
            var result = 0;
            switch (type) {
                case "sum":
                    result = _.sumBy(arr, key);
                    break;
                case "avg":
                    result = _.sumBy(arr, key)/arr.length;
                    break;
                case "max":
                    var maxii = _.maxBy(arr, function(o) { return o[key]; });
                    result = maxii[key];
                    break;
                case "min":
                    var minii = _.minBy(arr, function(o) { return o[key]; });
                    result = minii[key];
                    break;
            }
            return result;
        }
        function callRowAggregation(arr,key,type){
            if(type == 'sum'){
                return _.sumBy(arr);
            }
            else if(type == 'avg'){
                return  _.sumBy(arr)/arr.length;
            }else if(type == 'max'){
                var maxValue = _.max(arr);
                return maxValue;

            }else if(type == 'min'){
                var minValue = _.min(arr);
                return minValue;

            }else if(type == 'count'){

            }
           
        }
        function getDepthArray(keyArr){
            var depthArray = []
           var test = function(keyArr){ 
                _.map(keyArr,function(value,key){
                    if(_.isArray(value)){
                       depthArray = _.concat(depthArray,value);
                    } else{
                        test(value)
                    }
                })
            }   
            test(keyArr);   
            return depthArray;                     
        }
        _.mixin({
            'findByValues': function(collection, property, values) {
                return _.filter(collection, function(item) {
                    return _.includes(values, item[property]);
                });
            }
        });
    
     
        
        return services;


});

        