define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.ApprConfigCtrl', [])
					.controller('apprConfigCtrl', ['$scope','$rootScope','$state', '$http', 'gridData', '$uibModalInstance', 'ModalFactory','GridFactory', 'AlertService','GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'ApprConfigFactory','$filter','rowData','USER_SETTINGS', apprConfigCtrl])
							    
			function apprConfigCtrl($scope, $rootScope, $state, $http, gridData, $uibModalInstance, ModalFactory, GridFactory, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, ApprConfigFactory,$filter,rowData,USER_SETTINGS) {
				var vm = this;	
				
				vm.filingDetails = [];
				vm.filingEnityDetails = [];
				vm.grossIncomeDetails = [];
				vm.filingAllDetails = [];
				vm.filingGroup = {"CONSOL_GROUP_KEY":"","FILING_GROUP_NAME":"SELECT"};
				vm.filerEntity = [];//{"FILER_LE_KEY":"","FILER_LE_NAME":"SELECT"};
				vm.filerEntityEdit = {};
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = filterParams.scenario;   //filterParams.scenario;   
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				vm.gl_filing_key= filterParams.filing_group_key;
				
				vm.isCreate = (gridData.length == 0 || gridData.colData.map == "Add") ? true : false;
				vm.rowData = rowData;
				vm.USER_SETTINGS = USER_SETTINGS;
				vm.status = vm.isCreate ? '' : vm.rowData.IS_LOCKED;
				vm.gotExistingData = null;
				vm.statusDetails = [{
                	CODE_GROUP: "",
                	IS_LOCKED: "SELECT",
                	LOCK_DESC: ""
				}];
				
				
				
				//cancel the modal
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
						
		            
		            	var params = {
		                    "action_code": 'f69od7',
		                };
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                    .then(function(data) {
		                    	vm.grossIncomeDetails = data.jsonObject;
		                    	
		                    	/*if(vm.gl_filing_key == 3) {
				                	   let check_with = ["EXPAA","INTEXP_EXEMPT_ASSETS"],grossIncomeDetailsNew = [];
				                	   vm.grossIncomeDetails.map(item => {
				                		   if(item.GIE_NAME === "EXPAA" || item.GIE_NAME === "INTEXP_EXEMPT_ASSETS"){
				                			   grossIncomeDetailsNew.push(item);
				                		   }
				                	   })
				                	   vm.grossIncomeDetails = grossIncomeDetailsNew;
		                    	}*/ 
		                    	
		                    	vm.grossIncomeDetails.unshift({
	                                'GIE_NAME': '',
	                                'GIE_SHORT_DESC': 'SELECT'
	                            });
		                    	
		                    	 
		                    	/*vm.grossIncomeDetails = _.filter(data.jsonObject, function(p){
			                		 return _.includes(['INTEXP','RESEARCH'], p.ACCT_DESC);  
			                	});*/
		                    	
		                        vm.grossIncome = (vm.isCreate == false) ? 
		                        	vm.grossIncomeDetails.find(item => item.GIE_SHORT_DESC === vm.rowData.GIE_SHORT_DESC) : {
	                                'GIE_NAME':'',
	                                'GIE_SHORT_DESC': 'SELECT'
	                            };
	                            if(!vm.isCreate) {
	                            	 vm.getFillingGroupInfo();
	                            	// vm.filingEnityDetailInfo();
	                            	 vm.getStatus();
	                            }
	                           
	                            
		                    });
		                
		        vm.getFillingGroupInfo = function() {
		        	params = {
							//"action_code": 'hmkia6',
		        			"action_code":"35gc5l",
		                    "gie_name": vm.grossIncome.GIE_NAME
					};
	            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
	                .then(function(data) {
	                	
	                vm.filingAllDetails = Object.assign([],data.jsonObject);
	                vm.filingDetails = Object.assign([],data.jsonObject);
	                vm.filingDetails = _.uniqBy(vm.filingDetails, 'CONSOL_GROUP_KEY');
	                vm.filingEnityDetailInfo();
	                });
		        }
		        
		        
		        vm.filingEnityDetailInfo = function() {
		        	 vm.filingEnityDetails = Object.assign([],vm.filingAllDetails);
				    // vm.filingEnityDetails = _.filter(vm.filingEnityDetails, { 'CONSOL_GROUP_KEY': vm.filingGroup.CONSOL_GROUP_KEY});
				     vm.filingEnityDetails.unshift({"FILER_LE_KEY":"","FILER_LE":"SELECT"});
		                    if (vm.isCreate == false) {
		                    	   _.filter(vm.filingEnityDetails, function(p){
			                		  if(p.FILER_LE_KEY === vm.rowData.FILER_LE_KEY) {
			                			  vm.filerEntityEdit = p;
			                		  }
				                	});
		                    } else {
		                    	 vm.filerEntity = [];
		                    }
		        }
		        
		        //vm.getFillingGroupInfo();
		        
		        vm.getStatus = function() {
		        	var params = {
		                    "action_code": 'q7r80m',
		                    "sso_id": vm.USER_SETTINGS.user.sso_id,
		                    "tax_year": vm.filterTaxYear,
		                    "gie_name": vm.grossIncome.GIE_NAME
		                };
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                    .then(function(data) {
		                    	vm.statusDetails = data.jsonObject;
		                    	console.log(vm.statusDetails,"statusDetails");
		                    	
		                    	vm.statusDetails.unshift({
	                                	CODE_GROUP: "",
	                                	IS_LOCKED: "SELECT",
	                                	LOCK_DESC: ""
	                            });
		                    	
		                        vm.status = (vm.isCreate == false) ? {
		                            CODE_GROUP: vm.rowData.CODE_GROUP,
                                	IS_LOCKED: vm.rowData.IS_LOCKED,
                                	LOCK_DESC: vm.rowData.LOCK_DESC
		                        } : {
	                                	CODE_GROUP: "",
	                                	IS_LOCKED: "SELECT",
	                                	LOCK_DESC: ""
	                            };
		                    });
		        }
		            
				//reset the form
				vm.reset = function(){		
					//console.log(" Reset the Modal ");
					if(vm.isCreate) {
						//vm.getFillingGroupInfo();
						vm.filerEntity = [];
						vm.grossIncome = {
                                'GIE_NAME': '',
                                'GIE_SHORT_DESC': 'SELECT'
                        };
						vm.status = {
                            	CODE_GROUP: "",
                            	IS_LOCKED: "SELECT",
                            	LOCK_DESC: ""
                        };
					} else {
						 vm.grossIncome = vm.grossIncomeDetails.find(item => item.GIE_SHORT_DESC === vm.rowData.GIE_SHORT_DESC);
						 vm.getFillingGroupInfo();
						 vm.status = {
	                            	CODE_GROUP: vm.rowData.CODE_GROUP,
	                            	IS_LOCKED: vm.rowData.IS_LOCKED,
	                            	LOCK_DESC: vm.rowData.LOCK_DESC
	                     };
					}
				}
				
				
				//Get the saved data 
				vm.getSavedConfigData = function(){
					var params = {"action_code" : 'a83w9b' };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.gotExistingData = data.jsonObject;
						console.log("vm.gotExistingData : ", vm.gotExistingData );
					});
				}
				
				vm.getSavedConfigData();
				
				//Save the Investment Amt
				vm.save = function(){	
					vm.isSaveClicked = true;
					
					
					if((vm.isCreate && 
							(vm.grossIncome && (vm.grossIncome.GIE_NAME == undefined || 
									vm.grossIncome.GIE_NAME == null || vm.grossIncome.GIE_NAME == "") || 
									vm.status.LOCK_DESC == undefined  || vm.status.IS_LOCKED == 'SELECT' || /*vm.filerEntity.FILER_LE == undefined ||vm.filerEntity.FILER_LE == 'SELECT' ||*/
									(vm.filerEntity && vm.filerEntity.length === 0))) || 
									(!vm.isCreate && (vm.status.LOCK_DESC == undefined  || vm.status.IS_LOCKED == 'SELECT'))){
						AlertService.add("error", "Please select/enter all the required fields.",4000);
						vm.isSaveClicked = false;
						return;
					}
					
					if (vm.isCreate){

						let flag1 = false;
						vm.filerEntity.forEach(function(fEntityInfo) {
							if(fEntityInfo.FILER_LE == 'SELECT') {
								flag1 = true;
							}
						});	
						
						if(flag1){
							AlertService.add("error", "Please select/enter all the required fields.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}
						
					
					/*if(vm.grossIncome.GIE_NAME == 'EXPAA'  )
				    {
						if(vm.isCreate && _.find(vm.gotExistingData, {'FILING_GROUP': vm.filingGroup.CONSOL_GROUP_KEY })) {
							AlertService.add("error", "Apportionment type setup already exist.",4000);
							vm.isSaveClicked = false;
							return;
						}
				    }
					else {
						if(vm.isCreate && _.find(vm.gotExistingData, {'GIE_SHORT_DESC': vm.grossIncome.GIE_SHORT_DESC || ''})) {
							AlertService.add("error", "Apportionment type setup already exist.",4000);
							vm.isSaveClicked = false;
							return;
						}
						
					}*/
					if (vm.isCreate){
						let flag = false;
						vm.filerEntity.forEach(function(fEntityInfo) {
							if(_.find(vm.gotExistingData, {'GIE_SHORT_DESC': vm.grossIncome.GIE_SHORT_DESC,
								'FILER_LE':fEntityInfo.FILER_LE})) {
								flag = true;
							}
						});
							if(flag){
								AlertService.add("error", "Apportionment type setup already exist.",4000);
								vm.isSaveClicked = false;
								return;
							}
						
						
					}
					else {
						let flag = false;
						vm.filerEntity.forEach(function(fEntityInfo) {
							if(_.find(vm.gotExistingData, {'GIE_SHORT_DESC': vm.grossIncome.GIE_SHORT_DESC,
								'FILER_LE':fEntityInfo.FILER_LE,
								'LOCK_DESC':vm.status.LOCK_DESC})) {
								flag = true;
							}
						});
						
						if(flag){
							AlertService.add("error", "Apportionment type setup already exist.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}
					
					
					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.USER_SETTINGS.user.sso_id;
					clobSettingsObj['scenario'] = vm.scenario;
					clobSettingsObj["TAX_YEAR"] = vm.filterTaxYear;
					console.log("returnClobSettingsObj-----" + clobSettingsObj);
					
					var returnObj = {};
					returnObj["TAX_YEAR"] = vm.filterTaxYear;
					returnObj['scenario'] = vm.scenario;
					//returnObj['FILING_GROUP_KEY'] = vm.isCreate ? _.map(vm.filerEntity,'FILING_GROUP_NAME').join() : vm.filerEntityEdit.FILING_GROUP_NAME;;
					// returnObj['CONSOL_GROUP_KEY'] = vm.filingGroup.CONSOL_GROUP_KEY;
					returnObj['FILER_LE_KEY'] =  vm.isCreate ? _.map(vm.filerEntity,'FILER_LE_KEY').join() : vm.filerEntityEdit.FILER_LE_KEY;
					returnObj['GIE_NAME'] = vm.grossIncome.GIE_NAME;
					returnObj['CODE_GROUP'] = vm.status.CODE_GROUP;
					returnObj['IS_LOCKED'] = vm.status.IS_LOCKED;
					returnObj['LOCK_DESC'] = vm.status.LOCK_DESC;
					returnObj['ADJ_KEY'] = vm.isCreate ? null : vm.rowData.ADJ_KEY;	
					returnObj['FILING_GROUP_KEY'] = vm.gl_filing_key;
					
					returnObj["OPERATION_TYPE"] = vm.isCreate ? 'I' : 'U';	
					
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);
					
					var message = "Apportionment type configuration has been saved/updated";
					//send to save the Data					
					sendSaveDetails(clobSettingsObj, clobDataDetails, message);
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendSaveDetails(clobSettingsObj, clobDataDetails, message) {					
					ApprConfigFactory.saveAppConfig(clobSettingsObj, clobDataDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									/*me_code: returnClobSettingsObj.me_code,
	                                gridFilter: {
	                                	me_code: returnClobSettingsObj.me_code
	                                }*/
	                            };
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
			}		
			
			return controllers;
		});