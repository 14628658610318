/**
 * Created by 400371031 on 1/20/2021.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.F1120SubConsolController', []).controller(
							'F1120SubConsolController',
							[ '$scope', '$rootScope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'workspaceFactory', 'JsonObjectFactory',
									'$timeout', 'GENERAL_CONFIG',
									'USER_SETTINGS', 'F1120SubConsolFactory',
									F1120SubConsolController ])

			function F1120SubConsolController($scope, $rootScope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, workspaceFactory,
					JsonObjectFactory, $timeout, GENERAL_CONFIG, USER_SETTINGS,
					F1120SubConsolFactory) {

				var vm = this;

				console.log("=======F1120SubConsolController=======");
				console.log(gridData);
				console.log(workspaceFactory.activeScreen.data.data);
				console.log("=======rowData=======");
				console.log(rowData);
				vm.rowData = rowData;
				vm.userSettings = USER_SETTINGS;
				console.log(vm.userSettings);

				vm.entity_type = rowData.ENTITY_TYPE;
				vm.li_loss_carryover = 0;
				vm.nl_loss_carryover = 0;
				vm.li_lpc_comments = "";
				vm.nl_lpc_comments = "";
				vm.li_jsonData = [];
				vm.nl_jsonData = [];
				vm.userreviewd = USER_SETTINGS.user.display_name;
				var sendobj = [];
				vm.cmtflag = false;
				vm.subconsole = false;
				vm.system_updated = "System Calculated";
				vm.title = "Override Limitation for Sub Consol";
				vm.maindata = [];
				vm.capitallossanalysis = [];
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				for (var i = 0; i < workspaceFactory.activeScreen.data.data.length; i++) {
					if (workspaceFactory.activeScreen.data.data[i].LINE_TYPE == 'PUSH_CARRYOVER'
							&& workspaceFactory.activeScreen.data.data[i].ENTITY_TYPE == 'LI') {
						vm.li_loss_carryover = workspaceFactory.activeScreen.data.data[i].AMOUNT;
						vm.li_lpc_comments = "System Calculated based on Prior Year Life Insurance Amounts";
					}
					if (workspaceFactory.activeScreen.data.data[i].LINE_TYPE == 'PUSH_CARRYOVER'
							&& workspaceFactory.activeScreen.data.data[i].ENTITY_TYPE == 'REG') {
						vm.nl_loss_carryover = workspaceFactory.activeScreen.data.data[i].AMOUNT;
						vm.nl_lpc_comments = "System Calculated based on Prior Year Non-Life Amounts";
					}
				}
				vm.reset = function() {
					vm.li_jsonData = [];
					vm.nl_jsonData = [];
					fetchcosoldata();
				}
				// em44az

				fetchcosoldata();
				function fetchcosoldata() {
					var params = {
						"action_code" : 'em44az',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.li_jsonData = [];
											vm.nl_jsonData = [];
											vm.maindata = [];
											vm.maindata = JSON
													.parse(JSON
															.stringify(data.jsonObject));
											vm.li_jsonData
													.push(data.jsonObject[0]);
											vm.nl_jsonData
													.push(data.jsonObject[1]);
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				/*
				 * vm.newOverride = function(entity_type) { if (entity_type ==
				 * 'LI') { var occurrence = 1; // need to update Occurrence
				 * logic var new_row = { "ENTITY_TYPE" : "LI", "AMOUNT" : "0",
				 * "USER_UPDATED" : vm.userreviewd, "COMMENTS" : "", "cmt_flag" : "" };
				 * vm.li_jsonData.push(new_row); } else { var new_row = {
				 * "ENTITY_TYPE" : "NLI", "AMOUNT" : "0", "USER_UPDATED" :
				 * vm.userreviewd, "COMMENTS" : "", "cmt_flag" : "" };
				 * vm.nl_jsonData.push(new_row); } } vm.removeOverride =
				 * function(entity, $index) {
				 * 
				 * if (entity == 'LI') { vm.li_jsonData.splice($index, 1);
				 *  } else { vm.nl_jsonData.splice($index, 1);
				 *  } }
				 */
				/*
				 * vm.amt_change = function(f) { vm.cmtflag = false; if (f ==
				 * 'LI') { for ( var d in vm.li_jsonData) {
				 * 
				 * vm.li_jsonData[d].cmt_flag = "";
				 * 
				 * if (vm.li_jsonData[d].AMOUNT == 0 || vm.li_jsonData[d].AMOUNT ==
				 * undefined) { vm.li_jsonData[d].cmt_flag = true; vm.cmtflag =
				 * true; } } } else { for ( var e in vm.li_jsonData) {
				 * 
				 * vm.nl_jsonData[e].cmt_flag = "";
				 * 
				 * if (vm.nl_jsonData[e].AMOUNT == 0 || vm.nl_jsonData[e].AMOUNT ==
				 * undefined) { vm.nl_jsonData[e].cmt_flag = true; vm.cmtflag =
				 * true; } } } }
				 */
				vm.save = function() {

					if (vm.subconsole) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					vm.subconsole = true;
					if (vm.li_jsonData.length > 0) {
						for ( var dd in vm.li_jsonData) {

							if (vm.li_jsonData[dd].AMOUNT === 0
									|| vm.li_jsonData[dd].AMOUNT === undefined
									|| vm.li_jsonData[dd].COMMENTS === ""
									|| vm.li_jsonData[dd].COMMENTS === undefined) {
								AlertService
										.add(
												"danger",
												"Please correct the errors below",
												4000);
								vm.subconsole = false;
								return;

							}
						}
					}
					if (vm.nl_jsonData.length > 0) {
						for ( var ee in vm.nl_jsonData) {

							if (vm.nl_jsonData[ee].AMOUNT === 0
									|| vm.nl_jsonData[ee].AMOUNT === undefined
									|| vm.nl_jsonData[ee].COMMENTS === ""
									|| vm.nl_jsonData[ee].AMOUNT === undefined) {
								AlertService
										.add(
												"danger",
												"Please correct the errors below",
												4000);
								vm.subconsole = false;
								return;
							}
						}
					}
					var dummyJson = {};
					sendobj = [];
					dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
					dummyJson["scenario"] = GlobalService.globalParams.scenario;
					dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
					sendobj = vm.li_jsonData.concat(vm.nl_jsonData);
					var message = "Saved Successfully!"
					reclassCRUDHttp(dummyJson, sendobj, message);
				}
				function reclassCRUDHttp(tempobj, accountArray, message) {

					F1120SubConsolFactory.savesubconsolData(tempobj,
							accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									vm.subconsole = false;
								} else {
									AlertService.add("success", message, 4000);
									// BROADCAST CHANGE
									$uibModalInstance.dismiss('cancel');

									var args = {
										object_id : vm.rowData.object_id,
										gridFilter : {
											object_id : vm.rowData.object_id
										}
									};
									$rootScope.$emit('gridUpdate', args);

								}
							});

				}

			}

			return controllers;

		});