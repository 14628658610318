define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltApiResponseService',[])

        .factory("sltApiResponseFactory",['GENERAL_CONFIG', 'AlertService', '$http', '$rootScope',
                function(GENERAL_CONFIG, AlertService, $http, $rootScope) {
          var sltApiResponseFactory = {};
          
            var URLS = {
                getSltResponse: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32710",
                getMeCodes: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32736"
            }

            //Service call to Get all the user list.. 32653  
            sltApiResponseFactory.getSltResponseData = function(type, id) {
                var promise = $http({
                    method: "GET",
                    url: URLS.getSltResponse + '&input='+ type +'&log_id=' + id
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }

            sltApiResponseFactory.getMeCodesList = function(params) {
              
                var promise = $http({
                    method: "GET",
                    //url: URLS.reSyncGroup + '?job_id=' + params.job_id + '&job_params=' + params.job_params + '&tax_year=' + params.tax_year + '&scenario=' + params.scenario + '&jcd_key=' + params.jcd_key,
                    url: URLS.getMeCodes + '&tax_year=' + params.tax_year,
                    //data: params
                }).then(function(response) {
                    return response;
                });
                return promise;
            }

            sltApiResponseFactory.updateJob = function(_data) {
            
                var promise = $http({
                    method: "POST",
                    url: URLS.updateJob,
                    data: _data
                }).then(function(response) {
                    return response;
                });
                return promise;
            }

            return sltApiResponseFactory;
        }])

    return services;
});