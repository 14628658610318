define([
    'angular',
    './Section174Service'



], function () {
    'use strict';
    return angular.module('app.section174', ['app.section174Service'] );


});