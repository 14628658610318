define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.Form1125AService', [])

        .factory("Form1125AFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
                var factory = {};
                    
                factory.save1125ADetails = function (_callName, _data, isNew) {
                    var defer = $q.defer();
                    if(_data.rowData && _data.rowData.SCENARIO != _data.rowData.SCENARIO_KEY) {
                        AlertService.add("error", "Selected Scenario is Locked, please verify and update the amount.");
                        defer.reject();
                        return defer.promise;
                    }
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=d58wez";
                    clob_data.push(_data.rowData);
                    clob_setting = {tax_year: GlobalService.globalParams.tax_year};
                    params.process_name = "F1125A_EDIT_SAVE";
                    params.tax_year = GlobalService.globalParams.tax_year;
                    params.scenario = GlobalService.globalParams.scenario;
                    params.jcd_key = GlobalService.globalParams.jcd_key;
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                	combination_key: _data.rowData.COMBINATION_KEY
                                };
                                $rootScope.$broadcast("showDataRefresh", true);
                                setTimeout(function(){
                                	$rootScope.$broadcast("showDataRefresh", false);
                                	$rootScope.$emit('gridUpdate', args);},5000);
                                defer.resolve(data);
                        } else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    }
                    ,function(data){
                        defer.reject(data);
                    });
                    return defer.promise;
                }
                return factory;
            }
        ])
    return services;
});