define([
    'angular'

], function () {
    'use strict';


       var services =  angular.module('app.taxLiabilityDashboardService',[])
       
        .factory("TaxLiabilityDashboardFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
	         
        	 var taxLiabilityDashboardFactory = {};
 	         
        	 taxLiabilityDashboardFactory.getTaxLiability = function(tax_year,combination_key,group_id) {
                 var params = {
                     "action_code": "nullj8",
                     "tax_year": tax_year,
                     "combination_key": combination_key,
                     "group_id": group_id
                 };
                 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                     if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        // AlertService.add("error", data.errorMessage);
                     } else {
                         if (data.jsonObject.length) {
                             return data.jsonObject;
                         }
                     }
                 });
             };
             
             taxLiabilityDashboardFactory.getTotalTaxLiability = function(tax_year,combination_key,group_id) {
                 var params = {
                     "action_code": "zo47bc",
                     "tax_year": tax_year,
                     "combination_key": combination_key,
                     "group_id": group_id
                 };
                 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                     if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        // AlertService.add("error", data.errorMessage);
                     } else {
                         if (data.jsonObject.length) {
                             return data.jsonObject;
                         }
                     }
                 });
             };
             
             taxLiabilityDashboardFactory.getStatus = function(tax_year,combination_key) {
                 var params = {
                     "action_code": "p7vu4i",
                     "tax_year": tax_year,
                     "combination_key": combination_key,
                 };
                 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                     if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        // AlertService.add("error", data.errorMessage);
                     } else {
                         if (data.jsonObject.length) {
                             return data.jsonObject;
                         }
                     }
                 });
             };
             
             taxLiabilityDashboardFactory.save = function(_data,settings){
             	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=sukz5u";
             	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonSetting =  JSON.stringify(settings);
                var jsonObj =  JSON.stringify(_data);
                var params = filterParams;
                 params = _.merge({},  GlobalService.globalParams , params);
                 params =  _.extend({jsonObj:jsonObj}, params);
                 params =  _.extend({jsonSetting:jsonSetting}, params);
                 console.log(params);
                 var promise = $http({
                     method: "post",
                     url: url,
                     data: params
                 }).then(function (response){
          				console.log(response.data);
 	          				if(response.data.callSuccess === "1"){
 	                            AlertService.add("success", "Data saved Successfully.",3000);
 	                           
 	                        }else{
 	                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
 	                        }
 	          				return response.data;
      				})

                 return promise;	          	 
 	        }
 	        
 	        taxLiabilityDashboardFactory.delete = function(_data,isDelete){
             	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zndef9";
             	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                 var jsonSettings = [{
 	                tax_year:_data.TAX_YEAR,
 	                combination_keys:_data.combination_key//||_data.COMBINATION_KEYS
 	   			}];
                 var jsonObj =  JSON.stringify(_data);
                 var params = filterParams;
                 params = _.merge({},  GlobalService.globalParams, params);
                 params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
                 params = _.extend({jsonObj:jsonObj}, params);
                 console.log(params);
                 var promise = $http({
                     method: "post",
                     url: url,
                     data: params
                 }).then(function (response){
          				console.log(response.data);
 	          				if(response.data.callSuccess === "1"){
 	                            AlertService.add("success", "Data deleted Successfully.",3000);
 	                           
 	                        }else{
 	                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
 	                        }
 	          				return response.data;
      				})

                 return promise;	          	 
 	        }
             
             taxLiabilityDashboardFactory.saveStatus = function(_data){
               	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=514k49";
               	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                   var jsonSettings = [{
   	                combination_key:_data.combination_key
   	   			}];
                   var jsonObj =  JSON.stringify(_data);
                   var params = filterParams;
                   params = _.merge({},  GlobalService.globalParams, params);
                   params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
                   params = _.extend({jsonObj:jsonObj}, params);
                   console.log(params);
                   var promise = $http({
                       method: "post",
                       url: url,
                       data: params//JSON.stringify(_data)
                   }).then(function (response){
                	   var status = response.status;
   	          				return response.data;
        				})
        				
				   return promise;	          	 
   	        }

        	 
        	 return taxLiabilityDashboardFactory;
        }]);


    return services;


});