/**
 * Created by 400371031 on 1/7/2020.
 */

define([
    'angular',
    './CounterPartyCtrl',
    './CounterPartyService'

], function () {
    'use strict';
    return angular.module('app.CounterParty', ['app.CounterPartyCtrl', 'app.CounterPartyService']);

});