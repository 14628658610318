
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.batchRequestService',[])

    .factory('batchRequestServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var batchRequestServiceFactory = {};
        
        batchRequestServiceFactory.getMonitoringData = function(params){
            var params = params;
            var promise = $http({
                method: "get",
                url: GENERAL_CONFIG.base_url + '/getRequestData?request_id=' + params.request_id + '&ist_tkn=' + params.ist_tkn,
                data: params
            }).then(function (response) {
                return response;
            });
            return promise;
        };
        
        return batchRequestServiceFactory
    }])

    return services;

});