define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.dcsSchdBCController',[])
        .controller('SetAllController',  ['$scope','$rootScope','$uibModalInstance','JsonObjectFactory','AlertService' ,'gridData','GENERAL_CONFIG', setAllController] )
        .controller('SetQuestionsController',  ['$scope','$rootScope','$uibModalInstance','JsonObjectFactory','AlertService' ,'gridData','GENERAL_CONFIG', setQuestionsController] );



    function setQuestionsController ($scope,$rootScope,$uibModalInstance,JsonObjectFactory,AlertService,gridData,GENERAL_CONFIG) {
        var vm = this;
        vm.title = "Schedule B Questions";
        vm.questions = [];
        vm.loading = true;
        getQuestions();
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }



        function getQuestions() {

            var params = {
                "action_code": 'wlo965',
                "tax_year": gridData.rowData.TAX_YEAR,
                "leid": gridData.rowData.LEID,
                "cdr_no": gridData.rowData.CDR_NO,
                "bsla_code": gridData.rowData.BSLA_CODE,
                "scenario": gridData.rowData.SCENARIO,
                "reporting_period": gridData.rowData.REPORTING_PERIOD,
                "filing_group":  gridData.rowData.FILING_GROUP
            }

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.questions = data.jsonObject;
                }
                vm.loading = false;
            });

        };




        function prepareData(_data){
            var entities = [];

            _.forEach(_data, function(_value, key) {
                var obj = {
                    "tax_year": gridData.rowData.TAX_YEAR,
                    "leid": gridData.rowData.LEID,
                    "cdr_no": gridData.rowData.CDR_NO,
                    "bsla_code": gridData.rowData.BSLA_CODE,
                    "scenario": gridData.rowData.SCENARIO,
                    "reporting_period": gridData.rowData.REPORTING_PERIOD,
                    "line_no" : _value.LINE_NO,
                    "line_value" : _value.LINE_VALUE
                   };
                entities.push(obj);
            })



            return entities;
        }


        vm.save = function(){
            vm.crudLoading = true;
            var params = {
                "action_code": 'qom573',
            }
            params.json_data = JSON.stringify(prepareData(vm.questions));

            return   JsonObjectFactory.saveJSONWParams(GENERAL_CONFIG.base_url + '/saveJsonObject', params).then(function (data) {

                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage, 4000);
                    vm.crudLoading = false;
                } else {
                    AlertService.add("success", "Successfully Saved " , 2000);
                    vm.crudLoading = false;

                    var key_string = gridData.rowData.LEID + "_" +gridData.rowData.CDR_NO + "_" +gridData.rowData.REPORTING_PERIOD + "_" +gridData.rowData.BSLA_CODE + "_" +gridData.rowData.FUNC_CURR;
                    var args = {key_string:key_string};
                    $rootScope.$emit('gridUpdate', args);
                }
            });


        }



    }



    function setAllController ($scope,$rootScope,$uibModalInstance,JsonObjectFactory,AlertService,gridData,GENERAL_CONFIG) {
        var vm = this;
        vm.title = "Schedule B Set All Questions";
        vm.entities = gridData;
        vm.questions = [];
        vm.questionsGP3 = [];
        vm.crudLoading = false;
        vm.loading = true;
        getQuestions();
        getQuestionsGP3();

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }


        function getQuestions() {
            var params = {
                "action_code": '05rp83',
            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage, 3000);
                } else {
                    vm.questions = data.jsonObject;
                }

                vm.loading = false;
            });
        };


        function getQuestionsGP3() {
            var params = {
                "action_code": '05rp83',
                "filing_group": 3,

            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage, 3000);
                } else {
                    vm.questionsGP3 = data.jsonObject;
                }

                vm.loading = false;
            });
        };



        function prepareEntities(_data){
            var entities = []
            _.forEach(_data, function(_value, key) {
                entities.push(_value.data);
            })
            return entities;
        }


        function buildKeys(){
            var keyString = "";
            var loopNum = 0;
            _.forEach(vm.entities, function(_value, key) {
                if( loopNum > 0){
                    keyString += ",";
                }
                var value = _value.data;
                //console.log(" value " , value)
                keyString += value.LEID + "_" +value.CDR_NO + "_" +value.REPORTING_PERIOD + "_" +value.BSLA_CODE + "_" +value.FUNC_CURR;
                loopNum++;
            });

            return keyString;
        }


        vm.save = function(){
            vm.crudLoading = true;
            var params = {
                "action_code": 'gvjm8n'
            }
            
            params.json_data = JSON.stringify(prepareEntities(vm.entities));

            return   JsonObjectFactory.saveJSONWParams(GENERAL_CONFIG.base_url + '/saveJsonObject', params).then(function (data) {

                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage, 3000);
                    vm.crudLoading = false;

                } else {
                    AlertService.add("success", "Successfully Saved " , 2000);
                    vm.crudLoading = false;

                    var key_string = buildKeys();
                    var args = {key_string:key_string};

                    console.log("args " , args);

                    $rootScope.$emit('gridUpdate', args);
                    vm.crudLoading = false;



                }


            });





        }



    }


    return controllers;

});
