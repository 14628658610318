define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.scheduleF5471-OCtrl', [])
			.controller('F5471scheduleOCtrl', ['CommonUtilitiesFactory', 'GlobalService','$scope', 'JsonObjectFactory','$parse',  '$timeout','scheduleFOServiceFactory',
			 'workspaceFactory', '$state', '$window','$filter', 'AlertService','$rootScope', 'GENERAL_CONFIG', '$injector', 'ModalFactory',F5471scheduleOCtrl])
			.controller('F5471scheduleOCtrlModal', ['CommonUtilitiesFactory', 'GlobalService','$scope', 'JsonObjectFactory','$parse', '$timeout', 'scheduleFOServiceFactory',
			 'workspaceFactory', '$state', '$window','$filter', 'AlertService','$rootScope', 'GENERAL_CONFIG', '$injector', 'ModalFactory', '$uibModalInstance','rowData','colData',
			 'gridData', F5471scheduleOCtrlModal]);

		    function F5471scheduleOCtrl(CommonUtilitiesFactory, GlobalService, $scope, JsonObjectFactory, $parse, $timeout, scheduleFOServiceFactory, workspaceFactory, $state, $window,
	    			$filter, AlertService, $rootScope, GENERAL_CONFIG, $injector, ModalFactory) {
		    	F5471scheduleOCtrlModal(CommonUtilitiesFactory, GlobalService, $scope, JsonObjectFactory, $parse,  $timeout,scheduleFOServiceFactory, workspaceFactory, $state, $window,
		    			$filter, AlertService, $rootScope, GENERAL_CONFIG, $injector, ModalFactory, null, null, null, null, this);
			}

            function F5471scheduleOCtrlModal(CommonUtilitiesFactory, GlobalService, $scope, JsonObjectFactory, $parse, $timeout, scheduleFOServiceFactory, workspaceFactory, $state, $window,
	    			$filter, AlertService, $rootScope, GENERAL_CONFIG, $injector, ModalFactory, $uibModalInstance, rowData, colData, gridData, thisObj) {

            	var vm = this||thisObj;
				vm.rowData = (rowData != null) ? rowData : $scope.$parent.rowData;
              	vm.a = "";
                vm.loading = false;
                vm.dataLoading = true;
                vm.modalTitle = "Schedule O";
               // vm.testing = 0;
                vm.collapsed1 = true;
                vm.collapsed2 = true;
                vm.collapsed3 = true;
                vm.collapsed4 = true;
                vm.collapsed5 = true;
                
                vm.isF5471ScheduleOSaveClicked = false;
                $scope.crudLoading = false;
                $scope.showF5471ScheduleO = null;
                $rootScope.activeScheduleTab = 0;
                vm.sharHolderFlag = true;
                if(angular.isObject($uibModalInstance)) {
					vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
					vm.cancel = function () {
						var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};
						$rootScope.$emit('gridUpdate', args);
						$uibModalInstance.close();
						vm.modal_name = null;
					};
				} else {
					vm.modal_name = null;
				}

                vm.checkForEnabled = function() {
					if(vm.rowData.SCHD_O_ENABLED === undefined || vm.rowData.SCHD_O_ENABLED === null)
					{
						var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
		                  var params = _.merge({}, GlobalService.globalParams, filterParams);
						params.combination_key = vm.rowData.HO_COMBINATION_KEY;//vm.rowData.COMBINATION_KEY,
	                			
						
						return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=y5oe8m", params).then(function(data) {
							//console.log("Response Data x ::", data.jsonObject);
							if (data.callSuccess === "1") {
								console.log("Response Data x ::", data.jsonObject);
								if(data.jsonObject.length > 0 && data.jsonObject[0].SCHD_O_ENABLED == 'Y')
								{
									$scope.showF5471ScheduleO = true;
									getFilingGroups();
									//getCountriesFIR();
									loadAttribDetails();
									getCorpNameChange();
								}
								else
								{
									vm.body = "Schedule O is not enabled for this entity.";

									$scope.showF5471ScheduleO = false;
								}

							}
							else if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
								$scope.showF5471ScheduleO = false;
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
								vm.body = "Schedule O is not enabled for this entity.";

								$scope.showF5471ScheduleO = false;
							}
						});
					}
					else
					{
						if(!vm.rowData.SCHD_O_ENABLED) {
							vm.body = "Schedule O is not enabled for this entity.";
							$scope.showF5471ScheduleO =  false;
						}
						else
						{
							$scope.showF5471ScheduleO =  true;
							getFilingGroups();
							//getCountriesFIR();
							//loadF5471ScheduleODetails();
							loadAttribDetails();
							getCorpNameChange();
						}
					}
							/**/

				 }
                vm.checkForEnabled();

                vm.onTabChange = function(e) {
					   vm.selectedTabName = e;
					   $timeout(function() {
						   var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
						   var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
						   if (movingTab && selectedTab) {
							   $(movingTab).width(selectedTab.offsetWidth);
							   $(movingTab).height(selectedTab.offsetHeight)
								   .css('line-height', selectedTab.offsetHeight + 'px')
								   .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
						   }
					   }, 50);
					  //console.log("ON_tab_change_section_b_data_1---", vm.sectionBData);
				   };

               // vm.countries = CommonUtilitiesFactory.getCountriesFIR();
			function getFilingGroups(){
				var params = {};
                //console.log("SERVER_CONFIG----", SERVER_CONFIG);
				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.action_code= "v63gc1"/*"f5sne6"*/;
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                	if (data.callSuccess === "1") {
	                	vm.filingGroup = data.jsonObject;
	                	getCountriesFIR();
                	}
                });
            }
				function getCountriesFIR() { 
                	
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	                  var params = _.merge({}, GlobalService.globalParams, filterParams);
					
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kbuwei", params)
				.then(function(data) {
					if (data.callSuccess === "1") {
						vm.countries = data.jsonObject;
						loadF5471ScheduleODetails();
					}
            });
            }
           
            
                vm.states = GlobalService.getStates();

                ///////////////////FLAGS
                vm.editFlag = false;
                vm.tabC_flag = false;
                vm.tabD_flag = false;
                vm.tabE_flag = false;
                $scope.submitted= false;
                vm.C_date_flag = false;
                vm.D_date_flag = false;
                vm.E_date_flag = false;
                vm.tab5471_date_flag = false;
                
				vm.sectionAData = [];
				vm.sectionBData = [];
                vm.sectionCData = [];
                vm.sectionDData = [];
                vm.sectionEData = [];
                vm.tab5471Data = [];
                vm.tab5471OriginalData = [];
				
				vm.selectedTabAData = [];
				vm.selectedTabBData = [];
                vm.selectedTabCData = [];
                vm.selectedTabDData = [];
                vm.selectedTabEData = [];
                
                vm.tabAAttribDetails = [];
                vm.tabBAttribDetails = [];
                vm.tabCAttribDetails = [];
                vm.tabDAttribDetails = [];
                vm.tabEAttribDetails = [];

                vm.tempOldscheduleData = [];
                vm.corpNameChangeData = {};
                //vm.nameChangeData={};

///////////////////////////////////////////////////////////////////////////////////////////////////////
                vm.calendar = {
                        opened: false
                };
////////////////////////////////////////////////////////////////////////////////////////////////////////
		        vm.cancel = function () {
		        	$uibModalInstance.dismiss('cancel');
		        	var args = {combination_keys: vm.rowData.HO_COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY:vm.rowData.HO_COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
		        };
////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////
				 function loadF5471ScheduleODetails() {
		        	 vm.editFlag = false;
		        	 vm.dataLoading = true;
		        	 vm.collapsed1 = true;
                     vm.collapsed2 = true;
                     vm.collapsed3 = true;
                     vm.collapsed4 = true;
                     vm.collapsed5 = true;
                     
		        	 var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	                  var params = _.merge({}, GlobalService.globalParams, filterParams);
		        	  params.combination_key = vm.rowData.HO_COMBINATION_KEY;
		        	  
		        	  params.irs_form_no = 'F5471'//vm.rowData.COMBINATION_KEY,
		             // params.  			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
								
							//console.log("Input Data x **********::", params);
					 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=lio6vp", params).then(function(data) {
										//console.log("Response Data x ::", data.jsonObject);
						if (data.callSuccess === "1") {
							vm.sectionAData = [];
							vm.sectionBData = [];
							vm.sectionCData = [];
			                vm.sectionDData = [];
			                vm.sectionEData = [];
			                vm.tab5471Data = [];
							vm.isShow = 1;
							//vm.scheduleODetails = data.jsonObject;
							vm.scheduleODetailsTemp = data.jsonObject;
							console.log("vm.scheduleODetailsTemp", vm.scheduleODetailsTemp);
							console.log("vm.sectionCData.length---",vm.sectionCData.length);
							var line_no = "0";
							var i = 1;
							while(i < vm.scheduleODetailsTemp.length)
							{
								var tempAttribValue = vm.scheduleODetailsTemp[i].attrib_value;
	        					var strArray = tempAttribValue.split("~");
	            				if(strArray[0] != 'LINE_NO')
	            				{
	            					//console.log("vm.scheduleODetailsTemp[j].attrib_value.split()[0]-----------",vm.scheduleODetailsTemp[i].attrib_value.split("~")[0]);
		                			line_no =  strArray[3];
		                			//var j = i+1;
		                			var j = i;
		                			var index = 0;
		                			var tempObj= [];
		                			var countryUSFlag = false;
		                			var pre_occurrence = vm.scheduleODetailsTemp[j].object_id.split("~")[4];
		                			var pre_line_no = vm.scheduleODetailsTemp[i].line_no;
		                			while(j < vm.scheduleODetailsTemp.length && pre_occurrence == vm.scheduleODetailsTemp[j].object_id.split("~")[4] && pre_line_no == vm.scheduleODetailsTemp[j].line_no)
		                			{
		                				var attrib_details = vm.scheduleODetailsTemp[j].attrib_value;
		            					var detailsArr = attrib_details.split("~");
		            					var object_details= vm.scheduleODetailsTemp[j].object_id;
		                				var objectArr = object_details.split("~");
		                				if(pre_line_no != '4' && objectArr[4] != "0")
		                				{
		                					var value = (angular.isDefined(vm.scheduleODetailsTemp[j].VALUE) ? vm.scheduleODetailsTemp[j].VALUE : null);
		                					var disValue = null;
		                					if( (detailsArr[0] == "NAME_OF_SHAREHOLDER_FILING" || detailsArr[0] == "NAME_OF_SHAREHOLDER_DISPOSING") && angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                					{
		                						value = vm.getLegalEntity(vm.scheduleODetailsTemp[j].VALUE);
		                					}
		                					if( (detailsArr[0] == "COUNTRY"  || detailsArr[0] == "5471_O_US_PER_OFF_DIR_F_COUNTRY")&& angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                					{
		                						if(vm.scheduleODetailsTemp[j].VALUE == "US")
		                							countryUSFlag = true;
		                						value = vm.getCountryInfo(vm.scheduleODetailsTemp[j].VALUE);
		                					}
		                					if( (detailsArr[0] == "STATE" || detailsArr[0] =="5471_O_US_PER_OFF_DIR_US_STATE")&& angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                					{
		                						if(countryUSFlag == true)
		                							value = vm.getStateInfo(vm.scheduleODetailsTemp[j].VALUE);
		                					}
		                					if(detailsArr[0] == "NUMBER_OF_DIRECTLY_SHARES_ACQUIRED" || detailsArr[0] == "NUMBER_OF_INDIRECTLY_SHARES_ACQUIRED" || detailsArr[0] == "NUMBER_OF_CONSTRUCTIVELY_SHARES_ACQUIRED" || detailsArr[0] == "AMT_PAID_OR_VALUE_GIVEN" || detailsArr[0] == "NUMBER_OF_DIRECTLY_SHARES_DISPOSED" || detailsArr[0] == "NUMBER_OF_INDIRECTLY_SHARES_DISPOSED" || detailsArr[0] == "NUMBER_OF_CONSTRUCTIVELY_SHARES_DISPOSED" || detailsArr[0] == "AMT_RECEIVED" || detailsArr[0] == "FAIR_MARKET_VALUE" || detailsArr[0] == "ADJUST_BASIS")
		                					{
		                						if(angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                								value = parseInt(vm.scheduleODetailsTemp[j].VALUE);
		                						else
		                							value = 0;
		                					}
		                					if(detailsArr[0] == "CORPORATION_NAME_CHANGE")
		                						value = (angular.isDefined(vm.scheduleODetailsTemp[j].VALUE) ? (vm.scheduleODetailsTemp[j].VALUE == 'true' ? true : false) : false); 
		                					if((detailsArr[0] == "ZIP_POSTAL_CODE" ||detailsArr[0] == "5471_O_US_PER_OFF_DIR_US_ZIP" ))
		                						value = (angular.isDefined(vm.scheduleODetailsTemp[j].VALUE) ? vm.scheduleODetailsTemp[j].VALUE : null);
		                					if(detailsArr[0] == "IDENTIFYING_NUMBER" )
		                					{
		                						if(angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                						{
		                							if(vm.scheduleODetailsTemp[j].VALUE == 'APPLD FOR')
		                							{
		                								value = vm.scheduleODetailsTemp[j].VALUE;
		                								disValue = null;
		                							}
		                							else
		                							{
		                								value = vm.scheduleODetailsTemp[j].VALUE;
		                								disValue = vm.scheduleODetailsTemp[j].VALUE;
		                							}
		                						}
		                						else
		                						{
		                							value = null;
		                							disValue = null;
		                						}
		                					}
		                					if(detailsArr[0] == "5471_O_ORG_REORG_MISSING_EIN_REASON" )
		                					{
		                						if(angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                						{
		                							if(vm.scheduleODetailsTemp[j].VALUE == 'FOREIGNUS')
		                							{
		                								value = vm.scheduleODetailsTemp[j].VALUE;
		                								disValue = null;
		                							}
		                							else
		                							{
		                								value = vm.scheduleODetailsTemp[j].VALUE;
		                								disValue = vm.scheduleODetailsTemp[j].VALUE;
		                							}
		                						}
		                						else
		                						{
		                							value = null;
		                							disValue = null;
		                						}
		                					}
		                					
		                					if(detailsArr[0] == "5471_O_US_PER_OFF_DIR_OFFICER" || detailsArr[0] == "5471_O_US_PER_OFF_DIR_DIRECTOR")
		                					{
		                						if(angular.isDefined(vm.scheduleODetailsTemp[j].VALUE))
		                						{
		                							if(vm.scheduleODetailsTemp[j].VALUE == '1')
		                							{
		                								value = 'Y';
		                							}
		                							else
		                							{
		                								value = '';
		                							}
		                						}
		                						
		                					}

		                					tempObj[index] = {
												i : vm.scheduleODetailsTemp[j],
			                					"trans_type_key":objectArr[1],
			                					"attrib_name":detailsArr[0],
			                					"line_attrib_key":detailsArr[1],
			                					"trans_details_key":objectArr[3],
			                					"occurrence":objectArr[4],
			                					"attrib_description" : detailsArr[3],
			                					"form_key" : objectArr[6],
			                					"line_no": pre_line_no,
			                					"VALUE":value,
			                					"DISVALUE":disValue,
			                					"attrib_order":parseInt(detailsArr[4]),
			                					"updated_by" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_by) ? vm.scheduleODetailsTemp[j].updated_by : null),
			                					"updated_on" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_on) ? vm.scheduleODetailsTemp[j].updated_on : null)

			                				 };
		                				}
		                				else if(pre_line_no == '4')
		                				{
		                					var value = (angular.isDefined(vm.scheduleODetailsTemp[j].VALUE) ? vm.scheduleODetailsTemp[j].VALUE : null);
		                					if(detailsArr[0] == "REORGANIZATION_DATE")
		                					{
		                						if(value != null)
		                						{
		                							var dateSet = new Date(value);
		                							value = new Date(dateSet.getFullYear(), dateSet.getMonth(), dateSet.getDate());
		                						}
		                					}
		                					var mainIndex = vm.tab5471Data.length;
		                					vm.tab5471Data[mainIndex] = {
													i : vm.scheduleODetailsTemp[j],
				                					"trans_type_key":objectArr[1],
				                					"attrib_name":detailsArr[0],
				                					"line_attrib_key":detailsArr[1],
				                					"trans_details_key":objectArr[3],
				                					"occurrence":objectArr[4],
				                					"attrib_description" : detailsArr[3],
				                					"form_key" : objectArr[6],
				                					"line_no": pre_line_no,
				                					"VALUE":value,
				                					"attrib_order":parseInt(detailsArr[4]),
				                					"updated_by" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_by) ? vm.scheduleODetailsTemp[j].updated_by : null),
				                					"updated_on" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_on) ? vm.scheduleODetailsTemp[j].updated_on : null)

				                				 };

		                					vm.tab5471OriginalData[mainIndex] = {
													i : vm.scheduleODetailsTemp[j],
				                					"trans_type_key":objectArr[1],
				                					"attrib_name":detailsArr[0],
				                					"line_attrib_key":detailsArr[1],
				                					"trans_details_key":objectArr[3],
				                					"occurrence":objectArr[4],
				                					"attrib_description" : detailsArr[3],
				                					"form_key" : objectArr[6],
				                					"line_no": pre_line_no,
				                					"VALUE":value,
				                					"attrib_order":parseInt(detailsArr[4]),
				                					"updated_by" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_by) ? vm.scheduleODetailsTemp[j].updated_by : null),
				                					"updated_on" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_on) ? vm.scheduleODetailsTemp[j].updated_on : null)

				                				 };
		                				}
		                				index++;
		                				j++;
		                			}
		                			//console.log("tempObj-----------", tempObj);
		                			if(pre_line_no == "1" && tempObj.length > 0)
		                			{
		                				var tempIndex = vm.sectionCData.length;
		                				vm.sectionCData[tempIndex] = tempObj;
		                			}
		                			else if(pre_line_no == "2" && tempObj.length > 0 )
		                			{
		                				var tempIndex = vm.sectionDData.length;
		                				vm.sectionDData[tempIndex] = tempObj;
		                			}
		                			else if(pre_line_no =="3" && tempObj.length > 0)
		                			{
		                				var tempIndex = vm.sectionEData.length;
		                				vm.sectionEData[tempIndex] = tempObj;
		                			}
		                			else if(pre_line_no =="6" && tempObj.length > 0)
		                			{
		                				var tempIndex = vm.sectionBData.length;
		                				vm.sectionBData[tempIndex] = tempObj;
		                			}
		                			else if(pre_line_no =="5" && tempObj.length > 0)
		                			{
		                				var tempIndex = vm.sectionAData.length;
		                				vm.sectionAData[tempIndex] = tempObj;
		                			}
		                			i = j;
		                			
	            				}
	            				else
	            					i++;
							}
							/*vm.sectionCData_temp = _.sortBy(vm.sectionCData_temp, 'attrib_order');
							var pre_occurrence = (vm.sectionCData_temp[c].length > 0)? vm.sectionCData_temp[0].occurrence : null;
							var tempObj1 = [];
							var index = 0;
							for(var c = 0; c < vm.sectionCData_temp.length; c++)
							{
								var curr_occurrence = vm.sectionCData_temp[c].occurrence;
								if(curr_occurrence == pre_occurrence)
								{
									tempObj1[index] = {
											i : vm.scheduleODetailsTemp[j],
		                					"trans_type_key":objectArr[1],
		                					"attrib_name":detailsArr[0],
		                					"line_attrib_key":detailsArr[1],
		                					"trans_details_key":objectArr[3],
		                					"occurrence":objectArr[4],
		                					"attrib_description" : detailsArr[3],
		                					"form_key" : objectArr[6],
		                					"line_no": line_no,
		                					"VALUE":value,
		                					"DISVALUE":disValue,
		                					"attrib_order":parseInt(detailsArr[4]),
		                					"updated_by" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_by) ? vm.scheduleODetailsTemp[j].updated_by : null),
		                					"updated_on" : (angular.isDefined(vm.scheduleODetailsTemp[j].updated_on) ? vm.scheduleODetailsTemp[j].updated_on : null)
	
		                				 };
									index++;
								}
								else
								{
									
								}
							}*/
							vm.tab5471Data = _.sortBy(vm.tab5471Data, ['attrib_order']);
							
							/*for(var k = 0; k < vm.sectionCData.length; k++ )
							{
								var min = k;
								for(var m = k+1 ; m< vm.sectionCData.length; m++)
								{
									if(vm.sectionCData[min].attrib_order > vm.sectionCData[m].attrib_order)
										min = m;
								}
								
								if(min !== k)
								{
									var temp_obj = vm.sectionCData[k];
									vm.sectionCData[k] = vm.sectionCData[min];
									vm.sectionCData[min] = temp_obj;
								}
							}*/
							for(var m=0; m < vm.sectionCData.length; m++)
								vm.sectionCData[m] = _.sortBy(vm.sectionCData[m], 'attrib_order');
							
							for(var m=0; m < vm.sectionDData.length; m++)
								vm.sectionDData[m] = _.sortBy(vm.sectionDData[m], 'attrib_order');
							
							for(var m=0; m < vm.sectionEData.length; m++)
								vm.sectionEData[m] = _.sortBy(vm.sectionEData[m], 'attrib_order');
								
						    for(var m=0; m < vm.sectionBData.length; m++)
								vm.sectionBData[m] = _.sortBy(vm.sectionBData[m], 'attrib_order');	
								
							for(var m=0; m < vm.sectionAData.length; m++)
								vm.sectionAData[m] = _.sortBy(vm.sectionAData[m], 'attrib_order');		
									
							console.log("section_a_data---", vm.sectionAData);
							console.log("section_b_data---", vm.sectionBData);
							console.log("section_c_data---", vm.sectionCData);
							console.log("section_d_data---", vm.sectionDData);
							console.log("section_e_data---", vm.sectionEData);
							console.log("tab5471Data---", vm.tab5471Data);
							vm.dataLoading = false;
						}
						else
						{
							if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});

				 }
					  /////////////INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////

                function loadAttribDetails()
                {
                	var params = {
							      "irs_form_no":'F5471'
					             }
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=iqrhud", params).then(function(data) {
						//console.log("Response Data x ::", data.jsonObject);
						if (data.callSuccess === "1") {
							var tabAttribDetails = data.jsonObject;
							//console.log("tabAttribDetails--------------", tabAttribDetails);
							var tabC = angular.fromJson(tabAttribDetails[0]).LINE1_ATTRIB;
							vm.tabCAttribDetails = angular.fromJson(tabC);

							var tabD = angular.fromJson(tabAttribDetails[0]).LINE2_ATTRIB;
							vm.tabDAttribDetails = angular.fromJson(tabD);

							var tabE = angular.fromJson(tabAttribDetails[0]).LINE3_ATTRIB;
							vm.tabEAttribDetails = angular.fromJson(tabE);
					
							var tabB = angular.fromJson(tabAttribDetails[0]).LINE6_ATTRIB;
							vm.tabBAttribDetails = angular.fromJson(tabB);
							
							var tabA = angular.fromJson(tabAttribDetails[0]).LINE5_ATTRIB;
							vm.tabAAttribDetails = angular.fromJson(tabA);

							///////////////////Setting data in selected tab data///////////////
							
							/// tab A
							var taba_index = 0;
							for(var i =  0; i<vm.tabAAttribDetails.length; i++)
							{
									var value = null;								   
									vm.selectedTabAData[taba_index] = {
											"line_no" : vm.tabAAttribDetails[i].line_no,
											"line_attrib_key" : vm.tabAAttribDetails[i].line_attrib_key,
											"attrib_name" : vm.tabAAttribDetails[i].attrib_name,
											"attrib_description" : vm.tabAAttribDetails[i].attrib_description,
											"form_key" : vm.tabAAttribDetails[i].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : value,
											"trans_type_key" : vm.tabAAttribDetails[i].trans_type_key
									};
									taba_index++;
							}
							
							/// tab B
							var tabb_index = 0;
							for(var i =  0; i<vm.tabBAttribDetails.length; i++)
							{
								
								if(vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ADDR_1'  && 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ADDR_2'&&
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_CITY'&& 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_STATE' && 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ZIP')
								{
									var value = null;								   
									vm.selectedTabBData[tabb_index] = {
											"line_no" : vm.tabBAttribDetails[i].line_no,
											"line_attrib_key" : vm.tabBAttribDetails[i].line_attrib_key,
											"attrib_name" : vm.tabBAttribDetails[i].attrib_name,
											"attrib_description" : vm.tabBAttribDetails[i].attrib_description,
											"form_key" : vm.tabBAttribDetails[i].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : value,
											"trans_type_key" : vm.tabBAttribDetails[i].trans_type_key
									};
									tabb_index++;
							}

								
							}
							
							/// tab C
							var tabc_index = 0;
							for(var i =  0; i<vm.tabCAttribDetails.length; i++)
							{
								
								if(vm.tabCAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1'  && vm.tabCAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' && vm.tabCAttribDetails[i].attrib_name !== 'CITY'&& vm.tabCAttribDetails[i].attrib_name !== 'STATE' && vm.tabCAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' 
								&& vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_SH_NAME_1' && vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_SH_NAME_2' && vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_PERSON_NAME' )
								{
									var value = null;
								
									if(vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_DIRECTLY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_CONSTRUCTIVELY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_INDIRECTLY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'AMT_PAID_OR_VALUE_GIVEN')
										value = 0;
									else if(vm.tabCAttribDetails[i].attrib_name  == 'PERSON_BUSINESS_VALUE')
										value = "B";
									else if(vm.tabCAttribDetails[i].attrib_name == 'NAME_OF_SHAREHOLDER_FILING')
										value = {};
									
									vm.selectedTabCData[tabc_index] = {
											"line_no" : vm.tabCAttribDetails[i].line_no,
											"line_attrib_key" : vm.tabCAttribDetails[i].line_attrib_key,
											"attrib_name" : vm.tabCAttribDetails[i].attrib_name,
											"attrib_description" : vm.tabCAttribDetails[i].attrib_description,
											"form_key" : vm.tabCAttribDetails[i].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : value,
											"trans_type_key" : vm.tabCAttribDetails[i].trans_type_key
									};
									tabc_index++;
							}

								//vm.selectedTabCData[i]["]
							}

							/// tab D
							var tabd_index = 0;
							for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							{
								if( vm.tabDAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1' && vm.tabDAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' &&  vm.tabDAttribDetails[i].attrib_name !== 'CITY'&& vm.tabDAttribDetails[i].attrib_name !== 'STATE' && vm.tabDAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_SH_NAME_1' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_SH_NAME_2' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_PERSON_NAME')
								{
								var value = null;
								if(vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_DIRECTLY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_INDIRECTLY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_CONSTRUCTIVELY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'AMT_RECEIVED')
									value = 0;
								else if(vm.tabDAttribDetails[i].attrib_name  == 'PERSON_BUSINESS_VALUE')
									value = "B";
								else if(vm.tabDAttribDetails[i].attrib_name == 'NAME_OF_SHAREHOLDER_DISPOSING')
									value = {};

								vm.selectedTabDData[tabd_index] = {
										"line_no" : vm.tabDAttribDetails[i].line_no,
										"line_attrib_key" : vm.tabDAttribDetails[i].line_attrib_key,
										"attrib_name" : vm.tabDAttribDetails[i].attrib_name,
										"attrib_description" : vm.tabDAttribDetails[i].attrib_description,
										"form_key" : vm.tabDAttribDetails[i].form_key,
										"trans_details_key" : null,
										"occurrence" : null,
										"VALUE" : value,
										"trans_type_key" : vm.tabDAttribDetails[i].trans_type_key
								};
								tabd_index++;
								}

								//vm.selectedTabCData[i]["]
							}

							/// tab E
							var tabe_index = 0;
							for(var i =  0; i<vm.tabEAttribDetails.length; i++)
							{
								
								if(vm.tabEAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1' && vm.tabEAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' &&  vm.tabEAttribDetails[i].attrib_name !== 'CITY'&& vm.tabEAttribDetails[i].attrib_name !== 'STATE' && vm.tabEAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' && vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_DATE' /*&& vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_MISSING_EIN_REASON'*/ && vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_SSN' )
								{
								var value = null;
								if(vm.tabEAttribDetails[i].attrib_name == 'FAIR_MARKET_VALUE' || vm.tabEAttribDetails[i].attrib_name == 'ADJUST_BASIS')
									value = 0;
								else if(vm.tabEAttribDetails[i].attrib_name == 'PERSON_BUSINESS_VALUE')
									value = 'B';
								else if(vm.tabEAttribDetails[i].attrib_name == 'CORPORATION_NAME_CHANGE')
									value = false;

								vm.selectedTabEData[tabe_index] = {
										"line_no" : vm.tabEAttribDetails[i].line_no,
										"line_attrib_key" : vm.tabEAttribDetails[i].line_attrib_key,
										"attrib_name" : vm.tabEAttribDetails[i].attrib_name,
										"attrib_description" : vm.tabEAttribDetails[i].attrib_description,
										"form_key" : vm.tabEAttribDetails[i].form_key,
										"trans_details_key" : null,
										"occurrence" : null,
										"VALUE" : value,
										"DISVALUE":value,
										"trans_type_key" : vm.tabEAttribDetails[i].trans_type_key
								};
								tabe_index++;
								}

								//vm.selectedTabCData[i]["]
							}
							//changing order for TAB E data
							/*var temp_array=['5471_O_ORG_REORG_F_ADDR_1', '5471_O_ORG_REORG_F_ADDR_2', '5471_O_ORG_REORG_F_CITY', '5471_O_ORG_REORG_F_STATE', '5471_O_ORG_REORG_F_ZIP'];
							var next = 5;
							for(var k = 0 ; k < temp_array.length; k++)
							{
								//var temp_val = 
								var temp_obj = _.find(vm.selectedTabEData,{attrib_name : temp_array[k]} );
								var temp_obj1 = {
										"line_no" :temp_obj.line_no,
										"line_attrib_key" : temp_obj.line_attrib_key,
										"attrib_name" : temp_obj.attrib_name,
										"attrib_description" : temp_obj.attrib_description,
										"form_key" : temp_obj.form_key,
										"trans_details_key" : null,
										"occurrence" : null,
										"VALUE" : temp_obj.VALUE,
										"DISVALUE":temp_obj.VALUE,
										"trans_type_key" : temp_obj.trans_type_key
								}
								
								if(temp_obj !== undefined )
								{
									var temp_index =  _.findIndex(vm.selectedTabEData,{attrib_name : temp_array[k]} );
									vm.selectedTabEData.splice(temp_index, 1);
									
									var temp_index2 = vm.selectedTabEData.length;
									vm.selectedTabEData[temp_index2] = {
											"line_no" : vm.selectedTabEData[next].line_no,
											"line_attrib_key" : vm.selectedTabEData[next].line_attrib_key,
											"attrib_name" : vm.selectedTabEData[next].attrib_name,
											"attrib_description" : vm.selectedTabEData[next].attrib_description,
											"form_key" : vm.selectedTabEData[next].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : vm.selectedTabEData[next].VALUE,
											"DISVALUE":vm.selectedTabEData[next].VALUE,
											"trans_type_key" : vm.selectedTabEData[next].trans_type_key
									}
									vm.selectedTabEData[next].line_attrib_key = temp_obj1.line_attrib_key;
									vm.selectedTabEData[next].attrib_name = temp_obj1.attrib_name;
									vm.selectedTabEData[next].attrib_description = temp_obj1.attrib_description;
									vm.selectedTabEData[next].VALUE = null;
									
									
								}
								next++;
							}
							var temp_index2 = vm.selectedTabEData.length;
							vm.selectedTabEData[temp_index2] = {
									"line_no" : vm.selectedTabEData[next].line_no,
									"line_attrib_key" : vm.selectedTabEData[next].line_attrib_key,
									"attrib_name" : vm.selectedTabEData[next].attrib_name,
									"attrib_description" : vm.selectedTabEData[next].attrib_description,
									"form_key" : vm.selectedTabEData[next].form_key,
									"trans_details_key" : null,
									"occurrence" : null,
									"VALUE" : vm.selectedTabEData[next].VALUE,
									"DISVALUE":vm.selectedTabEData[next].VALUE,
									"trans_type_key" : vm.selectedTabEData[next].trans_type_key
							};

							vm.selectedTabEData.splice(next, 1);*/
							
							console.log("tabC attrib_data-----", vm.selectedTabCData);
							console.log("tabD attrib_data-----", vm.selectedTabDData);
							console.log("tabE attrib_data-----", vm.selectedTabEData);
							console.log("tabB attrib_data-----", vm.selectedTabBData);
							console.log("tabA attrib_data-----", vm.selectedTabAData);
						}
						else {
							if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});

                }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                function getCorpNameChange()
                {
                	console.log("GlobalService.globalParams-----------", GlobalService.globalParams);
                	var params = {
							"combination_key":vm.rowData.HO_COMBINATION_KEY,
							"tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
							"jcd_key":GlobalService.globalParams.jcd_key,
							"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario
					};
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6pisbi", params).then(function(data) {
						//console.log("Response Data x ::", data.jsonObject);
						if (data.callSuccess === "1") {
							console.log("data.jsonObject-----------------", data.jsonObject);
							vm.corpNameChangeData = data.jsonObject[0];

						}
						else
						{
							if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

                	});
                }

                	//getCorpNameChange();

////////////////////////////////////////////////////////////////////////////////////////////////////////

                vm.openCalendar = function() {
             		vm.calendar.opened = true;
             	};

             	$scope.$on('workflowSave',function(event,data){
             		//console.log();
             		vm.saveData();
			   }); 
             	vm.saveData = function()
             	{
             		vm.save(null, null);
             	}
//SAVE FUNCTIONALITY////////////////////////////////////////////////////////////////////////////////////////////////////////
                vm.save = function(tab,$root) {
                	vm.loading = true;
                	if(tab == null)
                		tab = $rootScope.activeScheduleTab;
                	if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }

                	$scope.crudLoading = true;
                  	//var data = {};
                    switch(tab) {
                        case 0: vm.save5471Tab($root);
                                    break;
                        case 1: vm.tabC_flag = true;
                        		vm.tabD_flag = false;
                        		vm.tabE_flag = false;
                        		vm.tabB_flag = false;
                        		vm.tabA_flag = false;
                        		vm.stock_acqisition($root);//console.log("from add_stock_acqisition");

                        break;
                        case 2: vm.tabC_flag = false;
                        		vm.tabD_flag = true;
                        		vm.tabE_flag = false;
                        		vm.tabB_flag = false;
                        		vm.tabA_flag = false;
                        		vm.stock_disposition($root);
                        		//console.log("from add_stock_disposition");
                        break;
                        case 3: vm.tabC_flag = false;
                        		vm.tabD_flag = false;
                        		vm.tabE_flag = true;
                        		vm.tabB_flag = false;
                        		vm.tabA_flag = false;
                        		vm.reorganization_info($root);
		                       //console.log("from add_reorganization_info");
                        break;
                        case 4: vm.tabC_flag = false;
                        		vm.tabD_flag = false;
                        		vm.tabE_flag = false;
                        		vm.tabB_flag = true;
                        		vm.tabA_flag = false;
                        		vm.sectionb_info($root);
		                       //console.log("from sectionb_info");
                        break;
                        case 5: vm.tabC_flag = false;
                        		vm.tabD_flag = false;
                        		vm.tabE_flag = false;
                        		vm.tabB_flag = false;
                        		vm.tabA_flag = true;
                        		vm.sectiona_info($root);
		                       //console.log("from sectiona_info");
                        break;
                    }
                };
                
                
                

//SAVE TAB5471 ////////////////////////////////////////////////////////////////////////////////////////////////////////

                vm.save5471Tab = function($root)
                {
	                	var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
	                							 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
	                							 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
						var editRow = [];
						for(var i = 0; i<vm.tab5471Data.length; i++)
						{
							var value = null;
							if(vm.tab5471Data[i].attrib_name == 'REORGANIZATION_DATE')
								value = (angular.isDefined(vm.tab5471Data[i].VALUE != null) ? $filter('date')(vm.tab5471Data[i].VALUE, "MM/dd/yyyy")  : null);
							else
							{
								if(angular.isDefined(vm.tab5471Data[i].VALUE) && (vm.tab5471Data[i].VALUE != null && vm.tab5471Data[i].VALUE.toString().length > 0))
									value = vm.tab5471Data[i].VALUE;
								else
									value = null;
							}
							var tempJsonObj = [{
								"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
								"trans_type_key":vm.tab5471Data[i].trans_type_key,
								"combination_key":vm.rowData.HO_COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":vm.tab5471Data[i].form_key,
								"line_no":vm.tab5471Data[i].line_no,
								"occurence":((vm.tab5471Data[i].occurrence == "0") ? null : vm.tab5471Data[i].occurrence),
								"line_attrib_key" : vm.tab5471Data[i].line_attrib_key,
								"Attribute_Name":vm.tab5471Data[i].attrib_name,
								"Attribute_Value":value,
								"trans_dtls_key" : ((vm.tab5471Data[i].trans_details_key == "0") ? null : vm.tab5471Data[i].trans_details_key),
								"trans_status":"A"
							}];

							editRow.push(tempJsonObj);
						 }
						var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

						console.log("scheduleDetails--------------", scheduleDetails);

						scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings, scheduleDetails).then(function(result) {
							if (result.errorMessage && result.errorMessage !== 'null')
							{
								AlertService.add("error", result.errorMessage, 1000);
								$scope.crudLoading = false;
							}
							else
							{
								vm.editFlag = false;
								loadF5471ScheduleODetails();
								AlertService.add("success", "Data is successfully saved/edited", 1000);
								$scope.crudLoading = false;
							}
						});
				}
				
				
				//Save section A
				
				vm.sectiona_info = function($root) {

					console.log("vm.selectedTabAData--------------------",vm.selectedTabAData);
				
						var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
												 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
						var editRow = [];
						for(var i = 0; i<vm.selectedTabAData.length; i++)
						{
							var value = null;
							
							if(vm.selectedTabAData[i].attrib_name == '5471_O_GEN_SH_LAST_FILED')
								{
						          value = (angular.isDefined(vm.selectedTabAData[i].VALUE != null) ? $filter('date')(vm.selectedTabAData[i].VALUE, "MM/dd/yyyy")  : null);
								}
								
							var tempJsonObj = [{
												"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												"trans_type_key":vm.selectedTabAData[i].trans_type_key,
												"combination_key":vm.rowData.HO_COMBINATION_KEY,
												"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
												"form_key":vm.selectedTabAData[i].form_key,
												"line_no":vm.selectedTabAData[i].line_no,
												"occurence":((vm.selectedTabAData[i].occurrence == "0") ? null : vm.selectedTabAData[i].occurrence),
												"line_attrib_key" : vm.selectedTabAData[i].line_attrib_key,
												"Attribute_Name":vm.selectedTabAData[i].attrib_name,
												"Attribute_Value":value,
												"trans_dtls_key" : ((vm.selectedTabAData[i].trans_details_key == "0") ? null : vm.selectedTabAData[i].trans_details_key),
												"trans_status":"A"
											}];
							editRow.push(tempJsonObj);
						}
						var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

						console.log("scheduleDetails--------------", scheduleDetails);

						scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings,
								scheduleDetails).then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 2000);
										$scope.crudLoading = false;
									} else{
										vm.editFlag = false;
										loadF5471ScheduleODetails();
										$rootScope.activeScheduleTab = 0;
										AlertService.add("success", "Section A is successfully saved/edited", 1000);
										$scope.crudLoading = false;
									}
								});

             }
               
               //save section B//////////////////
               
               

				vm.sectionb_info = function($root) {

					console.log("vm.selectedTabBData--------------------",vm.selectedTabBData);
					if(!(angular.isDefined(vm.selectedTabBData[2].VALUE)) || (vm.selectedTabBData[2].VALUE == null) 
						||((vm.selectedTabBData[2].VALUE.COUNTRY_CODE == "US") && (vm.selectedTabBData[0].VALUE == null 
						|| vm.selectedTabBData[3].VALUE == null || vm.selectedTabBData[5].VALUE == null 
						|| vm.selectedTabBData[6].VALUE == null || vm.selectedTabBData[7].VALUE == null)))
					{
						$scope.submitted= true;
						AlertService.add("error", "Please fill out required fields.", 1000);
						$scope.crudLoading = false;
					}
					else if(!(angular.isDefined(vm.selectedTabBData[2].VALUE)) || (vm.selectedTabBData[2].VALUE == null) 
							|| ((vm.selectedTabBData[2].VALUE.COUNTRY_CODE != "US") && (vm.selectedTabBData[0].VALUE == null 
							|| vm.selectedTabBData[3].VALUE == null )))
					{
						$scope.submitted= true;
						AlertService.add("error", "Please fill out required fields.",1000);
						$scope.crudLoading = false;
					}
					else if(!(angular.isDefined(vm.selectedTabBData[8].VALUE)) || (vm.selectedTabBData[8].VALUE !== null) && (vm.selectedTabBData[8].VALUE.length < 9))
					{
						$scope.submitted= true;
						$scope.crudLoading = false;
					}
					else
					{
					
						var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
												 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
						var editRow = [];
						for(var i = 0; i<vm.selectedTabBData.length; i++)
						{
							var value = null;
							if(vm.selectedTabBData[i].attrib_name == '5471_O_US_PER_OFF_DIR_F_COUNTRY')
								value = vm.selectedTabBData[i].VALUE.COUNTRY_CODE;
							else if(vm.selectedTabBData[i].attrib_name == '5471_O_US_PER_OFF_DIR_US_STATE' ||  vm.selectedTabBData[i].attrib_name == '5471_O_US_PER_OFF_DIR_F_STATE')
							{
								if( vm.selectedTabBData[i].VALUE != null && angular.isDefined(vm.selectedTabBData[i].VALUE.code))
									value = vm.selectedTabBData[i].VALUE.code;
								else if(angular.isDefined(vm.selectedTabBData[i].VALUE) && (vm.selectedTabBData[i].VALUE != null && vm.selectedTabBData[i].VALUE.toString().length > 0))
									value = vm.selectedTabBData[i].VALUE;
								else
									value = null;
							}														
							else
							{
								if(angular.isDefined(vm.selectedTabBData[i].VALUE) && (vm.selectedTabBData[i].VALUE != null && vm.selectedTabBData[i].VALUE.toString().length > 0))
									value = vm.selectedTabBData[i].VALUE;
								else
									value = null;
							}


							var tempJsonObj = [{
												"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												"trans_type_key":vm.selectedTabBData[i].trans_type_key,
												"combination_key":vm.rowData.HO_COMBINATION_KEY,
												"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
												"form_key":vm.selectedTabBData[i].form_key,
												"line_no":vm.selectedTabBData[i].line_no,
												"occurence":((vm.selectedTabBData[i].occurrence == "0") ? null : vm.selectedTabBData[i].occurrence),
												"line_attrib_key" : vm.selectedTabBData[i].line_attrib_key,
												"Attribute_Name":vm.selectedTabBData[i].attrib_name,
												"Attribute_Value":value,
												"trans_dtls_key" : ((vm.selectedTabBData[i].trans_details_key == "0") ? null : vm.selectedTabBData[i].trans_details_key),
												"trans_status":"A"
											}];
							editRow.push(tempJsonObj);
						}
						var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

						console.log("scheduleDetails--------------", scheduleDetails);

						scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings,
								scheduleDetails).then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 2000);
										$scope.crudLoading = false;
									} else{
										vm.editFlag = false;
										loadF5471ScheduleODetails();
										$rootScope.activeScheduleTab = 0;
										AlertService.add("success", "Section B is successfully saved/edited", 1000);
										$scope.crudLoading = false;
									}
								});

					}
             }
             
            
//SAVE STOCK ACQUISITION////////////////////////////////////////////////////////////////////////////////////////////////////////
				vm.stock_acqisition = function($root) {

					console.log("vm.selectedTabCData--------------------",vm.selectedTabCData);
					if( vm.C_date_flag || !(angular.isDefined(vm.selectedTabCData[0].VALUE.LEGAL_ENTITY_ID) ) || !(angular.isDefined(vm.selectedTabCData[11].VALUE)) || (vm.selectedTabCData[11].VALUE == null) ||((vm.selectedTabCData[11].VALUE.COUNTRY_CODE == "US") && (vm.selectedTabCData[9].VALUE == null || vm.selectedTabCData[12].VALUE == null || vm.selectedTabCData[14].VALUE == null || vm.selectedTabCData[15].VALUE == null || vm.selectedTabCData[16].VALUE == null)))
					{
						if(!(angular.isDefined(vm.selectedTabCData[0].VALUE.LEGAL_ENTITY_ID)))
								vm.sharHolderFlag = false;
						else
							vm.sharHolderFlag = true;
						$scope.submitted= true;
						AlertService.add("error", "Please fill out required fields.", 1000);
						$scope.crudLoading = false;
					}
					else if( vm.C_date_flag || !(angular.isDefined(vm.selectedTabCData[0].VALUE.LEGAL_ENTITY_ID) ) || !(angular.isDefined(vm.selectedTabCData[11].VALUE)) || (vm.selectedTabCData[11].VALUE == null) || ((vm.selectedTabCData[11].VALUE.COUNTRY_CODE != "US") && (vm.selectedTabCData[9].VALUE == null || vm.selectedTabCData[12].VALUE == null )))
					{
						if(!(angular.isDefined(vm.selectedTabCData[0].VALUE.LEGAL_ENTITY_ID)))
							vm.sharHolderFlag = false;
						else
							vm.sharHolderFlag = true;
						$scope.submitted= true;
						AlertService.add("error", "Please fill out required fields.",1000);
						$scope.crudLoading = false;
					}
					else
					{
						/*var stateTemp = null;
						if(angular.isDefined(vm.selectedTabCData[15].VALUE) && vm.selectedTabCData[15].VALUE != null )
						{
							if(angular.isDefined(vm.selectedTabCData[15].VALUE.code))
								stateTemp = vm.selectedTabCData[15].VALUE.code;
							else
								stateTemp = vm.selectedTabCData[15].VALUE;
						}*/
						var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
												 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
						var editRow = [];
						for(var i = 0; i<vm.selectedTabCData.length; i++)
						{
							var value = null;
							if(vm.selectedTabCData[i].attrib_name == 'NAME_OF_SHAREHOLDER_FILING')
								value = vm.selectedTabCData[i].VALUE.LEGAL_ENTITY_ID;
							else if(vm.selectedTabCData[i].attrib_name == 'DATE_OF_ACQUISITION')
								value = (angular.isDefined(vm.selectedTabCData[i].VALUE != null) ? $filter('date')(vm.selectedTabCData[i].VALUE, "MM/dd/yyyy")  : null);
							else if(vm.selectedTabCData[i].attrib_name == 'COUNTRY')
								value = vm.selectedTabCData[i].VALUE.COUNTRY_CODE;
							else if(vm.selectedTabCData[i].attrib_name == 'STATE' ||  vm.selectedTabCData[i].attrib_name == '5471_O_STOCK_ACQ_F_STATE')
							{
								if( vm.selectedTabCData[i].VALUE != null && angular.isDefined(vm.selectedTabCData[i].VALUE.code))
									value = vm.selectedTabCData[i].VALUE.code;
								else if(angular.isDefined(vm.selectedTabCData[i].VALUE) && (vm.selectedTabCData[i].VALUE != null && vm.selectedTabCData[i].VALUE.toString().length > 0))
									value = vm.selectedTabCData[i].VALUE;
								else
									value = null;
							}
							else
							{
								if(angular.isDefined(vm.selectedTabCData[i].VALUE) && (vm.selectedTabCData[i].VALUE != null && vm.selectedTabCData[i].VALUE.toString().length > 0))
									value = vm.selectedTabCData[i].VALUE;
								else
									value = null;
							}


							var tempJsonObj = [{
												"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
												"trans_type_key":vm.selectedTabCData[i].trans_type_key,
												"combination_key":vm.rowData.HO_COMBINATION_KEY,
												"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
												"form_key":vm.selectedTabCData[i].form_key,
												"line_no":vm.selectedTabCData[i].line_no,
												"occurence":((vm.selectedTabCData[i].occurrence == "0") ? null : vm.selectedTabCData[i].occurrence),
												"line_attrib_key" : vm.selectedTabCData[i].line_attrib_key,
												"Attribute_Name":vm.selectedTabCData[i].attrib_name,
												"Attribute_Value":value,
												"trans_dtls_key" : ((vm.selectedTabCData[i].trans_details_key == "0") ? null : vm.selectedTabCData[i].trans_details_key),
												"trans_status":"A"
											}];
							editRow.push(tempJsonObj);
						}
						var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

						console.log("scheduleDetails--------------", scheduleDetails);

						scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings,
								scheduleDetails).then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 2000);
										$scope.crudLoading = false;
									} else{
										vm.editFlag = false;
										loadF5471ScheduleODetails();
										$rootScope.activeScheduleTab = 0;
										AlertService.add("success", "Acquisition of Stock is successfully saved/edited", 1000);
										$scope.crudLoading = false;
									}
								});

					}
             }

//SAVE STOCK DISPOSITION////////////////////////////////////////////////////////////////////////////////////////////////////////
			vm.stock_disposition= function($root) {
				console.log("vm.selectedTabDData--------------------",vm.selectedTabDData);
				if( vm.D_date_flag || !(angular.isDefined(vm.selectedTabDData[0].VALUE.LEGAL_ENTITY_ID) ) || !(angular.isDefined(vm.selectedTabDData[11].VALUE)) || (vm.selectedTabDData[11].VALUE == null) ||((vm.selectedTabDData[11].VALUE.COUNTRY_CODE == "US") && (vm.selectedTabDData[9].VALUE == null || vm.selectedTabDData[12].VALUE == null || vm.selectedTabDData[14].VALUE == null || vm.selectedTabDData[15].VALUE == null || vm.selectedTabDData[16].VALUE == null)))
				{
					if(!(angular.isDefined(vm.selectedTabDData[0].VALUE.LEGAL_ENTITY_ID)))
						vm.sharHolderFlag = false;
					else
						vm.sharHolderFlag = true;
					$scope.submitted= true;
					AlertService.add("error", "Please fill out required fields.");
					$scope.crudLoading = false;
				}
				else if( vm.D_date_flag || !(angular.isDefined(vm.selectedTabDData[0].VALUE.LEGAL_ENTITY_ID) ) || !(angular.isDefined(vm.selectedTabDData[11].VALUE)) || (vm.selectedTabDData[11].VALUE == null) || ((vm.selectedTabDData[11].VALUE.COUNTRY_CODE != "US") && (vm.selectedTabDData[9].VALUE == null || vm.selectedTabDData[12].VALUE == null )))
				{
					if(!(angular.isDefined(vm.selectedTabDData[0].VALUE.LEGAL_ENTITY_ID)))
						vm.sharHolderFlag = false;
					else
						vm.sharHolderFlag = true;
					$scope.submitted= true;
					AlertService.add("error", "Please fill out required fields.");
					$scope.crudLoading = false;
				}
				else
				{
					var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
											 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
					var editRow = [];
					for(var i = 0; i<vm.selectedTabDData.length; i++)
					{
						var value = null;
						if(vm.selectedTabDData[i].attrib_name == 'NAME_OF_SHAREHOLDER_DISPOSING')
							value = vm.selectedTabDData[i].VALUE.LEGAL_ENTITY_ID;
						else if(vm.selectedTabDData[i].attrib_name == 'DATE_OF_DISPOSITION')
							value = (angular.isDefined(vm.selectedTabDData[i].VALUE != null) ? $filter('date')(vm.selectedTabDData[i].VALUE, "MM/dd/yyyy")  : null);
						else if(vm.selectedTabDData[i].attrib_name == 'COUNTRY')
							value = vm.selectedTabDData[i].VALUE.COUNTRY_CODE;
						else if(vm.selectedTabDData[i].attrib_name == 'STATE' ||  vm.selectedTabDData[i].attrib_name == '5471_O_STOCK_DIS_F_STATE')
						{
							if( vm.selectedTabDData[i].VALUE != null && angular.isDefined(vm.selectedTabDData[i].VALUE.code))
								value = vm.selectedTabDData[i].VALUE.code;
							else if(angular.isDefined(vm.selectedTabDData[i].VALUE) && (vm.selectedTabDData[i].VALUE != null && vm.selectedTabDData[i].VALUE.toString().length > 0))
								value = vm.selectedTabDData[i].VALUE;
							else
								value = null;
						}
						else
						{
							if(angular.isDefined(vm.selectedTabDData[i].VALUE) && (vm.selectedTabDData[i].VALUE != null && vm.selectedTabDData[i].VALUE.toString().length > 0))
								value = vm.selectedTabDData[i].VALUE;
							else
								value = null;
						}

						var tempJsonObj = [{
											"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											"trans_type_key":vm.selectedTabDData[i].trans_type_key,
											"combination_key":vm.rowData.HO_COMBINATION_KEY,
											"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
											"form_key":vm.selectedTabDData[i].form_key,
											"line_no":vm.selectedTabDData[i].line_no,
											"occurence":((vm.selectedTabDData[i].occurrence == "0") ? null : vm.selectedTabDData[i].occurrence),
											"line_attrib_key" : vm.selectedTabDData[i].line_attrib_key,
											"Attribute_Name":vm.selectedTabDData[i].attrib_name,
											"Attribute_Value":value,
											"trans_dtls_key" : ((vm.selectedTabDData[i].trans_details_key == "0") ? null : vm.selectedTabDData[i].trans_details_key),
											"trans_status":"A"
										}];
						editRow.push(tempJsonObj);
					}
					var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

					console.log("scheduleDetails--------------", scheduleDetails);

					scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings,
							scheduleDetails).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 2000);
									$scope.crudLoading = false;
								} else{
									vm.editFlag = false;
									loadF5471ScheduleODetails();
									$rootScope.activeScheduleTab = 0;
									AlertService.add("success", "Disposition of Stock is successfully saved/edited", 1000);
									$scope.crudLoading = false;
								}
							});

				}
			}
//SAVE REORGANIZATION////////////////////////////////////////////////////////////////////////////////////////////////////////
			vm.reorganization_info = function($root)
			{
				/*if( !(angular.isDefined(vm.selectedTabEData.PERSON_BNSS_NAME_LINE_1)) || !(angular.isDefined(vm.selectedTabEData.COUNTRY)) || !(angular.isDefined(vm.selectedTabEData.ADDR_LINE_1)) || !(angular.isDefined(vm.selectedTabEData.CITY)) || !(angular.isDefined(vm.selectedTabEData.STATE)) || !(angular.isDefined(vm.selectedTabEData.ZIPCODE)) )
				{
					AlertService.add("error", "Please fill out required(*) fields.");
				}*/
				if( vm.E_date_flag || !(angular.isDefined(vm.selectedTabEData[4].VALUE)) || (vm.selectedTabEData[4].VALUE == null) ||((vm.selectedTabEData[4].VALUE.COUNTRY_CODE == "US") && (vm.selectedTabEData[2].VALUE == null || vm.selectedTabEData[5].VALUE == null || vm.selectedTabEData[7].VALUE == null || vm.selectedTabEData[8].VALUE == null || vm.selectedTabEData[9].VALUE == null)))
				{
					$scope.submitted= true;
					AlertService.add("error", "Please fill out required fields.");
					$scope.crudLoading = false;
				}
				else if( vm.E_date_flag || !(angular.isDefined(vm.selectedTabEData[4].VALUE)) || (vm.selectedTabEData[4].VALUE == null) || ((vm.selectedTabEData[4].VALUE.COUNTRY_CODE != "US") && (vm.selectedTabEData[2].VALUE == null || vm.selectedTabEData[5].VALUE == null )))
				{
					$scope.submitted= true;
					AlertService.add("error", "Please fill out required fields.");
					$scope.crudLoading = false;
				}
				else
				{

					var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
											 "jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
					var editRow = [];
					for(var i = 0; i<vm.selectedTabEData.length; i++)
					{
						var value = null;
						if(vm.selectedTabEData[i].attrib_name == 'DATE_OF_TRANSFER')
							value = (angular.isDefined(vm.selectedTabEData[i].VALUE != null) ? $filter('date')(vm.selectedTabEData[i].VALUE, "MM/dd/yyyy")  : null);
						else if(vm.selectedTabEData[i].attrib_name == 'COUNTRY')
							value = vm.selectedTabEData[i].VALUE.COUNTRY_CODE;
						else if(vm.selectedTabEData[i].attrib_name == 'STATE' )
						{
							if( vm.selectedTabEData[i].VALUE != null && angular.isDefined(vm.selectedTabEData[i].VALUE.code))
								value = vm.selectedTabEData[i].VALUE.code;
							else if(angular.isDefined(vm.selectedTabEData[i].VALUE) && (vm.selectedTabEData[i].VALUE != null && vm.selectedTabEData[i].VALUE.toString().length > 0))
								value = vm.selectedTabEData[i].VALUE;
							else
								value = null;
						}
						else if(vm.selectedTabEData[i].attrib_name == 'IDENTIFYING_NUMBER'||vm.selectedTabEData[i].attrib_name == '5471_O_ORG_REORG_MISSING_EIN_REASON' )
						{
							if( (vm.selectedTabEData[i].VALUE != null || vm.selectedTabEData[i].DISVALUE != null) )
							{
								if(vm.selectedTabEData[i].DISVALUE != null && vm.selectedTabEData[i].DISVALUE.toString().length > 0 )
								{
									if(vm.selectedTabEData[i].DISVALUE && vm.selectedTabEData[i].DISVALUE.length > 2)
										value = vm.selectedTabEData[i].DISVALUE.substr(0, 2) + '-' + vm.selectedTabEData[i].DISVALUE.substr(2, vm.selectedTabEData[i].DISVALUE.length);
									else
										value = vm.selectedTabEData[i].DISVALUE
								}
								else if(vm.selectedTabEData[i].VALUE != null && vm.selectedTabEData[i].VALUE.toString().length > 0   )
									value = vm.selectedTabEData[i].VALUE;
								else
									value = null;
							}
							else
								value = null;
						}

						else
						{
							if(angular.isDefined(vm.selectedTabEData[i].VALUE) && (vm.selectedTabEData[i].VALUE != null && vm.selectedTabEData[i].VALUE.toString().length > 0))
								value = vm.selectedTabEData[i].VALUE;
							else
								value = null;
						}
                        
						var tempJsonObj = [{
											"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											"trans_type_key":vm.selectedTabEData[i].trans_type_key,
											"combination_key":vm.rowData.HO_COMBINATION_KEY,
											"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
											"form_key":vm.selectedTabEData[i].form_key,
											"line_no":vm.selectedTabEData[i].line_no,
											"occurence":((vm.selectedTabEData[i].occurrence == "0") ? null : vm.selectedTabEData[i].occurrence),
											"line_attrib_key" : vm.selectedTabEData[i].line_attrib_key,
											"Attribute_Name":vm.selectedTabEData[i].attrib_name,
											"Attribute_Value":value,
											"trans_dtls_key" : ((vm.selectedTabEData[i].trans_details_key == "0") ? null : vm.selectedTabEData[i].trans_details_key),
											"trans_status":"A"
										}];
						editRow.push(tempJsonObj);
					}
					var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};

					console.log("scheduleDetails--------------", scheduleDetails);

					scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings, scheduleDetails).then( function(result) {
						if (result.errorMessage
								&& result.errorMessage !== 'null') {
							AlertService.add("error",
									result.errorMessage, 2000);
							$scope.crudLoading = false;
						} else{
							vm.editFlag = false;
							loadF5471ScheduleODetails();
							$rootScope.activeScheduleTab = 0;
							AlertService.add("success", "Reorganization Information is successfully saved/edited", 1000);
							$scope.crudLoading = false;
						}
					});
				}
			}

//EDIT FUNCTIONALITY////////////////////////////////////////////////////////////////////////////////////////////////////////
            vm.editSchedule = function(item, section) {
            	vm.editFlag = true;
            	vm.loading = true;
              	var data = {};
        		console.log("items before-----", item);
            	switch(section) {
                    case "C":
                    	$rootScope.activeScheduleTab = 1;
                    	vm.selectedTabCData = [];
                    	vm.tempOldscheduleData = [];
                    	for(var i = 0; i<item.length ; i++)
                    	{
                        	var value = null;
                        	if(item[i].attrib_name =='PERSON_BUSINESS_VALUE')
                        	{
                        		if(!(angular.isDefined(item[i].VALUE)) || item[i].VALUE == null )
                        			value = "B";
                        		else
                        			value = item[i].VALUE;
                        	}
                        	else if(item[i].attrib_name == "DATE_OF_ACQUISITION" )////setting date in the correct format to show on the UI
                        	{
                        		if( item[i].VALUE != null)
                        		{
                        			var dateSet = new Date(item[i].VALUE);
                        			value = new Date(dateSet.getFullYear(), dateSet.getMonth(), dateSet.getDate());
                        		}
                        	}
                        	else if(item[i].attrib_name == "NAME_OF_SHAREHOLDER_FILING")// if shareholder entity id is not defined, to get default entity info
                        	{
                        		if(!(angular.isDefined(item[i].VALUE)) || item[i].VALUE == null)
                        			value = {};
                        		else
                        			value = item[i].VALUE;
                        	}
                        	else
                        		value = item[i].VALUE;

                        	vm.selectedTabCData[i] = {
									"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
							};

                        	vm.tempOldscheduleData[i] = {
                        			"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
                        	};
                    	}
                    break;

                    case "D":
                    	$rootScope.activeScheduleTab = 2;
                    	vm.selectedTabDData = [];
                    	vm.tempOldscheduleData = [];
                    	for(var i = 0; i<item.length ; i++)
                    	{
                        	var value = null;
                        	if(item[i].attrib_name =='PERSON_BUSINESS_VALUE')
                        	{
                        		if(!(angular.isDefined(item[i].VALUE)) || item[i].VALUE == null )
                        			value = "B";
                        		else
                        			value = item[i].VALUE;
                        	}
                        	else if(item[i].attrib_name == "DATE_OF_DISPOSITION" )////setting date in the correct format to show on the UI
                        	{
                        		if( item[i].VALUE != null)
                        		{
                        			var dateSet = new Date(item[i].VALUE);
                        			value = new Date(dateSet.getFullYear(), dateSet.getMonth(), dateSet.getDate());
                        		}
                        	}
                        	else if(item[i].attrib_name == "NAME_OF_SHAREHOLDER_DISPOSING")// if shareholder entity id is not defined, to get default entity info
                        	{
                        		if(!(angular.isDefined(item[i].VALUE)) || item[i].VALUE == null)
                        			value = {};
                        		else
                        			value = item[i].VALUE;
                        	}
                        	else
                        		value = item[i].VALUE;

                        	vm.selectedTabDData[i] = {
									"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
							};

                        	vm.tempOldscheduleData[i] = {
                        			"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
                        	};
                    	}

                    break;

                    case "E":
                    	$rootScope.activeScheduleTab = 3;
                    	vm.selectedTabEData = [];
                    	vm.tempOldscheduleData = [];
                    	for(var i = 0; i<item.length ; i++)
                    	{
                        	var value = null;
                        	var disValue = null;

                        	if(item[i].attrib_name =='PERSON_BUSINESS_VALUE')
                        	{
                        		if(!(angular.isDefined(item[i].VALUE)) || item[i].VALUE == null )
                        			value = "B";
                        		else
                        			value = item[i].VALUE;
                        	}
                        	else if(item[i].attrib_name == "DATE_OF_TRANSFER" )////setting date in the correct format to show on the UI
                        	{
                        		if( item[i].VALUE != null)
                        		{
                        			var dateSet = new Date(item[i].VALUE);
                        			value = new Date(dateSet.getFullYear(), dateSet.getMonth(), dateSet.getDate());
                        		}
                        	}
                        	else if(item[i].attrib_name == "IDENTIFYING_NUMBER" )
                        	{
                        		if(item[i].VALUE != null)
                        	    {
	                        		if( item[i].VALUE == 'APPLD FOR' || item[i].VALUE == 'FOREIGNUS' )
	                        		{
	                        			disValue = null;
	                        			value = item[i].VALUE;
	                        		}
	                        		else
	                        		{
	                        			value = item[i].VALUE;
	                        			disValue = item[i].VALUE.replace('-', '');
	                        		}
                        	    }

                        	}

                        	else
                        		value = item[i].VALUE;

                        	vm.selectedTabEData[i] = {
									"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"DISVALUE" : disValue,
									"trans_type_key" : item[i].trans_type_key
							};

                        	vm.tempOldscheduleData[i] = {
                        			"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"DISVALUE" : disValue,
									"trans_type_key" : item[i].trans_type_key
                        	};
                    	}

                    break;
                    
                    case "B":
                    	$rootScope.activeScheduleTab = 4;
                    	vm.selectedTabBData = [];
                    	vm.tempOldscheduleData = [];
                    	for(var i = 0; i<item.length ; i++)
                    	{
                        	var value = null;
                        	
                        	if(item[i].attrib_name == "5471_O_US_PER_OFF_DIR_OFFICER" || item[i].attrib_name == "5471_O_US_PER_OFF_DIR_DIRECTOR")
		                		{
		                						if( item[i].VALUE != null)
		                						{
		                							if(item[i].VALUE == 'Y')
		                							{
		                								value = '1';
		                							}
		                							else
		                							{
		                								value = '0';
		                							}
		                						}
		                						
		                					}
		                	else				
							value = item[i].VALUE;

                        	vm.selectedTabBData[i] = {
									"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
							};

                        	vm.tempOldscheduleData[i] = {
                        			"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
                        	};
                    	}
                    break;
                    
                    case "A":
                    	$rootScope.activeScheduleTab = 5;
                    	vm.selectedTabAData = [];
                    	vm.tempOldscheduleData = [];
                    	for(var i = 0; i<item.length ; i++)
                    	{
                        	var value = null;
                        	 if(item[i].attrib_name == "5471_O_GEN_SH_LAST_FILED" )////setting date in the correct format to show on the UI
                        	{
                        		if( item[i].VALUE != null)
                        		{
                        			var dateSet = new Date(item[i].VALUE);
                        			value = new Date(dateSet.getFullYear(), dateSet.getMonth(), dateSet.getDate());
                        		}
                        	}
                        	vm.selectedTabAData[i] = {
									"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
							};

                        	vm.tempOldscheduleData[i] = {
                        			"line_no" : item[i].line_no,
									"line_attrib_key" : item[i].line_attrib_key,
									"attrib_name" : item[i].attrib_name,
									"attrib_description" : item[i].attrib_description,
									"trans_details_key" : item[i].trans_details_key,
									"occurrence" : item[i].occurrence,
									"form_key":item[i].form_key,
									"VALUE" : value,
									"trans_type_key" : item[i].trans_type_key
                        	};
                    	}
                    break;
                }
            	return;
           }

//DELETE //////////////////////////////////////////////////////////////////////////////////
           vm.deleteSchedule = function(item)
           {
        	   //console.log("in delete function & items:-------------------", item);
               var stateTemp = null;
			   if(angular.isDefined(item[15]) && angular.isDefined(item[15].VALUE) && item.VALUE != null)
			   {
					if(angular.isDefined(item[15]) &&angular.isDefined(item[15].VALUE.code))
						stateTemp = item[15].VALUE.code;
					else
						stateTemp = item[15].VALUE;
			   }
			   var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
			   							"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
			   							"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
			   var removeRow = [];
			   for(var i = 0; i< item.length; i++)
			   {
				   var value = null;
				   if(item[i].attrib_name == 'NAME_OF_SHAREHOLDER_FILING' || item[i].attrib_name == 'NAME_OF_SHAREHOLDER_DISPOSING' )
					   value = item[i].VALUE.LEGAL_ENTITY_ID;
				   else if(item[i].attrib_name == 'DATE_OF_ACQUISITION' || item[i].attrib_name == '5471_O_GEN_SH_LAST_FILED')
					   value = (angular.isDefined(item[i].VALUE != null) ? $filter('date')(item[i].VALUE, "MM/dd/yyyy")  : null);
				   else if(item[i].attrib_name == 'COUNTRY' || item[i].attrib_name == '5471_O_US_PER_OFF_DIR_F_COUNTRY')
					   value = item[i].VALUE.COUNTRY_CODE;
				   else if(item[i].attrib_name == 'STATE' || item[i].attrib_name == '5471_O_US_PER_OFF_DIR_US_STATE')
				   {
					   if( item[i].VALUE != null && angular.isDefined(item[i].VALUE.code))
						   value = item[i].VALUE.code;
					   else if(angular.isDefined(item[i].VALUE) && (item[i].VALUE != null && item[i].VALUE.toString().length > 0))
						   value = item[i].VALUE;
					   else
						   value = null;
				   }
				   else
				   {
					   if(angular.isDefined(item[i].VALUE) && (item[i].VALUE != null && item[i].VALUE.toString().length > 0))
						   value = item[i].VALUE;
					   else
						   value = null;
				   }
				   var tempJsonObj = [{
										"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
										"trans_type_key":item[i].trans_type_key,
										"combination_key":vm.rowData.HO_COMBINATION_KEY,
										"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
										"form_key":item[i].form_key,
										"line_no":item[i].line_no,
										"occurence":((item[i].occurrence == "0") ? null : item[i].occurrence),
										"line_attrib_key" : item[i].line_attrib_key,
										"Attribute_Name":item[i].attrib_name,
										"Attribute_Value":value,
										"trans_dtls_key" : ((item[i].trans_details_key == "0") ? null : item[i].trans_details_key),
										"trans_status":"A"
									}];
				   removeRow.push(tempJsonObj);
			   }
			   var scheduleDetails = {"addrows":[], "editrows":[], "removerows":removeRow};

			   console.log("scheduleDetails--------------", scheduleDetails);

			   scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings, scheduleDetails).then( function(result) {
					if (result.errorMessage
							&& result.errorMessage !== 'null') {
						AlertService.add("error",
								result.errorMessage, 4000);
					} else{
						//vm.editFlag = false;
						loadF5471ScheduleODetails();
						AlertService.add("success", "Data is successfully Deleted", 4000);

					}
				});
           	}

//////////////////////////////////////////////////////////////////////////////////////////////////////

           vm.getLegalEntity = function(legalId) {
            	//console.log("filinggroup----", vm.filingGroup);
            	var finalObject = {};
            	for(var i = 0; i< vm.filingGroup.length; i++) {
            		if(vm.filingGroup[i].LEGAL_ENTITY_ID === legalId) {
            			finalObject = vm.filingGroup[i];
            			break;
            		}

            	}
            	return finalObject;
            }

//////////////////////////////////////////////////////////////////////////////////////////////////////

                vm.getPersonBusinessInd = function(trans_id)
                {
                	var temp_ind = "";
                	for(var i = 0; i<vm.scheduleODetails.length; i++ )
                		{
                			if(vm.scheduleODetails[i].TRANS_ID == trans_id)
                			{
                				temp_ind = vm.scheduleODetails[i].PERSON_BNSS_IND;
                				break;
                			}
                		}
                	return temp_ind;
                }

//////////////////////////////////////////////////////////////////////////////////////////////////////
						///setting value to 0 for null values of amounts
				vm.checkValue = function(index, section)
				{
					//console.log("value---vm.selectedTabCData[index].VALUE ", vm.selectedTabCData[index].VALUE );
					if(section == 'C')
					{
						if(vm.selectedTabCData[index].VALUE == null || !vm.selectedTabCData[index].VALUE)
							vm.selectedTabCData[index].VALUE = 0;
					}
					else if(section == 'D')
					{
						if(vm.selectedTabDData[index].VALUE == null || !vm.selectedTabDData[index].VALUE)
							vm.selectedTabDData[index].VALUE = 0;
					}
					else if(section == 'E')
					{
						if(vm.selectedTabEData[index].VALUE == null || !vm.selectedTabEData[index].VALUE)
							vm.selectedTabEData[index].VALUE = 0;
					}
				}
//////////////////////////////////////////////////////////////////////////////////////////////////////
				vm.tabENameChange = function()
				{
					//console.log("checkbox:---------------",vm.selectedTabEData[0].VALUE);
					console.log("vm.selectedTabEData-----", vm.selectedTabEData);
					if(vm.selectedTabEData[0].VALUE == true)
					{
						var country = null;
						alert('Please enter the "formerly known as" name in Name Line 1 and 2 and if address is missing, enter LE address to complete a name change transaction.');
						for(var i =0; i<vm.selectedTabEData.length; i++)
						{
							console.log("vm.corpNameChangeData.NAME_CHANGE_EIN.toString()--------", vm.corpNameChangeData.NAME_CHANGE_EIN.toString());
							switch(vm.selectedTabEData[i].attrib_name)
							{
								case "CORPORATION_NAME_CHANGE" :
									vm.selectedTabEData[i].VALUE = true;
									break;
								case "PERSON_BUSINESS_VALUE" :
									vm.selectedTabEData[i].VALUE = 'B';
									break;
								case "COUNTRY" :
									country = vm.getCountryInfo(vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE);
									vm.selectedTabEData[i].VALUE = vm.getCountryInfo(vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE);
									break;
								case "ADDRESS_LINE_1" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'ADDRESS_LINE_1'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ADDR_1'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 : null);
									}
										
									break;
								case "5471_O_ORG_REORG_F_ADDR_1" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'ADDRESS_LINE_1'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ADDR_1'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_1 : null);
									}
										
									break;
								case "ADDRESS_LINE_2" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'ADDRESS_LINE_2'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ADDR_2'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 : null);
									}
									
									break;
								case "5471_O_ORG_REORG_F_ADDR_2" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'ADDRESS_LINE_2'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ADDR_2'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 != 'null' ? vm.corpNameChangeData.NAME_CHANGE_ADDRESS_LINE_2 : null);
									}
									
									break;
								case "CITY" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_CITY !='null' ? vm.corpNameChangeData.NAME_CHANGE_CITY : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_CITY !='null' ? vm.corpNameChangeData.NAME_CHANGE_CITY : null);
									}
									
									break;
								case "5471_O_ORG_REORG_F_CITY" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_CITY !='null' ? vm.corpNameChangeData.NAME_CHANGE_CITY : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_CITY !='null' ? vm.corpNameChangeData.NAME_CHANGE_CITY : null);
									}
									
									break;
								case "STATE" :
									if(vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'STATE'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										
										if(vm.corpNameChangeData.NAME_CHANGE_STATE != null )
											vm.selectedTabEData[i].VALUE = vm.getStateInfo(vm.corpNameChangeData.NAME_CHANGE_STATE);
										else
											vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_STATE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_STATE : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_STATE'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										
										if(vm.corpNameChangeData.NAME_CHANGE_STATE != null )
											vm.selectedTabEData[i].VALUE = vm.getStateInfo(vm.corpNameChangeData.NAME_CHANGE_STATE);
										else
											vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_STATE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_STATE : null);
									}
									break;
								case "5471_O_ORG_REORG_F_STATE" :
									if(vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'STATE'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										
										if(vm.corpNameChangeData.NAME_CHANGE_STATE != null && vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE == 'US' )
											vm.selectedTabEData[i].VALUE = vm.getStateInfo(vm.corpNameChangeData.NAME_CHANGE_STATE);
										else
											vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_STATE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_STATE : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_STATE'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										
										if(vm.corpNameChangeData.NAME_CHANGE_STATE != null && vm.corpNameChangeData.NAME_CHANGE_COUNTRY_CODE == 'US')
											vm.selectedTabEData[i].VALUE = vm.getStateInfo(vm.corpNameChangeData.NAME_CHANGE_STATE);
										else
											vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_STATE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_STATE : null);
									}
									break;
								case "ZIP_POSTAL_CODE" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ZIP'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE : null);
									}
									break;
								case "5471_O_ORG_REORG_F_ZIP" :
									if(country == 'US')
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'CITY'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE : null);
									}
									else
									{
										var temp_obj = _.find(vm.tabEAttribDetails, {attrib_name:'5471_O_ORG_REORG_F_ZIP'});
										vm.selectedTabEData[i].attrib_name = temp_obj.attrib_name;
										vm.selectedTabEData[i].attrib_description = temp_obj.attrib_description;
										vm.selectedTabEData[i].line_attrib_key = temp_obj.line_attrib_key;
										vm.selectedTabEData[i].VALUE = (vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE != 'null' ? vm.corpNameChangeData.NAME_CHANGE_POSTAL_CODE : null);
									}
									break;
								case "IDENTIFYING_NUMBER" :
									vm.selectedTabEData[i].VALUE = ( vm.corpNameChangeData.NAME_CHANGE_EIN != 'null' ? vm.corpNameChangeData.NAME_CHANGE_EIN : null);
									vm.selectedTabEData[i].DISVALUE = ( vm.corpNameChangeData.NAME_CHANGE_EIN != 'null' ? vm.corpNameChangeData.NAME_CHANGE_EIN : null);
									break;
								case "DESCRIPTION_OF_ASSETS" :
									if(vm.selectedTabEData[i].attrib_description== 'Description of assets')
										vm.selectedTabEData[i].VALUE = "Corporation Name Change";
									else
										vm.selectedTabEData[i].VALUE = null;
									break;
								case "DESCRIPTION_OF_ASSETS_TRANSFERRED" :
									vm.selectedTabEData[i].VALUE = null;
									break;
								case "FAIR_MARKET_VALUE" :
									vm.selectedTabEData[i].VALUE = 0;
									break;
								case "ADJUST_BASIS" :
									vm.selectedTabEData[i].VALUE = 0;
									break;
								default :
									vm.selectedTabEData[i].VALUE = null;
									break;

							}
						}
						//console.log("vm.selectedTabEData------------", vm.selectedTabEData);
					}
					else
					{
						if (vm.editFlag)
						{
							vm.tabE_flag = false;
	                        vm.E_date_flag = false;
	                        var tempObj = [];
	                		for(var i = 0; i<vm.selectedTabEData.length; i++)
	                		{
	                			tempObj[i] = {
	                					"line_no" : vm.selectedTabEData[i].line_no,
										"line_attrib_key" : vm.selectedTabEData[i].line_attrib_key,
										"attrib_name" : vm.selectedTabEData[i].attrib_name,
										"attrib_description" : vm.selectedTabEData[i].attrib_description,
										"form_key" : vm.selectedTabEData[i].form_key,
										"trans_details_key" : vm.selectedTabEData[i].trans_details_key,
										"occurrence" : vm.selectedTabEData[i].occurrence,
										"VALUE" : null,
										"DISVALUE" : null,
										"trans_type_key" : vm.selectedTabEData[i].trans_type_key
	                			}
	                		}
	                		console.log("tempObj--------", tempObj);
	                		vm.selectedTabEData = [];
	                		for(var i =  0; i<tempObj.length; i++)
							{
								var value = null;
								if(tempObj[i].attrib_name == 'FAIR_MARKET_VALUE' || tempObj[i].attrib_name == 'ADJUST_BASIS')
									value = 0;
								else if(tempObj[i].attrib_name  == 'PERSON_BUSINESS_VALUE')
									value = "B";

								vm.selectedTabEData[i] = {
										"line_no" : tempObj[i].line_no,
										"line_attrib_key" : tempObj[i].line_attrib_key,
										"attrib_name" : tempObj[i].attrib_name,
										"attrib_description" : tempObj[i].attrib_description,
										"trans_details_key" : tempObj[i].trans_details_key,
										"form_key":tempObj[i].form_key,
										"occurrence" : tempObj[i].occurrence,
										"VALUE" : value,
										"trans_type_key" : tempObj[i].trans_type_key
								};

								//vm.selectedTabCData[i]["]
							}

						}
						else
							vm.clearTabData(3);
					}

				}

//////////////////////////////////////////////////////////////////////////////////////////////////////

				vm.formatDate = function(selectedtabIndex)
				{
					console.log("vm.rowData----------", vm.rowData);
					var start_date = new Date(vm.rowData.TAX_YEAR_BEGIN);
		        	var end_date = new Date(vm.rowData.TAX_YEAR_END);

		        	if(selectedtabIndex == 1)
		        	{
			        	if((vm.selectedTabCData[2].VALUE != null ) && ((vm.selectedTabCData[2].VALUE < start_date) || (vm.selectedTabCData[2].VALUE > end_date )) )
			        		vm.C_date_flag = true;
			        	else
			        		vm.C_date_flag = false;
			        		//vm.formattedDate = $filter('date')(vm.selectedTabEData[2].VALUE, 'MM/dd/yyyy');
			               // vm.TRANS_DATE = vm.formattedDate;
		        	}
		        	else if(selectedtabIndex == 2)
		        	{
			        	if((vm.selectedTabDData[2].VALUE != null ) && ( (vm.selectedTabDData[2].VALUE < start_date) || (vm.selectedTabDData[2].VALUE > end_date )) )
			        		vm.D_date_flag = true;
			        	else
			        		vm.D_date_flag = false;
			        		//vm.formattedDate = $filter('date')(vm.selectedTabEData[2].VALUE, 'MM/dd/yyyy');
			               // vm.TRANS_DATE = vm.formattedDate;
		        	}
		        	else if(selectedtabIndex == 3)
		        	{
			        	if((vm.selectedTabEData[11].VALUE != null ) && ( (vm.selectedTabEData[11].VALUE < start_date) || (vm.selectedTabEData[11].VALUE > end_date ) ) )
			        		vm.E_date_flag = true;
			        	else
			        		vm.E_date_flag = false;
			        		//vm.formattedDate = $filter('date')(vm.selectedTabEData[2].VALUE, 'MM/dd/yyyy');
			               // vm.TRANS_DATE = vm.formattedDate;
		        	}
		        	else if(selectedtabIndex == 5)
		        	{
			        	if((vm.selectedTabEData[0].VALUE != null ) && ( (vm.selectedTabEData[0].VALUE < start_date) || (vm.selectedTabEData[0].VALUE > end_date ) ) )
			        		vm.A_date_flag = true;
			        	else
			        		vm.A_date_flag = false;
			        		//vm.formattedDate = $filter('date')(vm.selectedTabEData[2].VALUE, 'MM/dd/yyyy');
			               // vm.TRANS_DATE = vm.formattedDate;
		        	}
				}

//////////////////////////////////////////////////////////////////////////////////////////////////////

				vm.getCountryInfo = function(countryCode)
                {
                	var tempCountry = {};
                	for(var i = 0; i<vm.countries.length; i++)
                	{
                		if(vm.countries[i].COUNTRY_CODE ==  countryCode)
                		{
                			tempCountry = vm.countries[i];
                			break;
                		}
                	}
                	return tempCountry;
                }

//////////////////////////////////////////////////////////////////////////////////////////////////////

                vm.getStateInfo = function(stateCode)
                {
                	var tempState = {};
                	for(var i = 0; i<vm.states.length; i++)
                	{
                		if(vm.states[i].code ==  stateCode)
                		{
                			tempState = vm.states[i];
                			break;
                		}
                	}
                	return tempState;
                }

                vm.radioClicked = function(tab, index1,index2)
                {
                	if(tab == 'E')
                	{
                		if(angular.isDefined(vm.selectedTabEData[index1].VALUE) && angular.isDefined(vm.selectedTabEData[index1].DISVALUE ))
                		{
                			
                				vm.selectedTabEData[index1].DISVALUE = null;
                				vm.selectedTabEData[index1].VALUE = null;
                				
                			
                		}
                		if(angular.isDefined(vm.selectedTabEData[index2].DISVALUE))
                				{
                			        vm.selectedTabEData[index2].DISVALUE = null;
                				}

                	}
                }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
                vm.uncheckedRadio = function(tab, index1, index2)
                {
                	console.log("inchange---------")
                	if(tab == 'E')
                	{
                		if(angular.isDefined(vm.selectedTabEData[index2].VALUE) && angular.isDefined(vm.selectedTabEData[index2].DISVALUE))
                		{
                			vm.selectedTabEData[index2].DISVALUE = null;
            				vm.selectedTabEData[index2].VALUE = null;
                		}
                		if(angular.isDefined(vm.selectedTabEData[index1].DISVALUE) && angular.isDefined(vm.selectedTabEData[index1].VALUE))
            			{
            				vm.selectedTabEData[index1].VALUE = vm.selectedTabEData[index1].DISVALUE;
            			}
                	}
                }

//////////////////////////////////////////////////////////////////////////////////////////////////////

                vm.changeCountry = function(country_code, tab)
                {
                	console.log("tab b attributes:-----", vm.selectedTabBData);
                	console.log("tab c attributes:-----", vm.selectedTabCData);
            		console.log("tab d attributes:-----", vm.selectedTabDData);
            		console.log("tab e attributes:-----", vm.selectedTabEData);
            		switch(tab)
            		{
                		case "C" :
                			vm.selectedTabCData[15].VALUE = null;
                			vm.selectedTabCData[16].VALUE = null;
                			
                			vm.selectedTabCData[12].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_STOCK_ACQ_F_ADDR_1';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabCAttribDetails, {
                					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_STOCK_ACQ_F_ADDR_1') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabCData[12].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabCData[12].attrib_description = temp_obj.attrib_description;
                				}
                				
                				///////
                				vm.selectedTabCData[13].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_STOCK_ACQ_F_ADDR_2';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabCAttribDetails, {
                					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_STOCK_ACQ_F_ADDR_2') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabCData[13].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabCData[13].attrib_description = temp_obj.attrib_description;
                				}
                				
                				///////
                				vm.selectedTabCData[14].attrib_name = country_code == 'US' ? 'CITY' : '5471_O_STOCK_ACQ_F_CITY';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabCAttribDetails, {
                					attrib_name: (country_code == 'US' ? 'CITY' : '5471_O_STOCK_ACQ_F_CITY') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabCData[14].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabCData[14].attrib_description = temp_obj.attrib_description;
                				}
                				
                				/////
                				vm.selectedTabCData[15].attrib_name = country_code == 'US' ? 'STATE' : '5471_O_STOCK_ACQ_F_STATE';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabCAttribDetails, {
                					attrib_name: (country_code == 'US' ? 'STATE' : '5471_O_STOCK_ACQ_F_STATE') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabCData[15].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabCData[15].attrib_description = temp_obj.attrib_description;
                				}
                				
                				/////
                				vm.selectedTabCData[16].attrib_name = country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_STOCK_ACQ_F_ZIP';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabCAttribDetails, {
                					attrib_name: (country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_STOCK_ACQ_F_ZIP')
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabCData[16].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabCData[16].attrib_description = temp_obj.attrib_description;
                				}
                				
                			
                			break;
                		case "D" :
                			vm.selectedTabDData[15].VALUE = null;
                			vm.selectedTabDData[16].VALUE = null;
                			
                			vm.selectedTabDData[12].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_STOCK_DIS_F_ADDR_1';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabDAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_STOCK_DIS_F_ADDR_1') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabDData[12].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabDData[12].attrib_description = temp_obj.attrib_description;
            				}
            				
            				///////
            				vm.selectedTabDData[13].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_STOCK_DIS_F_ADDR_2';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabDAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_STOCK_DIS_F_ADDR_2') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabDData[13].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabDData[13].attrib_description = temp_obj.attrib_description;
            				}
            				
            				///////
            				vm.selectedTabDData[14].attrib_name = country_code == 'US' ? 'CITY' : '5471_O_STOCK_DIS_F_CITY';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabDAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'CITY' : '5471_O_STOCK_DIS_F_CITY') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabDData[14].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabDData[14].attrib_description = temp_obj.attrib_description;
            				}
            				
            				/////
            				vm.selectedTabDData[15].attrib_name = country_code == 'US' ? 'STATE' : '5471_O_STOCK_DIS_F_STATE';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabDAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'STATE' : '5471_O_STOCK_DIS_F_STATE') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabDData[15].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabDData[15].attrib_description = temp_obj.attrib_description;
            				}
            				
            				/////
            				vm.selectedTabDData[16].attrib_name = country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_STOCK_DIS_F_ZIP';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabDAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_STOCK_DIS_F_ZIP')
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabDData[16].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabDData[16].attrib_description = temp_obj.attrib_description;
            				}
                			
                			break;
                		case "E":
                			vm.selectedTabEData[8].VALUE = null;
                			vm.selectedTabEData[9].VALUE = null;
                			
                			vm.selectedTabEData[5].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_ORG_REORG_F_ADDR_1';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabEAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_1' : '5471_O_ORG_REORG_F_ADDR_1') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabEData[5].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabEData[5].attrib_description = temp_obj.attrib_description;
            				}
            				
            				///////
            				vm.selectedTabEData[6].attrib_name = country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_ORG_REORG_F_ADDR_2';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabEAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ADDRESS_LINE_2' : '5471_O_ORG_REORG_F_ADDR_2') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabEData[6].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabEData[6].attrib_description = temp_obj.attrib_description;
            				}
            				
            				///////
            				vm.selectedTabEData[7].attrib_name = country_code == 'US' ? 'CITY' : '5471_O_ORG_REORG_F_CITY';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabEAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'CITY' : '5471_O_ORG_REORG_F_CITY') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabEData[7].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabEData[7].attrib_description = temp_obj.attrib_description;
            				}
            				
            				/////
            				vm.selectedTabEData[8].attrib_name = country_code == 'US' ? 'STATE' : '5471_O_ORG_REORG_F_STATE';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabEAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'STATE' : '5471_O_ORG_REORG_F_STATE') 
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabEData[8].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabEData[8].attrib_description = temp_obj.attrib_description;
            				}
            				
            				/////
            				vm.selectedTabEData[9].attrib_name = country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_ORG_REORG_F_ZIP';
            				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
							//{
            				var temp_obj = _.find(vm.tabEAttribDetails, {
            					attrib_name: (country_code == 'US' ? 'ZIP_POSTAL_CODE' : '5471_O_ORG_REORG_F_ZIP')
            				});
            				if(temp_obj !== undefined)
            				{
            					vm.selectedTabEData[9].line_attrib_key = temp_obj.line_attrib_key;
            					vm.selectedTabEData[9].attrib_description = temp_obj.attrib_description;
            				}
                			break;
                			
                			case "B" :
                			vm.selectedTabBData[6].VALUE = null;
                			vm.selectedTabBData[7].VALUE = null;                			
                			vm.selectedTabBData[3].attrib_name = country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ADDR_1' : '5471_O_US_PER_OFF_DIR_F_ADDR_1';
                				//for(var i =  0; i<vm.tabDAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabBAttribDetails, {
                					attrib_name: (country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ADDR_1' : '5471_O_US_PER_OFF_DIR_F_ADDR_1') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabBData[3].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabBData[3].attrib_description = temp_obj.attrib_description;
                				}
                				
                				///////
                				vm.selectedTabBData[4].attrib_name = country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ADDR_2' : '5471_O_US_PER_OFF_DIR_F_ADDR_2';
                				//for(var i =  0; i<vm.tabBAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabBAttribDetails, {
                					attrib_name: (country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ADDR_2' : '5471_O_US_PER_OFF_DIR_F_ADDR_2') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabBData[4].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabBData[4].attrib_description = temp_obj.attrib_description;
                				}
                				
                				///////
                				vm.selectedTabBData[5].attrib_name = country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_CITY' : '5471_O_US_PER_OFF_DIR_F_CITY';
                				//for(var i =  0; i<vm.tabBAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabBAttribDetails, {
                					attrib_name: (country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_CITY' : '5471_O_US_PER_OFF_DIR_F_CITY') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabBData[5].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabBData[5].attrib_description = temp_obj.attrib_description;
                				}
                				
                				/////
                				vm.selectedTabBData[6].attrib_name = country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_STATE' : '5471_O_US_PER_OFF_DIR_F_STATE';
                				//for(var i =  0; i<vm.tabBAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabBAttribDetails, {
                					attrib_name: (country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_STATE' : '5471_O_US_PER_OFF_DIR_F_STATE') 
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabBData[6].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabBData[6].attrib_description = temp_obj.attrib_description;
                				}
                				
                				/////
                				vm.selectedTabBData[7].attrib_name = country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ZIP' : '5471_O_US_PER_OFF_DIR_F_ZIP';
                				//for(var i =  0; i<vm.tabBAttribDetails.length; i++)
    							//{
                				var temp_obj = _.find(vm.tabBAttribDetails, {
                					attrib_name: (country_code == 'US' ? '5471_O_US_PER_OFF_DIR_US_ZIP' : '5471_O_US_PER_OFF_DIR_F_ZIP')
                				});
                				if(temp_obj !== undefined)
                				{
                					vm.selectedTabBData[7].line_attrib_key = temp_obj.line_attrib_key;
                					vm.selectedTabBData[7].attrib_description = temp_obj.attrib_description;
                				}
                				
                			
                			break;
            		}
            		console.log("tab b attributes:-----", vm.selectedTabBData);
            		console.log("tab c attributes:-----", vm.selectedTabCData);
            		console.log("tab d attributes:-----", vm.selectedTabDData);
            		console.log("tab e attributes:-----", vm.selectedTabEData);
                }

//FUNCTION TO CLEAR TAB DATA //////////////////////////////////////////////////////////////////////////////////////////////////////
                vm.clearTabData = function(selectedTabIndex)
                {
                	vm.editFlag = false;
                	if(selectedTabIndex == 0)
                	{
                		vm.collapsed1 = true;
                        vm.collapsed2 = true;
                        vm.collapsed3 = true;
                        vm.collapsed4 = true;
                        vm.collapsed5 = true;
                        
                	}
                	if(selectedTabIndex == 4)
                	{
                		vm.tabB_flag = false;
                		vm.selectedTabBData = [];
                		var tabb_index = 0;
						for(var i =  0; i<vm.tabBAttribDetails.length; i++)
						{
							
							if(vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ADDR_1'  && 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ADDR_2'&&
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_CITY'&& 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_STATE' && 
								   vm.tabBAttribDetails[i].attrib_name !== '5471_O_US_PER_OFF_DIR_US_ZIP')
								   {
								var value = null;
								vm.selectedTabBData[tabb_index] = {
											"line_no" : vm.tabBAttribDetails[i].line_no,
											"line_attrib_key" : vm.tabBAttribDetails[i].line_attrib_key,
											"attrib_name" : vm.tabBAttribDetails[i].attrib_name,
											"attrib_description" : vm.tabBAttribDetails[i].attrib_description,
											"form_key" : vm.tabBAttribDetails[i].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : value,
											"trans_type_key" : vm.tabBAttribDetails[i].trans_type_key
									};
									tabb_index++;
						}

							//vm.selectedTabBData[i]["]
						}
				}
				    if(selectedTabIndex == 5)
                	{
                		vm.selectedTabAData = [];
                		var taba_index = 0;
						for(var i =  0; i<vm.tabAAttribDetails.length; i++)
						{
								var value = null;
								vm.selectedTabAData[taba_index] = {
											"line_no" : vm.tabAAttribDetails[i].line_no,
											"line_attrib_key" : vm.tabAAttribDetails[i].line_attrib_key,
											"attrib_name" : vm.tabAAttribDetails[i].attrib_name,
											"attrib_description" : vm.tabAAttribDetails[i].attrib_description,
											"form_key" : vm.tabAAttribDetails[i].form_key,
											"trans_details_key" : null,
											"occurrence" : null,
											"VALUE" : value,
											"trans_type_key" : vm.tabAAttribDetails[i].trans_type_key
									};
									taba_index++;
						}
					}
                	if(selectedTabIndex == 1)
                	{
                		vm.tabC_flag = false;
                		vm.C_date_flag = false;
                		vm.selectedTabCData = [];
                		var tabc_index = 0;
						for(var i =  0; i<vm.tabCAttribDetails.length; i++)
						{
							
							if(vm.tabCAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1'  && vm.tabCAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' && vm.tabCAttribDetails[i].attrib_name !== 'CITY'&& vm.tabCAttribDetails[i].attrib_name !== 'STATE' && vm.tabCAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' && vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_SH_NAME_1' && vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_SH_NAME_2' && vm.tabCAttribDetails[i].attrib_name !== '5471_O_STOCK_ACQ_PERSON_NAME' )
							{
								var value = null;
							
								if(vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_DIRECTLY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_CONSTRUCTIVELY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'NUMBER_OF_INDIRECTLY_SHARES_ACQUIRED' || vm.tabCAttribDetails[i].attrib_name == 'AMT_PAID_OR_VALUE_GIVEN')
									value = 0;
								else if(vm.tabCAttribDetails[i].attrib_name  == 'PERSON_BUSINESS_VALUE')
									value = "B";
								else if(vm.tabCAttribDetails[i].attrib_name == 'NAME_OF_SHAREHOLDER_FILING')
									value = {};
								
								vm.selectedTabCData[tabc_index] = {
										"line_no" : vm.tabCAttribDetails[i].line_no,
										"line_attrib_key" : vm.tabCAttribDetails[i].line_attrib_key,
										"attrib_name" : vm.tabCAttribDetails[i].attrib_name,
										"attrib_description" : vm.tabCAttribDetails[i].attrib_description,
										"form_key" : vm.tabCAttribDetails[i].form_key,
										"trans_details_key" : null,
										"occurrence" : null,
										"VALUE" : value,
										"trans_type_key" : vm.tabCAttribDetails[i].trans_type_key
								};
								tabc_index++;
						}

							//vm.selectedTabCData[i]["]
						}
                		
                	}
                	if(selectedTabIndex == 2)
                	{
                		vm.tabD_flag = false;
                        vm.D_date_flag = false;
                		vm.selectedTabDData = [];
                		var tabd_index = 0;
						for(var i =  0; i<vm.tabDAttribDetails.length; i++)
						{
							if( vm.tabDAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1' && vm.tabDAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' &&  vm.tabDAttribDetails[i].attrib_name !== 'CITY'&& vm.tabDAttribDetails[i].attrib_name !== 'STATE' && vm.tabDAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_SH_NAME_1' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_SH_NAME_2' && vm.tabDAttribDetails[i].attrib_name !== '5471_O_STOCK_DIS_PERSON_NAME')
							{
							var value = null;
							if(vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_DIRECTLY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_INDIRECTLY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'NUMBER_OF_CONSTRUCTIVELY_SHARES_DISPOSED' || vm.tabDAttribDetails[i].attrib_name == 'AMT_RECEIVED')
								value = 0;
							else if(vm.tabDAttribDetails[i].attrib_name  == 'PERSON_BUSINESS_VALUE')
								value = "B";
							else if(vm.tabDAttribDetails[i].attrib_name == 'NAME_OF_SHAREHOLDER_DISPOSING')
								value = {};

							vm.selectedTabDData[tabd_index] = {
									"line_no" : vm.tabDAttribDetails[i].line_no,
									"line_attrib_key" : vm.tabDAttribDetails[i].line_attrib_key,
									"attrib_name" : vm.tabDAttribDetails[i].attrib_name,
									"attrib_description" : vm.tabDAttribDetails[i].attrib_description,
									"form_key" : vm.tabDAttribDetails[i].form_key,
									"trans_details_key" : null,
									"occurrence" : null,
									"VALUE" : value,
									"trans_type_key" : vm.tabDAttribDetails[i].trans_type_key
							};
							tabd_index++;
							}

							//vm.selectedTabCData[i]["]
						}
                		console.log("vm.selectedTabDData after tab change---------------", vm.selectedTabDData);

                	}

                	if(selectedTabIndex == 3)
                	{
                		vm.tabE_flag = false;
                        vm.E_date_flag = false;
                		vm.selectedTabEData = [];
                		var tabe_index = 0;
						for(var i =  0; i<vm.tabEAttribDetails.length; i++)
						{
							
							if(vm.tabEAttribDetails[i].attrib_name !== 'ADDRESS_LINE_1' && vm.tabEAttribDetails[i].attrib_name !== 'ADDRESS_LINE_2' &&  vm.tabEAttribDetails[i].attrib_name !== 'CITY'&& vm.tabEAttribDetails[i].attrib_name !== 'STATE' && vm.tabEAttribDetails[i].attrib_name !== 'ZIP_POSTAL_CODE' && vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_DATE' && /*vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_MISSING_EIN_REASON' && */vm.tabEAttribDetails[i].attrib_name !== '5471_O_ORG_REORG_SSN')
							{
							var value = null;
							if(vm.tabEAttribDetails[i].attrib_name == 'FAIR_MARKET_VALUE' || vm.tabEAttribDetails[i].attrib_name == 'ADJUST_BASIS')
								value = 0;
							else if(vm.tabEAttribDetails[i].attrib_name == 'PERSON_BUSINESS_VALUE')
								value = 'B';
							else if(vm.tabEAttribDetails[i].attrib_name == 'CORPORATION_NAME_CHANGE')
								value = false;

							vm.selectedTabEData[tabe_index] = {
									"line_no" : vm.tabEAttribDetails[i].line_no,
									"line_attrib_key" : vm.tabEAttribDetails[i].line_attrib_key,
									"attrib_name" : vm.tabEAttribDetails[i].attrib_name,
									"attrib_description" : vm.tabEAttribDetails[i].attrib_description,
									"form_key" : vm.tabEAttribDetails[i].form_key,
									"trans_details_key" : null,
									"occurrence" : null,
									"VALUE" : value,
									"DISVALUE":value,
									"trans_type_key" : vm.tabEAttribDetails[i].trans_type_key
							};
							tabe_index++;
							}

							//vm.selectedTabCData[i]["]
						}
						/*//changing order for TAB E data
						var temp_array=['5471_O_ORG_REORG_F_ADDR_1', '5471_O_ORG_REORG_F_ADDR_2', '5471_O_ORG_REORG_F_CITY', '5471_O_ORG_REORG_F_STATE', '5471_O_ORG_REORG_F_ZIP'];
						var next = 5;
						for(var k = 0 ; k < temp_array.length; k++)
						{
							//var temp_val = 
							var temp_obj = _.find(vm.selectedTabEData,{attrib_name : temp_array[k]} );
							var temp_obj1 = {
									"line_no" :temp_obj.line_no,
									"line_attrib_key" : temp_obj.line_attrib_key,
									"attrib_name" : temp_obj.attrib_name,
									"attrib_description" : temp_obj.attrib_description,
									"form_key" : temp_obj.form_key,
									"trans_details_key" : null,
									"occurrence" : null,
									"VALUE" : temp_obj.VALUE,
									"DISVALUE":temp_obj.VALUE,
									"trans_type_key" : temp_obj.trans_type_key
							}
							
							if(temp_obj !== undefined )
							{
								var temp_index =  _.findIndex(vm.selectedTabEData,{attrib_name : temp_array[k]} );
								vm.selectedTabEData.splice(temp_index, 1);
								
								var temp_index2 = vm.selectedTabEData.length;
								vm.selectedTabEData[temp_index2] = {
										"line_no" : vm.selectedTabEData[next].line_no,
										"line_attrib_key" : vm.selectedTabEData[next].line_attrib_key,
										"attrib_name" : vm.selectedTabEData[next].attrib_name,
										"attrib_description" : vm.selectedTabEData[next].attrib_description,
										"form_key" : vm.selectedTabEData[next].form_key,
										"trans_details_key" : null,
										"occurrence" : null,
										"VALUE" : vm.selectedTabEData[next].VALUE,
										"DISVALUE":vm.selectedTabEData[next].VALUE,
										"trans_type_key" : vm.selectedTabEData[next].trans_type_key
								}
								vm.selectedTabEData[next].line_attrib_key = temp_obj1.line_attrib_key;
								vm.selectedTabEData[next].attrib_name = temp_obj1.attrib_name;
								vm.selectedTabEData[next].attrib_description = temp_obj1.attrib_description;
								vm.selectedTabEData[next].VALUE = null;
								
								
							}
							next++;
						}
						var temp_index2 = vm.selectedTabEData.length;
						vm.selectedTabEData[temp_index2] = {
								"line_no" : vm.selectedTabEData[next].line_no,
								"line_attrib_key" : vm.selectedTabEData[next].line_attrib_key,
								"attrib_name" : vm.selectedTabEData[next].attrib_name,
								"attrib_description" : vm.selectedTabEData[next].attrib_description,
								"form_key" : vm.selectedTabEData[next].form_key,
								"trans_details_key" : null,
								"occurrence" : null,
								"VALUE" : vm.selectedTabEData[next].VALUE,
								"DISVALUE":vm.selectedTabEData[next].VALUE,
								"trans_type_key" : vm.selectedTabEData[next].trans_type_key
						};

						vm.selectedTabEData.splice(next, 1);*/
                		console.log("vm.selectedTabEData after tab change---------------", vm.selectedTabEData);
                	}

                }
                
                vm.changeName = function(tab)
                {
                	if(tab == 'C')
                	{
                		var temp_index = _.findIndex(vm.selectedTabCData, {attrib_name :'PERSON_BUSINESS_VALUE'});
                		
                		if(vm.selectedTabCData[temp_index].VALUE == 'P')
                	    {
                			var temp_index1 = _.findIndex(vm.tabCAttribDetails, {attrib_name :'5471_O_STOCK_ACQ_PERSON_NAME'});
                			var temp_index2 = _.findIndex(vm.selectedTabCData, {attrib_name :'NAME_LINE_1'});
                			if(temp_index2 !== -1)
                			{
                				vm.selectedTabCData[temp_index2].line_attrib_key = vm.tabCAttribDetails[temp_index1].line_attrib_key;
                				vm.selectedTabCData[temp_index2].attrib_name = vm.tabCAttribDetails[temp_index1].attrib_name;
                				vm.selectedTabCData[temp_index2].attrib_description = vm.tabCAttribDetails[temp_index1].attrib_description;
                				vm.selectedTabCData[temp_index2].VALUE = null;
                			}
                	    }
                		else if(vm.selectedTabCData[temp_index].VALUE == 'B')
                		{
                			var temp_index1 = _.findIndex(vm.tabCAttribDetails, {attrib_name :'NAME_LINE_1'});
                			var temp_index2 = _.findIndex(vm.selectedTabCData, {attrib_name :'5471_O_STOCK_ACQ_PERSON_NAME'});
                			if(temp_index2 !== -1)
                			{
                				vm.selectedTabCData[temp_index2].line_attrib_key = vm.tabCAttribDetails[temp_index1].line_attrib_key;
                				vm.selectedTabCData[temp_index2].attrib_name = vm.tabCAttribDetails[temp_index1].attrib_name;
                				vm.selectedTabCData[temp_index2].attrib_description = vm.tabCAttribDetails[temp_index1].attrib_description;
                				vm.selectedTabCData[temp_index2].VALUE = null;
                			}
                		}
                	}
                	else if(tab == 'D')
                	{
                		var temp_index = _.findIndex(vm.selectedTabDData, {attrib_name :'PERSON_BUSINESS_VALUE'});
                		
                		if(vm.selectedTabDData[temp_index].VALUE == 'P')
                	    {
                			var temp_index1 = _.findIndex(vm.tabDAttribDetails, {attrib_name :'5471_O_STOCK_DIS_PERSON_NAME'});
                			var temp_index2 = _.findIndex(vm.selectedTabDData, {attrib_name :'NAME_LINE_1'});
                			if(temp_index2 !== -1)
                			{
                				vm.selectedTabDData[temp_index2].line_attrib_key = vm.tabDAttribDetails[temp_index1].line_attrib_key;
                				vm.selectedTabDData[temp_index2].attrib_name = vm.tabDAttribDetails[temp_index1].attrib_name;
                				vm.selectedTabDData[temp_index2].attrib_description = vm.tabDAttribDetails[temp_index1].attrib_description;
                				vm.selectedTabDData[temp_index2].VALUE = null;
                				
                			}
                	    }
                		else if(vm.selectedTabDData[temp_index].VALUE == 'B')
                		{
                			var temp_index1 = _.findIndex(vm.tabDAttribDetails, {attrib_name :'NAME_LINE_1'});
                			var temp_index2 = _.findIndex(vm.selectedTabDData, {attrib_name :'5471_O_STOCK_DIS_PERSON_NAME'});
                			if(temp_index2 !== -1)
                			{
                				vm.selectedTabDData[temp_index2].line_attrib_key = vm.tabDAttribDetails[temp_index1].line_attrib_key;
                				vm.selectedTabDData[temp_index2].attrib_name = vm.tabDAttribDetails[temp_index1].attrib_name;
                				vm.selectedTabDData[temp_index2].attrib_description = vm.tabDAttribDetails[temp_index1].attrib_description;
                				vm.selectedTabDData[temp_index2].VALUE = null;
                				
                				
                			}
                		}
                	}
                	
                }

//TO RESET VALUE //////////////////////////////////////////////////////////////////////////////////////////////////////
            	vm.reset = function(selectedTabIndex, root)
            	{
            		$scope.crudLoading = false;

            		console.log("in reset");
            		if(selectedTabIndex == 0)
            		{
            			for(var i = 0 ; i < vm.tab5471OriginalData.length ; i++)
            			{
            				vm.tab5471Data[i] = {
								i : vm.tab5471OriginalData[i],
            					"trans_type_key":vm.tab5471OriginalData[i].trans_type_key,
            					"attrib_name":vm.tab5471OriginalData[i].attrib_name,
            					"line_attrib_key":vm.tab5471OriginalData[i].line_attrib_key,
            					"trans_details_key":vm.tab5471OriginalData[i].trans_details_key,
            					"occurrence":vm.tab5471OriginalData[i].occurrence,
            					"attrib_description" : vm.tab5471OriginalData[i].attrib_description,
            					"line_no": vm.tab5471OriginalData[i].line_no,
            					"form_key":vm.tab5471OriginalData[i].form_key,
            					"VALUE":vm.tab5471OriginalData[i].VALUE,
            					"updated_by" : vm.tab5471OriginalData[i].updated_by,
            					"updated_on" : vm.tab5471OriginalData[i].updated_on
            				 };
            			}
            		}
            		else
            		{
	            		if(vm.editFlag)
	            		{
	            			console.log("ineditFlag----");
	            			if(selectedTabIndex  == 1)
	            			{
	            				console.log("in section c------");
	            				console.log("vm.tempOldscheduleData-------------", vm.tempOldscheduleData);
	                			vm.selectedTabCData = [];
	                			for(var i = 0; i < vm.tempOldscheduleData.length; i++)
	                			{
	                				vm.selectedTabCData[i] = {
											"line_no" : vm.tempOldscheduleData[i].line_no,
											"line_attrib_key" : vm.tempOldscheduleData[i].line_attrib_key,
											"attrib_name" : vm.tempOldscheduleData[i].attrib_name,
											"attrib_description" : vm.tempOldscheduleData[i].attrib_description,
											"form_key" : vm.tempOldscheduleData[i].form_key,
											"trans_details_key" : vm.tempOldscheduleData[i].trans_details_key,
											"occurrence" : vm.tempOldscheduleData[i].occurrence,
											"VALUE" : vm.tempOldscheduleData[i].VALUE,
											"trans_type_key" : vm.tempOldscheduleData[i].trans_type_key
									};
	                			}

		                        vm.formatDate(selectedTabIndex);
	            			}
	            			//vm.editFlag = false;
	            			else if(selectedTabIndex == 2)
	            			{
	            				console.log("in section d------");
	            				console.log("vm.tempOldscheduleData-------------", vm.tempOldscheduleData);
	                			vm.selectedTabDData = [];
	                			for(var i = 0; i < vm.tempOldscheduleData.length; i++)
	                			{
	                				vm.selectedTabDData[i] = {
											"line_no" : vm.tempOldscheduleData[i].line_no,
											"line_attrib_key" : vm.tempOldscheduleData[i].line_attrib_key,
											"attrib_name" : vm.tempOldscheduleData[i].attrib_name,
											"attrib_description" : vm.tempOldscheduleData[i].attrib_description,
											"form_key" : vm.tempOldscheduleData[i].form_key,
											"trans_details_key" :vm.tempOldscheduleData[i].trans_details_key ,
											"occurrence" : vm.tempOldscheduleData[i].occurrence,
											"VALUE" : vm.tempOldscheduleData[i].VALUE,
											"trans_type_key" : vm.tempOldscheduleData[i].trans_type_key
									};
	                			}
	                			vm.formatDate(selectedTabIndex);
	            			}
	            			else if(selectedTabIndex == 3)
	            			{
	            				console.log("in section e------");
	            				console.log("vm.tempOldscheduleData-------------", vm.tempOldscheduleData);
	                			vm.selectedTabEData = [];
	                			for(var i = 0; i < vm.tempOldscheduleData.length; i++)
	                			{
	                				vm.selectedTabEData[i] = {
											"line_no" : vm.tempOldscheduleData[i].line_no,
											"line_attrib_key" : vm.tempOldscheduleData[i].line_attrib_key,
											"attrib_name" : vm.tempOldscheduleData[i].attrib_name,
											"attrib_description" : vm.tempOldscheduleData[i].attrib_description,
											"form_key" : vm.tempOldscheduleData[i].form_key,
											"trans_details_key" : vm.tempOldscheduleData[i].trans_details_key,
											"occurrence" : vm.tempOldscheduleData[i].occurrence,
											"VALUE" : vm.tempOldscheduleData[i].VALUE,
											"DISVALUE" : vm.tempOldscheduleData[i].DISVALUE,
											"trans_type_key" : vm.tempOldscheduleData[i].trans_type_key
									};
	                			}
	                			vm.formatDate(selectedTabIndex);
	            			}
	            			
	            			else if(selectedTabIndex == 4)
	            			{
	            				console.log("in section B------");
	            				console.log("vm.tempOldscheduleData-------------", vm.tempOldscheduleData);
	                			vm.selectedTabBData = [];
	                			for(var i = 0; i < vm.tempOldscheduleData.length; i++)
	                			{
	                				vm.selectedTabBData[i] = {
											"line_no" : vm.tempOldscheduleData[i].line_no,
											"line_attrib_key" : vm.tempOldscheduleData[i].line_attrib_key,
											"attrib_name" : vm.tempOldscheduleData[i].attrib_name,
											"attrib_description" : vm.tempOldscheduleData[i].attrib_description,
											"form_key" : vm.tempOldscheduleData[i].form_key,
											"trans_details_key" : vm.tempOldscheduleData[i].trans_details_key,
											"occurrence" : vm.tempOldscheduleData[i].occurrence,
											"VALUE" : vm.tempOldscheduleData[i].VALUE,
											"trans_type_key" : vm.tempOldscheduleData[i].trans_type_key
									};
	                			}
	            			}
	            			
	            			else if(selectedTabIndex == 5)
	            			{
	            				console.log("in section A------");
	            				console.log("vm.tempOldscheduleData-------------", vm.tempOldscheduleData);
	                			vm.selectedTabAData = [];
	                			for(var i = 0; i < vm.tempOldscheduleData.length; i++)
	                			{
	                				vm.selectedTabAData[i] = {
											"line_no" : vm.tempOldscheduleData[i].line_no,
											"line_attrib_key" : vm.tempOldscheduleData[i].line_attrib_key,
											"attrib_name" : vm.tempOldscheduleData[i].attrib_name,
											"attrib_description" : vm.tempOldscheduleData[i].attrib_description,
											"form_key" : vm.tempOldscheduleData[i].form_key,
											"trans_details_key" : vm.tempOldscheduleData[i].trans_details_key,
											"occurrence" : vm.tempOldscheduleData[i].occurrence,
											"VALUE" : vm.tempOldscheduleData[i].VALUE,
											"trans_type_key" : vm.tempOldscheduleData[i].trans_type_key
									};
	                			}
	            			}
	            			vm.formatDate(selectedTabIndex);
	            		}
	            		else
	            		{
	            			vm.clearTabData(selectedTabIndex);
	            		}
            		}

            	}
            }
            return controllers;
});

