define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.PushToEfileServices', [])
        .factory("PushToEfileFactory", ['$http', 'GENERAL_CONFIG', 'USER_SETTINGS', 'GlobalService', function ($http, GENERAL_CONFIG, USER_SETTINGS, GlobalService) {
            const requestUrlBuilder = {
                url: '',
                isFirstParam: true,
                set: function (url) {
                    this.url = url;
                    this.isFirstParam = true;
                    return this;
                },
                get: function () {
                    return this.url;
                },
                appendEndpoint: function (endpoint) {
                    this.url = this.url + endpoint;
                    return this;
                },
                appendParam: function (paramName, paramVal) {
                    if (!this.url || this.url.length === 0) throw Error('Bad Url');
                    if (paramName !== undefined && paramVal !== undefined) {
                        this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                        this.isFirstParam = false;
                    }
                    return this;
                }
            };
            const pushToEfileFactory = {};

            pushToEfileFactory.getDstEfilePushTaxYears = function () {
                return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32494&sso_id=${USER_SETTINGS.user.sso_id}`);
            }

            pushToEfileFactory.getDstEfilePushDcns = function (tax_year_id) {
                return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32495&sso_id=${USER_SETTINGS.user.sso_id}&tax_year_id=${tax_year_id}`);
            }

            pushToEfileFactory.getDstEfilePushTaxPayers = function (tax_year_id, dcn_id) {
                return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32496&sso_id=${USER_SETTINGS.user.sso_id}&tax_year_id=${tax_year_id}&dcn_id=${dcn_id}`);
            }

            pushToEfileFactory.pushDstDataToEfile = function (tax_year, dcn_id, submissionId) {
                return $http.post(`${GENERAL_CONFIG.admin_api_url}/dstDataToEfile?taxYear=${tax_year}&dcnId=${dcn_id}&submissionId=${submissionId}`);
            }

            pushToEfileFactory.getPDFPushData = function (tax_year, dcn_id, submissionId) {
                return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32538&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${tax_year}&dcn_id=${dcn_id}&submission_id=${submissionId}`);
            }

            pushToEfileFactory.uploadPdfToEfile = function (taxYear, dcnId, submissionId, formId, fileName) {
                return $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                    .appendEndpoint('/dstPDFDataToEfile')
                    .appendParam('taxYear', taxYear)
                    .appendParam('dcnId', dcnId)
                    .appendParam('submissionId', submissionId)
                    .appendParam('formId', formId)
                    .appendParam('fileName', fileName)
                    .get());
            }
            
            pushToEfileFactory.deleteForm = function (submissionId, fileName, formSub, taxYear) {
                
                var params = {
                    'submissionId': submissionId,
                    'file_name': fileName,
                    'formSub': formSub,
        			'tax_year':	taxYear,
        			'scenario': GlobalService.globalParams.scenario,
        			'jcd_key': GlobalService.globalParams.jcd_key,
                };
    
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32554",
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
                // return $http.post(`${GENERAL_CONFIG.base_url}/saveJsonObject?action_id=32554&submissionId=${submissionId}&file_name=${fileName}&formSub=${formSub}`);
            }

            return pushToEfileFactory;
        }]);

    return services;
});
