define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.stateGroupDetailsController', [])
    .controller('stateGroupDetailsController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','stateGroupDetailsService',stateGroupDetailsController])
    .controller('StateEditGroupController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' ,'gridData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','stateGroupDetailsService',StateEditGroupController])
    .controller('StateAddGroupController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','stateGroupDetailsService',StateAddGroupController])
    .controller('StateDeleteGroupController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' ,'gridData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','stateGroupDetailsService','$uibModal',StateDeleteGroupController])
    .controller('StateAssignGroupController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' ,'gridData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','stateGroupDetailsService','DTOptionsBuilder','$uibModal',StateAssignGroupController]);
    function stateGroupDetailsController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,stateGroupDetailsService){
        $rootScope.$on('StateGroup:CreateNewGroup', function(event, args) {
        	console.log("New Group creation call!!");
        });
    }
    function StateEditGroupController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData,gridData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,stateGroupDetailsService){
        console.log({gridData,rowData,colData});
        var vm = this;
        vm.modalTitle = "Edit Group Details";
        vm.isLoading = false;
        vm.allSelectedGroups =  [];
        if(rowData && Object.keys(rowData).length > 0 ){
            vm.allSelectedGroups.push(rowData);
        }else if(gridData.length>0){
            gridData.map((row) => {
                vm.allSelectedGroups.push(row.data);
            });
        }else{
            AlertService.add('info', 'No group is selected for editing');
        }
        

        vm.allSelectedGroups_backup = JSON.parse(JSON.stringify(vm.allSelectedGroups));

        var tempModelObject = {
            "CONSOL_TYPE":"SINGLE",
            "CREATED_BY":"",
            "CREATED_ON":"",
            "FILER_TAX_YEAR_BEGIN":"",
            "FILER_TAX_YEAR_END":"",
            "GROUP_FLAG":"State",
            "GROUP_NAME":"",
            "GROUP_OBJ_KEY":"",
            "GROUP_TYPE":"REG",
            "LOCK_Y_N":"N",
            "MEF_GROUP_KEY":"",
            "PARENT_KEY":"",
            "SCENARIO":"",
            "STATE":"",
            "TAX_YEAR":"",
            "UPDATED_BY":"",
            "UPDATED_ON":"",
        };
        vm.newGroups =  [];
        vm.addGroup = function(){
            vm.newGroups.push(Object.assign({},tempModelObject,{"CREATED_BY":USER_SETTINGS.user.sso_id,"UPDATED_BY":USER_SETTINGS.user.sso_id}));
        }

        vm.onEditSave = function() {
            vm.isLoading = true;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31724";            
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonSettings = {
            	"tax_year": vm.allSelectedGroups[0].TAX_YEAR,
            	"scenario": filterParams.scenario,
            	"jcd_key": vm.allSelectedGroups[0].JCD_KEY,
            	"combination_key": vm.allSelectedGroups[0].COMBINATION_KEY
            };
            let data = vm.allSelectedGroups.concat(vm.newGroups);
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({data:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params =  _.extend({sso_id:USER_SETTINGS.user.sso_id}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
					AlertService.add("success", "Groups Updated successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in updating the groups. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
                console.log('This is the error', error);
            })
        }
        vm.removeGroup = function(index){
            if(vm.newGroups.length > 0){
                vm.newGroups.splice(index,1);
            }
        }
        
        vm.onEditReset = function(){
            console.log('onEditReset');
            vm.allSelectedGroups = vm.allSelectedGroups_backup; 
        }

        vm.cancel = function(){
            vm.onEditReset();
            $uibModalInstance.dismiss('cancel');
        }

    }
    function StateAddGroupController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,stateGroupDetailsService){

        var vm = this;
        vm.modalTitle = "Add Group Details";
        vm.isLoading = false;
        var tempModelObject = {
            "CONSOL_TYPE":"SINGLE",
            "CREATED_BY":"",
            "CREATED_ON":"",
            "FILER_TAX_YEAR_BEGIN":"",
            "FILER_TAX_YEAR_END":"",
            "GROUP_FLAG":"STATE",
            "GROUP_NAME":"",
            "GROUP_OBJ_KEY":"",
            "GROUP_TYPE":"REG",
            "LOCK_Y_N":"N",
            "MEF_GROUP_KEY":"",
            "PARENT_KEY":"",
            "SCENARIO":"",
            "STATE":"",
            "TAX_YEAR":"",
            "UPDATED_BY":"",
            "UPDATED_ON":"",
        };
        

        vm.newGroups =  [];
        vm.addGroup = function(){
            vm.newGroups.push(Object.assign({},tempModelObject,{"CREATED_BY":USER_SETTINGS.user.sso_id,"UPDATED_BY":USER_SETTINGS.user.sso_id}));
        }
        
        vm.removeGroup = function(index){
            if(vm.newGroups.length > 0){
                vm.newGroups.splice(index,1);
            }
        }

        vm.validation = function(){

            if(vm.newGroups.length < 1){
                return false;
            }

            for (let i=0; i < vm.newGroups.length; i++){
                if(vm.newGroups[i].GROUP_NAME.trim() == "")
                    return false;
            }
            

            return true;
        }

        vm.onSave = function() {
            
            if(!vm.validation()){
                AlertService.add('warning', 'Group name can not be empty.');
                return ;                
            }
            
            vm.isLoading = true;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31723";            
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(vm.newGroups);
            var jsonSettings = {
            	"tax_year": filterParams.tax_year,
            	"scenario": filterParams.scenario,
            	"jcd_key": workspaceFactory.activeScreen.jcd_key,
            	"combination_key": ''
            };
            var params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params =  _.extend({data:jsonObj}, params);
            params =  _.extend({sso_id:USER_SETTINGS.user.sso_id}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
					AlertService.add("success", "Groups created successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in creating the groups. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
                console.log('This is the error', error);
            })
            
        }
        
        vm.onReset = function(){
            console.log('onEditReset');
            vm.allSelectedGroups = vm.allSelectedGroups_backup; 
        }

        vm.cancel = function(){
            vm.onReset();
            $uibModalInstance.dismiss('cancel');
        }

    }
    function StateDeleteGroupController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData,gridData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,stateGroupDetailsService,$uibModal){

        var vm = this;

        vm.modalTitle = "Delete Group Details";
        vm.isLoading = false;
        vm.allSelectedGroups =  [];
        gridData.map((row) => {
            vm.allSelectedGroups.push(row.data);
        });

        vm.getConfirmation = function(index){
            // $uibModal.open({
            //     templateUrl: 'app/templates/modals/confirm-action.html',
            //     windowClass: 'modal-warning',
            //     controller: ['$scope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG',
            //         function ($scope, $stateParams, $uibModalInstance, GENERAL_CONFIG) {
                        
            //             $scope.header = "Confirm";
            //             $scope.message = "Do you want to continue deleting group?";
            //             $scope.confirm = function () {
            //                 vm.canDeleteEntity = true;
            //                 $uibModalInstance.close();
            //                 vm.removeGroup(index);

            //             }
            //             $scope.cancel = function () {
            //                 vm.canDeleteEntity = true;
            //                 $uibModalInstance.close();
            //             }

            //             $scope.$on('$destroy', function () {
            //                 ////////////console.log("ngReally Destroyed");
            //             });
            //         }
            //     ]
            // });

            vm.canDeleteEntity = true;
            vm.removeGroup(index);
        }

        vm.removeGroup = function(index) {
            if(vm.allSelectedGroups.length > 0){
                vm.onDelete(index) ;
            }
        };

        vm.onDelete = function(index) {
            vm.isLoading = true;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31825";            
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = "";//JSON.stringify(vm.allSelectedGroups[index].MEF_GROUP_KEY);
            vm.allSelectedGroups.forEach(group => {
                jsonObj += group.MEF_GROUP_KEY+',';
            });
            var jsonSettings = JSON.stringify({
            	"tax_year": filterParams.tax_year,
            	"scenario": filterParams.scenario,
            	"jcd_key": workspaceFactory.activeScreen.jcd_key,
            	"combination_key": ''
            });
            var params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params =  _.extend({groupKeys:jsonObj}, params);
            params =  _.extend({sso_id:USER_SETTINGS.user.sso_id}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                    // vm.allSelectedGroups.splice(index,1);
                    vm.cancel();
					AlertService.add("success", "Groups deleted successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in deleting the groups. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
                console.log('This is the error', error);
            })
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('dataFilters:refreshGrid', {
                "refresh": true
            });
        };

    }
    function StateAssignGroupController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData,gridData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,stateGroupDetailsService, DTOptionsBuilder,$uibModal){
        
        var vm = this;
        vm.loading = false;
        vm.resending= false;
        vm.modalTitle = "Assign Entities to State Group";
        vm.entityType = 'INTERNAL';
        vm.dtInstance = null;

        const temp = {
            "CREATED_BY": "",
            "CREATED_ON": "",
            "ENTITY_TYPE": "REG",
            "EXTERNAL_Y_N": "",
            "FGN_DOM_FLAG": "",
            "FILER_STATUS": "A",
            "FORM_FILER_IND": "",
            "LE_KEY": "",
            "LE_NAME": "",
            "MEF_GROUP_KEY": "",
            "TAX_YEAR": "",
            "UPDATED_BY": "",
            "UPDATED_ON": "",
        };

        if(rowData && Object.keys(rowData).length > 0 ){
            vm.firstSelectedGroup = rowData;
        }else if(gridData.length > 1){
            AlertService.add('info', 'Only one group can be selected for assignment',4000);
            vm.firstSelectedGroup = gridData[0].data;
        }else if(gridData.length == 1){
            vm.firstSelectedGroup = gridData[0].data;
        }else{
            AlertService.add('info', 'No group selected for assignment', 4000); 
        }

        vm.firstSelectedGroup_backup = JSON.parse(JSON.stringify(vm.firstSelectedGroup));
        vm.stateGroupDisplayName = vm.firstSelectedGroup.GROUP_NAME +' - '+ vm.firstSelectedGroup.MEF_GROUP_KEY;
        vm.allExternalEntities={
            param_name: "State Entities",
            label: "State Entities",
            label_name: "label",
            value_name: "value",
            values: [],
            selected:[]
        };
        vm.allInternalEntities={
            param_name: "Internal Entities",
            label: "Internal Entities",
            label_name: "label",
            value_name: "value",
            values: [],
            selected:[]
        };

        vm.newAssignedEntities = [];
        
        vm.multiselectEvents = {
			onItemSelect: function (item) {
			},
			onItemDeselect: function (item) {
			}
		};
        
        const loadAllEntities = function(action_id){
            vm.isInternalEntityLoading = true;
            vm.isExternalEntityLoading = true;
            var url = GENERAL_CONFIG.base_url + "/loadJsonObject?action_id="+action_id;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = {
            	"tax_year": vm.firstSelectedGroup.TAX_YEAR,
            	"scenario": filterParams.scenario,
            	"jcd_key": vm.firstSelectedGroup.JCD_KEY,
            	"combination_key": vm.firstSelectedGroup.COMBINATION_KEY,
                "groupKeys":[vm.firstSelectedGroup.MEF_GROUP_KEY]
            };

            JsonObjectFactory.getJSONObj(url, params).then( (data) => {
                
                if(data.callSuccess === "1") {
                    let entities = data.jsonObject
                                        .map(entity =>{
                                            entity.VALUE = entity.LE_KEY;
                                            entity.LABEL = entity.LEID +' - '+ entity.LE_NAME +' - '+ entity.CDR_NO  +' - '+ entity.REPORTING_PERIOD;
                                            return entity;
                                        });
                    
                    if(action_id == 31762){
                        vm.isExternalEntityLoading = false;
                        vm.allExternalEntities.values = entities;
                        vm.allExternalEntities.values_backup = angular.copy(entities);
                        vm.markAllSavedEntities();
                    }else{
                        vm.isInternalEntityLoading = false;
                        vm.allInternalEntities.values = entities;
                        vm.allInternalEntities.values_backup = angular.copy(entities);
                        vm.markAllSavedEntities();
                    }
                } else {
                    if (data.errorMessage) {
                        AlertService.add("error",data.errorMessage,4000);        
                    } else {
                        AlertService.add( "error", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });            
        };

        function fetchUSStateList(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=oppj8b').then(function (data) {
				vm.allUSStates = data.jsonObject;
            });
		}

        const loadAllAssignedEntities = function(){
            var url = GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=31727";
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = {
            	"tax_year": vm.firstSelectedGroup.TAX_YEAR,
            	"scenario": filterParams.scenario,
            	"jcd_key": vm.firstSelectedGroup.JCD_KEY,
            	"combination_key": vm.firstSelectedGroup.COMBINATION_KEY,
                "groupKeys":[vm.firstSelectedGroup.MEF_GROUP_KEY]
            };
            vm.allAssignedEntities = [];
            JsonObjectFactory.getJSONObj(url, params).then( (data) => {
                if(data.callSuccess === "1") {
                    vm.allAssignedEntities = data.jsonObject;
                    vm.markAllSavedEntities();
                    vm.loadAssignDataTable();
                }
                else {
                    if (data.errorMessage) {
                        AlertService.add("error",data.errorMessage,4000);        
                    } else {
                        AlertService.add( "error", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        function isValidAddress(){
            for(let i=0; i<vm.allAssignedEntities.length;i++){
                let entity = vm.allAssignedEntities[i];
                if( entity.EXTERNAL_Y_N == 'Y') {
                    if(!!entity.ADDRESS_LINE1 == !!entity.ADDRESS_CITY &&
                    !!entity.ADDRESS_LINE1 == !!entity.ADDRESS_STATE &&
                    !!entity.ADDRESS_LINE1 == !!entity.ADDRESS_ZIP){
                            // do nothing
                    }else{
                        return false;
                    }
                }                    
            }

            return true;
        }

        vm.onSaveAddress = function(){
            vm.isSaveLoading = true;

            if(!isValidAddress()){
                AlertService.add('warning', 'One or more fields of Address is empty.');
            }

            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31867";
            var jsonObj = JSON.stringify(vm.allAssignedEntities);

            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({},  GlobalService.globalParams , params);
            params = _.extend({data:jsonObj}, params);
            params = _.extend({scenario:filterParams.scenario}, params);
            params = _.extend({tax_year:vm.firstSelectedGroup.TAX_YEAR}, params);
            params = _.extend({jcd_key:vm.firstSelectedGroup.JCD_KEY}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isSaveLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					AlertService.add("success", "Address saved successfully", 4000);
                    loadAllAssignedEntities();
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in assigning the groups. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isSaveLoading = false;
                AlertService.add("error",error);
            })

        }

        vm.markAllSavedEntities = function(){
            vm.allExternalEntities.values = angular.copy(vm.allExternalEntities.values_backup) || [];
            vm.allInternalEntities.values = angular.copy(vm.allInternalEntities.values_backup) || [];
            vm.allExternalEntities.selected = [];
            vm.allInternalEntities.selected = [];
            for(let i=0; i< vm.allAssignedEntities.length ; i++){
                let assignedLE_KEY = vm.allAssignedEntities[i].LE_KEY;

                for(let j=0; j< vm.allExternalEntities.values.length ; j++){
                    let externalLE_KEY = vm.allExternalEntities.values[j].LE_KEY;
                    vm.allExternalEntities.values[j].isChecked = false;
                    if(assignedLE_KEY == externalLE_KEY){
                        vm.allExternalEntities.values.splice(j,1);
                    }
                }

                for(let k=0; k< vm.allInternalEntities.values.length ; k++){
                    let internalLE_KEY = vm.allInternalEntities.values[k].LE_KEY;
                    vm.allInternalEntities.values[k].isChecked = false;
                    if(assignedLE_KEY == internalLE_KEY){
                        vm.allInternalEntities.values.splice(k,1);
                    }
                }
            }
        }
        
        vm.loadAssignDataTable = function(){
            vm.allAssignedEntitiesTable = {};
            vm.allAssignedEntitiesTable.dtOptions = DTOptionsBuilder.newOptions()
                        .withOption('paging', true)
                        .withOption('fixedHeader', true)
                        .withOption('scrollY','35vh')
                        .withOption('scrollX', 'auto')
                        .withOption('scrollCollapse', true)
                        .withOption('autoWidth', false);
        }

        var init =function(){
            fetchUSStateList();
            loadAllEntities(31762);//External Entities
            loadAllEntities(31726);//Internal Entities
            loadAllAssignedEntities();
        }
        init();

        vm.saveAll=function(){
            vm.onAssignEntity();
            vm.onSaveAddress();
        }

        vm.onAssignEntity = function() {
            
            if(     (vm.entityType == 'INTERNAL' && vm.allInternalEntities.selected.length == 0) 
                ||  ( vm.entityType == 'EXTERNAL' && vm.allExternalEntities.selected.length == 0) ){
                AlertService.add('warning','No Entity selected');
                return;
            }

            vm.isLoading = true;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31734";

            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonSettings = {
            	"tax_year": vm.firstSelectedGroup.TAX_YEAR,
            	"scenario": filterParams.scenario,
            	"jcd_key": vm.firstSelectedGroup.JCD_KEY,
            	"combination_key": vm.firstSelectedGroup.COMBINATION_KEY
            };
            
            var data = [];
            let selectedEntities= (vm.entityType == 'INTERNAL')?vm.allInternalEntities.selected:vm.allExternalEntities.selected;
            selectedEntities.forEach((entity)=>{
                let row = angular.copy(temp);
                
                row.CREATED_BY = USER_SETTINGS.user.sso_id;
                row.UPDATED_BY = USER_SETTINGS.user.sso_id;
                row.MEF_GROUP_KEY = vm.firstSelectedGroup.MEF_GROUP_KEY;
                row.LE_KEY = entity.LE_KEY ;
                row.LE_NAME = entity.LE_NAME;
                row.TAX_YEAR = entity.TAX_YEAR;
                row.EXTERNAL_Y_N = vm.entityType == 'EXTERNAL'?'Y':'N';
                row.TGT_MEF_CONSOL_ENTITIES = entity.LEID;
                row.REPORTING_PERIOD = entity.REPORTING_PERIOD;
                row.CDR_NO = entity.CDR_NO;
                data.push(row);
                

            });
            
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({data:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params =  _.extend({sso_id:USER_SETTINGS.user.sso_id}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					AlertService.add("success", "Entity Assigned successfully", 4000);
                    loadAllAssignedEntities();
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in assigning the groups. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
            })

        }

        vm.onUnassignEntity = function(entity) {

            vm.isLoading = true;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31739";   
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonSettings = {
            	"tax_year": vm.firstSelectedGroup.TAX_YEAR,
            	"scenario": filterParams.scenario,
            	"jcd_key": vm.firstSelectedGroup.JCD_KEY,
            	"combination_key": vm.firstSelectedGroup.COMBINATION_KEY
            };
            
            var data = entity;
            
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({data:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params =  _.extend({sso_id:USER_SETTINGS.user.sso_id}, params);
            params.scenario = filterParams.scenario;
            params.tax_year = filterParams.tax_year;

            $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					AlertService.add("success", "Entity Unassigned successfully", 4000);
                    loadAllAssignedEntities();
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in unassigning the entity. If this continues please contact support",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
            })

        }

        vm.onReset = function(){
            console.log('onReset');
            vm.firstSelectedGroup = vm.firstSelectedGroup_backup; 
            init();
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        vm.getConfirmation = function(data){
            $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'modal-warning',
                controller: ['$scope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG',
                    function ($scope, $stateParams, $uibModalInstance, GENERAL_CONFIG) {
                        
                        $scope.header = "Confirm";
                        $scope.message = "Do you want to continue unassigning entity?";
                        $scope.confirm = function () {
                            vm.canDeleteEntity = true;
                            $uibModalInstance.close();
                            vm.onUnassignEntity(data);

                        }
                        $scope.cancel = function () {
                            vm.canDeleteEntity = true;
                            $uibModalInstance.close();
                        }

                        $scope.$on('$destroy', function () {
                            ////////////console.log("ngReally Destroyed");
                        });
                    }
                ]
            });
        }

    } 
    return controllers;
});
