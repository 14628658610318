define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.firRepointService', [])
    .factory("firRepointFectory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
        var firRepointFectory = {};
        firRepointFectory.url = {};
       // firRepointFectory.url.saverepoint  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=glr5xa";//"saveJsonObject.ge?action_code=glr5xa";

       
        // reclass changes start
        
        firRepointFectory.getActiveScreen = function() {
            return workspaceFactory.activeScreen.screen_key;
        };
        
        /*firRepointFectory.getData = function (_url,_params) {
            console.log("TEST/////////////////////////////////////////////////");
        };*/

        
        
      
        firRepointFectory.saveTb = function(_data,_accounts, sourceSystem){
           
            firRepointFectory.url.saverepoint  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=u7kuxv";
              
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(_accounts);
            var jsonSettings =  JSON.stringify(_data);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            //params =  _.extend({jsonSettings:jsonSettings}, _data);
            
            params.process_name =  "FIR_REPOINT_SAVE";
            console.log(params);
            var promise = $http({
                method: "post",
                url: firRepointFectory.url.saverepoint,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response.data;
            });

            return promise;
            //return null;

        };
        
      

        return firRepointFectory;
    }]);


return services;


});
