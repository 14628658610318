
define([
    'angular',
    './DCLController',
    './DCLService',
], function () {
    'use strict';
    return angular.module('app.dcl', ['app.DCLController','app.DCLService'])
        // .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        //     $stateProvider
        //         .state('supplement', {
        //             url: '/supplement',
        //             templateUrl: 'app/components/messages/messages-tpl.html',
        //             data:{ncyBreadcrumbLabel: 'Tasks'},
        //             access:""
        //         })

        //         .state('supplement.edit', {
        //             url: '/supplement-edit',
        //             templateUrl: 'app/components/messages/messages-tpl.html',
        //             data:{ncyBreadcrumbLabel: 'Tasks'},
        //             access:""
        //         });

        // }]);

});