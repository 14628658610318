/**
 * @author mnagothi001 [503037154 - Mallesh Nagothi] on 02/16/2018.
 * This is to manage GTW Templates and Template Types
 * -Create Template Types, Templates and Assign Template Modules
 * 
 */
define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.templatesCtrl',[])
        .controller('templatesListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','GENERAL_CONFIG', templatesListCtrl])
        .controller('templatesCtrl', ['$rootScope','$scope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','rowData','USER_SETTINGS','GENERAL_CONFIG','TemplateAdminFactory','UserAdminFactory', 'AdminFactory', templatesCtrl])
        .controller('templatesTypeListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','GENERAL_CONFIG', templatesTypeListCtrl])
        .controller('templateTypesCtrl', ['$rootScope','$scope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','rowData','JCDFactory','GENERAL_CONFIG', templateTypesCtrl])
  
     /**
     * @Author: Mallesh Nagothi
     * Controller for ADD Template TYPE Screen
     * 
     */    
    function templateTypesCtrl($rootScope,$scope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModalInstance,rowData,JCDFactory,GENERAL_CONFIG) {
    	
    	var vm = this;
         vm.title = "Add New Template";
         vm.loading = false;
         vm.template_type_data = {};
         vm.mode = "";
         
         vm.mode =  'new';
         if(!rowData) {
         	vm.mode =  'new';
         	vm.template_type_data.template_type_active = 1;
         	 vm.title = "Add New Template Type";
         }else{
         	vm.mode =  'edit';
         	 vm.title = "Update Template Type";
         	 fetchTemplateTypeByKey();
         }
         
         function fetchTemplateTypeByKey(){
         	vm.loading = true;
             var params = {
                 "action_code": '0a8qkl',   
                 "template_type_key": rowData.TEMPLATE_TYPE_KEY
             }
             return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                 if (data.callSuccess === "1") {
                 	vm.template_type_data = JsonObjectFactory.objToLower(data.jsonObject[0]);
                 	vm.loading = false;
                 	console.log(vm.template_type_data);
                 }else{
                 	AlertService.add("error", "Exception while retrieving GTW TEMPLATE TYPES Data...",4000);
                 	vm.loading = true;
                 	return;
                 }
                 vm.loading = false;
             });
         }
         
         vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
         };
         
         
         vm.save = function(_saveMode){
        	if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
        	
    		if (vm.templateFrom.$invalid) {
                vm.templateFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                vm.crudLoading = false;
                return;
            }
    		
            var service = JsonObjectFactory;
            if(_saveMode === 'edit'){
            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ryf88q";
            	var payLoadForSaving = [{TEMPLATE_TYPE_KEY: vm.template_type_data.template_type_key, TEMPLATE_TYPE_NAME:vm.template_type_data.template_type_name,TEMPLATE_TYPE_DESC:vm.template_type_data.template_type_description, IS_ACTIVE:vm.template_type_data.template_type_active}];
            }else{
            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=txxeu7";
            	var payLoadForSaving = [{TEMPLATE_TYPE_NAME:vm.template_type_data.template_type_name,TEMPLATE_TYPE_DESC:vm.template_type_data.template_type_description}];
            }
            var jsonObj = JSON.stringify(payLoadForSaving);
            var jsonSettings = '{}';
            var params = _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            service.saveJSON(url , params).then(function(data){
                if(data.callSuccess === "1"){
                	console.log(data);
                	AlertService.add("success", "Template Type Saved Successfully.",4000);
                    vm.crudLoading = false;
                    var args = {};
                    $rootScope.$emit('gridUpdate', args);
                    vm.cancel();
                }else{
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    vm.crudLoading = false;
                }
            });
        };
    	
    }
    
    
    
    /**
     * 
     * @Author Mallesh Nagothi
     * Controller for Adding a NEW TEMPLATE
     * 
     * 
     */    
    function templatesCtrl($rootScope,$scope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModalInstance,rowData,USER_SETTINGS,GENERAL_CONFIG,TemplateAdminFactory,UserAdminFactory, AdminFactory) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.selectedClientName = "";
        vm.btnDisabled = "N";
        vm.toggleAll = toggleAll;
        vm.isAllExpanded = isAllExpanded;
        vm.setApplication = setApplication;
        vm.changeGroupRole = changeGroupRole;
        vm.filterMenu = filterMenu;
        vm.applicationFilter = {
            values: [],
            selected: [],
            name: 'Application(s)',
            model: null,
        };
        vm.templateGroups = [];

        vm.loadSettings = function (clientKey){
            vm.loading = true;
            AdminFactory.getAppKeys(clientKey).then(function (data) {
                vm.channelList = data.channel.p_cursor;
                vm.selectedChannel = vm.channelList[0];
                AdminFactory.getConfigSettings(vm.selectedChannel.channel_key).then(function (data) {
                    //vm.templateGroups = JSON.parse(data.configSettings[142].VALUE);
                    var templateGroupsObject = _.find(data.configSettings, {NAME : "access_template_groups"});
                    vm.templateGroups = JSON.parse(templateGroupsObject.VALUE);
                    vm.loading = false;
                });
            });
        };

        vm.loadSettings(user.client.client_key);
        
        if(!rowData) {
        	initTemplateScreen('new',0);
        	 /** To fill user assigned clients drop down**/
            getUserAssignedBusinesses(0);
            
        }else{
        	initTemplateScreen('edit',rowData.TEMPLATE_KEY);
        }
        
        $scope.$on('totalmods:changed', function(event,data) {
        	vm.totalModulesAssigned = data;
        });
        
        vm.getClientNameByClientId = function(clientId){
        	angular.forEach(vm.clients, function(client, index) {
        		if(client.client_key === clientId ){
        			vm.selectedClientName = client.client_name;
        		}
        	});
        };
        
        function initTemplateScreen(_initMode,_templateKey){
        	//vm.mode =  'new'; //initMode;
        	vm.mode =  _initMode;
        	vm.loading = false;
            vm.addNewTemplateType = false;
            vm.template_data = {};
            vm.stepNumber = 1;
            vm.step1Style = "col-lg-4 text-center text-primary";
            vm.step2Style = "col-lg-4 text-center text-muted";
            vm.step3Style = "col-lg-4 text-center text-muted";
            vm.step3Flag = false;
            vm.totalModulesForSelectedClient = 0;
            vm.totalModulesAssigned = 0;
            vm.userAssignNav = [];
            vm.template_data.allClientJurisdictions = [];
            vm.saveLabel = "Save & Continue";
            
            vm.user_id = vm.userSettings.user.sso_id; //$state.params.data.user_id;
            vm.actionType = "TemplateAdminFactory";
            vm.rowPrimaryKey = _templateKey;
            vm.totalModulesAssigned = 0;
            
            
            if(_initMode === 'edit'){
            	vm.screenModeNew = false;
            	vm.title = "Update Template";
            	vm.step1Title = "Edit Template";
            	vm.step2Title = "Edit Assigned Modules";
            	fetchTemplateById(_templateKey);
            	vm.userStatusMessage = "Step#1 Modify Template details and Click \"Save & Continue\" button to modify Template Modules";
            }else if(_initMode === 'new'){
            	vm.screenModeNew = true;
            	vm.template_data.template_active = 1;
            	vm.template_data.template_active1 = true;
            	vm.title = "Create Template";
            	vm.step1Title = "Create Template";
            	vm.step2Title = "Assign Modules";
            	vm.userStatusMessage = "Enter details of Template and Click \"Save & Continue\" button to Select Template Modules. Template Status will by default be ACTIVE";
            	fetchTemplateTypes();
            }
            
            vm.slideStep1Div = true;
       	   	vm.slideStep2Div  = false;
        };
        
        function getUserAssignedBusinesses(_clientKey){
        	/** Logic is changed. Instead of fetching from DB, This method returns from USER SETTINGS, so that the list will be 
        	 * limited to the Businesses which logged in user has access to**/
        	if(vm.userSettings){
        		var user = vm.userSettings.user;
        		vm.clients = user.clients;
        		if(_clientKey === 0){
	        		vm.client_key = user.client_key;
	        		vm.template_data.client_key = user.client_key;
	        		vm.selectedClientName = user.client.client_name;
        		}else{
        			vm.client_key = _clientKey;
	        		vm.template_data.client_key = _clientKey;
	        		for(var i = 0; i < user.clients.length; i++){
	        			if(user.clients[i].client_key === _clientKey){
	        				vm.selectedClientName = user.clients[i].client_name;
	        			}
	        		}                 
	        		vm.selectedClientName = user.client.client_name;
        		}
        		
        		/** Now load Jurisdictions of Selected Client/Business**/
        		if(vm.client_key && _clientKey === 0){
        			getAllJurisdictionsByClientKey(vm.client_key);
        		}
        	}
        	
        };
        
        vm.fillJurisdictionsOnClientChange = function(_clientKey){
        	getAllJurisdictionsByClientKey(_clientKey);
        }
        
       function getAllJurisdictionsByClientKey(_clientKey){
        	console.log("getAllJurisdictionsByClientKey() _clientKey",_clientKey);
        	/** Empty them before refreshing jurisdictions as they are not valid any more **/
        	vm.template_data.allClientJurisdictions = [];
         	vm.loading = true;
         	var data = {};
         	data.clientkey = _clientKey;
            vm.loading = true;
            console.log("getAllJurisdictionsByClientKey() data ::",data);
            UserAdminFactory.getJurisByBusiness(data).then(function (data) {
                console.log("data ::",data);
            	vm.loading = false;
                if(data.callSuccess === "1"){
                	vm.template_data.allClientJurisdictions = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	console.log("vm.allJurisdictions ::",vm.template_data.allClientJurisdictions);
                	if(vm.template_data.allClientJurisdictions.length > 0){
                		angular.forEach(vm.template_data.allClientJurisdictions, function(jurisdiction, index) {
	                    		/** System is trying to auto select United States as default Jurisdiction**/
                				if(jurisdiction.jurisdictionkey === 1 ){
	                    			vm.template_data.jurisdictionkey = jurisdiction.jurisdictionkey;
	                    			console.log("System auto selected US as Juris:: vm.jurisdictionkey",vm.template_data.jurisdictionkey);
	                    		}
                		 });
                		/** Pl. read above logic this is obvious logic. Thnx.
                		 *  Not clear by reading above comments. Here ya go.. If US not found in Jurisdictions list, system defaults to first Jurisdiction in the list index
                		 * **/
                		if(vm.template_data.jurisdictionkey <=0  ){
                			/** First Item as Default Jurisdiction**/
                			vm.template_data.jurisdictionkey = vm.template_data.allClientJurisdictions[0].jurisdictionkey;
                			console.log("System auto selected first item as Juris:: vm.jurisdictionkey",vm.template_data.jurisdictionkey);
                		}
                	}else{
                		vm.userMessage = "No Jurisdictions found. Please change Filter Criteria..";
                	}
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }
                }
            });
             
        	
        };
        
        
        vm.getTotalModulesFound = function(){
        	vm.totalModulesForSelectedClient = 0;
        	angular.forEach(vm.userAssignNav, function(module) {
        		if(module.children){
        			angular.forEach(module.children, function(child){
        			   if(child !==null){
        				   if(child.children){
        					   angular.forEach(child.children, function(grandChild){
        						   if(grandChild.type && grandChild.type === "activity"){
       	            				   vm.totalModulesForSelectedClient++;
        						   }
        					   })
        				   }
        			   }
        			})
        		}
        	});
        	console.log("In getTotalModulesFound() totalModulesForSelectedClient ::",vm.totalModulesForSelectedClient);
        };
        
        vm.refresh = function(_stepNumber){
	       	 if(_stepNumber === 1){
	          	   vm.slideStep1Div = false;
	          	   vm.slideStep2Div  = true;
	          	   fetchAllGTWModules();
	          	   forceFetchModuleCount();
	          	 
	       	 }else if(_stepNumber === 2){
	       		   vm.slideStep1Div = false;
	       		   vm.slideStep2Div  = false;
       		 }
        };
        
        function forceFetchModuleCount(){
        	/** force fetch count of modules in the template**/
           	var data = {};
            vm.loading = true;
            var data = {};
        	data.template_key = vm.rowPrimaryKey;
        	data.logged_in_user_sso_id = vm.user_id;
        	TemplateAdminFactory.forceFetchTotalAssignedModules(data).then(function (data) {
                vm.loading = false;
                console.log("forceFetchModuleCount ::",data);
                if(data){
                     vm.totalModulesAssigned = data[0].moduleCount;
                }else {
                	console.log("Unable to forceFetchModuleCount");
                }
            });
        }

        function mergeNonMatchedTaskHierachy(jsonAry){
            var doProcess1 = function() {
                 for(var i = 0 ; i < jsonAry.length; i++){
                     var chlObj = jsonAry[i];
                     process11(chlObj);
                     chlObj = process12(chlObj);
                 }
             }
            var process11=function(chlObj){
             if (chlObj.children && chlObj.children.length) {
                 for (var c = 0; c < chlObj.children.length; c++) {
                     var childTaskObj = chlObj.children[c];
                     // check any parent key for the current task
                     // if present, add
                     var childObjList = _.filter(chlObj.children,{parentTaskKey : childTaskObj.id});
                     if(childObjList.length){
                         childTaskObj.processed=true;
                         for(var i=0;i<childObjList.length;i++){
                             var childObj = childObjList[i];
                             if(childTaskObj.children)
                                 childTaskObj.children.unshift(childObj);
                             else
                                 childTaskObj.children=[childObj];
                             // process11(chlObj);
                         }
                     }
                 }
             }
         }
         var process12=function(chlObj){
             chlObj.children=_.filter(chlObj.children,{parentTaskKey:null});
             return chlObj;
         }
         console.log(doProcess1());
         }
 
        
        /**
         * @Author Mallesh  Naoghti
         * Module Assign function 
         * This is for bringing all GTW Modules By Jurisdiction and By Client Key in a Tree Structure way
         * This calls PKG_GLOBAL_TAX_USERS.getUserAssignSelect() stored Proc
         * This uses UserAdminDiretives for rendering tree structure.  
         * 
         */
        function fetchAllGTWModules(){
        	  var data = {};
              vm.loading = true;
              data.jurisdiction_key = 1;
              data.client_key = vm.template_data.client_key;
              data.row_primary_key = vm.rowPrimaryKey;
              TemplateAdminFactory.getGtwModules(data).then(function (data) {
                  vm.loading = false;
                  console.log("fetchAllGTWModules ::",data.jsonObject);
                  if(data.callSuccess === "1" ){
                       vm.userAssignNav = data.jsonObject;
                       vm.applicationFilter.values = data.jsonObject.map(function(item) {
                        return { ...item, value: item.id };
                        });
                        vm.applicationFilter.selected = angular.copy(vm.applicationFilter.values);
                        mergeNonMatchedTaskHierachy(vm.userAssignNav);
                        var dropdownOption = _.find(vm.userAssignNav,{id: "5"});
                        setApplication(dropdownOption);
                        filterMenu();
                       vm.getTotalModulesFound();
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
              });
              vm.stepNumber = 2;
        }
        
        
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.templateStatusChanged = function(){
	       	 if(vm.template_data.template_active === 1){
	       		vm.template_data.template_active = 0;
	       		vm.template_data.template_active1 = false;
	       	 }else{
	       		vm.template_data.template_active = 1;
	       		vm.template_data.template_active1 = true;
	       	 }
        }
        
        vm.save = function(_saveMode,_stepNumber){
        	if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
        	
    		if (vm.templateFrom.$invalid) {
                vm.templateFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                vm.crudLoading = false;
                return;
            }
    		
    		if(vm.saveLabel === 'Close'){
    			$rootScope.$emit('gridUpdate', {});
                vm.cancel();
    		}
    		
    		if(vm.saveLabel === 'Continue'){
//    			$rootScope.$emit('gridUpdate', {});
//                vm.cancel();
    			vm.stepSuccess(_saveMode, _stepNumber);
    		}
    		
    		if(_stepNumber === 1){
	            var service = JsonObjectFactory;
	            if(vm.addNewTemplateType){
	            	vm.template_data.template_type_key = "";
	            	vm.template_data.template_type_name = vm.template_data.template_type_name_new;
	            }else{
	            	vm.template_data.template_type_name_new = "";
	            	vm.template_data.template_type_desc = "";
	            }
	            if(!vm.template_data.template_type_key){
	            	vm.template_data.template_type_key = "";
	            }
	            if(!vm.template_data.template_type_desc){
	            	vm.template_data.template_type_desc = "";
	            }
	            if(!vm.template_data.template_type_name){
	            	vm.template_data.template_type_name = "";
	            }
	            if(!vm.template_data.template_type_name_new){
	            	vm.template_data.template_type_name_new = "";
	            }
	            
	            
	            if(_saveMode === 'new'){
	            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=5so839";
	                var payLoadForSaving = [{TEMPLATE_NAME:vm.template_data.template_name,TEMPLATE_DESCRIPTION:vm.template_data.template_description,TEMPLATE_GROUP_KEY: vm.template_data.template_group,TEMPLATE_TYPE_KEY:vm.template_data.template_type_key,TEMPLATE_TYPE_NAME:vm.template_data.template_type_name,TEMPLATE_TYPE_DESC:vm.template_data.template_type_desc, CLIENT_KEY:vm.template_data.client_key,JURISDICTION_KEY:vm.template_data.jurisdictionkey}];
	            }else{
	            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=csx7p4";
	                var payLoadForSaving = [{TEMPLATE_KEY:vm.template_data.template_key, TEMPLATE_NAME:vm.template_data.template_name,TEMPLATE_DESCRIPTION:vm.template_data.template_description,TEMPLATE_GROUP_KEY: vm.template_data.template_group, TEMPLATE_ACTIVE:vm.template_data.template_active,TEMPLATE_TYPE_KEY:vm.template_data.template_type_key,TEMPLATE_TYPE_NAME:vm.template_data.template_type_name,TEMPLATE_TYPE_DESC:vm.template_data.template_type_desc, CLIENT_KEY:vm.template_data.client_key,JURISDICTION_KEY:vm.template_data.jurisdictionkey}];
	            }
	            
	            var jsonObj = JSON.stringify(payLoadForSaving);
	            var jsonSettings = '{}';
	
	            var params = _.extend({jsonObj:jsonObj}, params);
	            params =  _.extend({jsonSettings:jsonSettings}, params);
                
	            service.saveJSON(url , params).then(function(data){
	                if(data.callSuccess === "1"){
	                	console.log(data);
                        $rootScope.$emit("TemplateCreated", {});
	                	if(_saveMode === 'new'){
	                		vm.rowPrimaryKey = data.generatedPk;
	                	}else{
	                		vm.rowPrimaryKey = vm.template_data.template_key
	                	}
	                	vm.stepSuccess(_saveMode, _stepNumber);
	                }else{
	                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
	                    vm.crudLoading = false;
	                }
	            });
    		}
        };
        
        vm.stepSuccess = function(_saveMode, _stepNumber){
        	
        	if(_saveMode === 'new' && _stepNumber === 1){
        		vm.crudLoading = false;
        		vm.step1Style = "col-lg-4 text-center text-success";
                vm.step2Style = "col-lg-4 text-center text-primary";
                vm.step3Style = "col-lg-4 text-center text-muted";
                vm.step1Title = "Template Created";
                vm.step2Title = "Assign Modules";
                vm.saveLabel  = "Continue";
                vm.userStatusMessage = "Using dropdown menu, Assign Roles for Each Activity. This automatically saves the Role with in the Template.";
                
        	}else if(_saveMode === 'edit' && _stepNumber === 1){
        		vm.crudLoading = false;
        		vm.step1Style = "col-lg-4 text-center text-muted";
                vm.step2Style = "col-lg-4 text-center text-primary";
                vm.step3Style = "col-lg-4 text-center text-muted";
                vm.step1Title = "Template Updated";
                vm.step2Title = "Edit Template Modules";
                vm.saveLabel  = "Continue";
                vm.userStatusMessage = "Using dropdown menu, change Role assignments for Each Activity. This automatically saves the Role with in the Template.";
        	}else if(_saveMode === 'new' && _stepNumber === 2){
        		vm.crudLoading = false;
        		vm.step1Style = "col-lg-4 text-center text-muted";
                vm.step2Style = "col-lg-4 text-center text-muted";
                vm.step3Style = "col-lg-4 text-center text-primary";
                vm.step1Title = "Template Created";
                vm.step2Title = "Template Modules Created";
                vm.saveLabel  = "Close";
                vm.userStatusMessage = "New Template created successfully.Click CLOSE button to close this window";
                vm.step3Flag = true;
        	}else if(_saveMode === 'edit' && _stepNumber === 2){
        		vm.crudLoading = false;
        		vm.step1Style = "col-lg-4 text-center text-muted";
                vm.step2Style = "col-lg-4 text-center text-muted";
                vm.step3Style = "col-lg-4 text-center text-primary";
                vm.step1Title = "Template Updated";
                vm.step2Title = "Template Modules Updated";
                vm.saveLabel  = "Close";
                vm.userStatusMessage = "Template Edited successfully.Click CLOSE button to close this window";  	
                vm.step3Flag = true;
        	}
        	vm.refresh(_stepNumber);
        	
            //var args = {};
            //$rootScope.$emit('gridUpdate', args);
            //vm.cancel();
        }
        
        vm.previousScreen = function(_saveMode){
        	if(_saveMode === 'new'){
        		initTemplateScreen('edit', vm.rowPrimaryKey);
        	}else{
        		initTemplateScreen('edit',rowData.TEMPLATE_KEY);
        	}
        	
        }
        
        function fetchTemplateById(_templateKey){
        	vm.loading = true;
            var params = {
                "action_code": '916d9i',   
                "template_key": _templateKey 
            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
            	vm.loading = false;
            	if (data.callSuccess === "1") {
            		var tempData = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	vm.template_data = tempData[0];
                	getUserAssignedBusinesses(data.jsonObject[0].CLIENT_KEY);
                	
                    /** Force feed Active/Inactive toggle button as its not a tradictional check box for auto selection**/               	
                	if(vm.template_data.template_active === 1){
                   		vm.template_data.template_active1 = true;
                   	}else{
                   		vm.template_data.template_active1 = false;
                   	}
                }else{
                	AlertService.add("error", "Exception while retrieving GTW TEMPLATE TYPES Data...",4000);
                	vm.loading = true;
                	return;
                }
                vm.loading = false;
            });
        	
        }
        
        /** 
         * Author: Mallesh Nagothi
    	 *  pkg_globaltax_templates.GET_TEMPLATE_TYPE_NAMES(?,?,?,?,?)
    	 *  fetch_type = 1 Retrieves Only Active Template Types
    	 *  fetch_type = 0 Retrieves Only Inactive Template Types
    	 *  fetch_type = null Retrieves All Template Types
    	 * **/
        function fetchTemplateTypes(){
        	vm.loading = true;
            var params = {
                "action_code": '4txmy1',   
                "fetch_type": '1'
            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                	vm.template_data.templatetypes = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	
                	/** If there is only one Template Type Please auto Select it, this makes business user's life easy**/
                	if(vm.template_data.templatetypes){
                		if(vm.template_data.templatetypes.length ===1){
                			vm.template_data.template_type_key = vm.template_data.templatetypes[0].template_type_key; 
                			vm.template_data.template_type_name = vm.template_data.templatetypes[0].template_type_name;
                		}
                	}
                	if(!vm.template_data.templatetypes){
                		 AlertService.add("warning", "GTW Template Type not found. Create Template Types before creating GTW Template",4000);
                		 vm.loading = false;
                		 vm.cancel();
                	}
                }else{
                	AlertService.add("error", "Exception while retrieving GTW TEMPLATE TYPES Data...",4000);
                	vm.loading = true;
                	return;
                }
                vm.loading = false;
            });
        }
        
        /**
         * @author Mallesh Nagothi
         * @since 02/16/2018
         * When user clicks +/-  and tries to Add a new Template Types
         */
        vm.manageTemplateTypes = function(_state){
        	vm.template_data.template_type_name_new = "";
        	vm.template_data.template_type_desc = "";
        	 if(vm.addNewTemplateType){
        		 vm.addNewTemplateType = false;
        	 }else{
        		 vm.addNewTemplateType = true;
        	 }
        }

        //Used to expand and collpase all the application module cards.
        function isAllExpanded() {
            return vm.filteredUserAssignNav && vm.filteredUserAssignNav.some(function(node) {
                return !node.collapsed;
            });
        }

        //Used to expand and collpase all the application module cards.
        function toggleAll() {
            var expand = isAllExpanded();
            vm.filteredUserAssignNav.forEach(function(node) {
                node.collapsed = expand;
            });
        }

        function setApplication(item) {
            vm.searchTerm = '';
            if (item) {
                vm.selectedApplication = item;
                vm.filteredUserAssignNav = item.children;
            } else {
                filterMenu();
            }
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        function changeGroupRole(roleHigh, group) {
            if (angular.isArray(group.children)) {
                group.children.forEach(function(item) {
                    changeGroupRole(roleHigh, item);
                });
            }
            if (angular.isArray(group.roles)) {
                if (roleHight) {
                    group.role = _.get(group.roles, { value: Math.max.aplly(Math, _.map(group.roles, 'value'))});
                } else {
                    group.role = _.get(group.roles, { value: Math.min.aplly(Math, _.map(group.roles, 'value'))});
                }
            }
        }
        
        
     }//End of templatesCtrl


    
    function templatesTypeListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,GENERAL_CONFIG) {
    	 var vm = this;
         vm.name = "Template Types List";
     
         var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
             var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
             delete args['gridFilter'];
             getUpDate(args,filterObj);
         });


         $scope.$on('$destroy', function iVeBeenDismissed() {
             gridUpdateListener();
         });

         vm.refresh = function(_params){
             getData();
         };

         vm.addTemplateType = function(){
             var data = {};
             var modalObj = {};
             modalObj.template = "app/components/admin/templates/template-type-tpl.html";
             modalObj.controler = "templateTypesCtrl as ctrl";
             modalObj.size = "md";
             modalObj.keyboard = false;
             modalObj.backdrop = "static";
             vm.openModal(modalObj,data);
         }
        
         var getData = function() {
         	 $scope.$broadcast("showLoader", true);
             vm.loading = true;
             vm.gridData = [];
             vm.gridData.DATA = [];

             GridFactory.getDataCustom($state.current.api.get).then(function (data) {
                 if (data.callSuccess === "1") {
                     $scope.$broadcast("dataLoaded", data);
                     $scope.$broadcast("showLoader", false);
                 } else {
                     AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                 }
             });

         }


         var getUpDate = function(_params,_filterObj) {
           	 var additionalParams = {}
             var params = _.merge({}, additionalParams, _params);
             params = _.merge({}, params, vm.filter);
             $scope.$broadcast("showDataRefresh", true);

             GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                 if (data.callSuccess === "1") {
                     $scope.$broadcast("showDataRefresh", false);
                     $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                 } else {
                     $scope.$broadcast("showDataRefresh", false);
                     AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                 }
             });
         }

         $timeout(function() {
         	$scope.$broadcast("showLoader", true);
             getData();
         }, 5);


         $scope.ctrlFn = function(_callName,_data,_type) {
         	var modalObj = {};
             modalObj.template = "app/components/admin/templates/template-type-tpl.html";
             modalObj.controler = "templateTypesCtrl as ctrl";
             modalObj.size = "md";
             modalObj.keyboard = false;
             modalObj.backdrop = "static";
             vm.openModal(modalObj,_data);
         };

         vm.openModal = function (modalObj,_data) {
             vm.rowData = _data.rowData;
             vm.gridData = _data;

             var modalInstance = $uibModal.open({
                 animation: true,
                 templateUrl: modalObj.template,
                 controller: modalObj.controler,
                 size: modalObj.size,
                 backdrop: modalObj.backdrop,
                 keyboard: modalObj.keyboard,
                 resolve: {
                     rowData: function () {
                         return vm.rowData;
                     },
                     gridData: function () {
                         return vm.gridData;
                     }
                 }
             });

             modalInstance.result.then(function (selectedItem) {
                 $scope.selected = selectedItem;
             }, function () {
                 $log.info('Modal dismissed at: ' + new Date());
                 $log.info('$scope.rowData' , $scope.rowData);
             });
         };
         
         
    }
    
    
    /**
     * @Autor Mallesh Nagothi
     * This ngController is to list GTW Templates on Admin Screen
     * Since:  02/16/2018
     * 
     */
    function templatesListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,GENERAL_CONFIG) {
        var vm = this;
        vm.name = "Templates List";

        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            gridUpdateListener();
        });

        vm.refresh = function(_params){
            getData();
        };

        vm.addTemplate = function(){
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/templates/template-tpl.html";
            modalObj.controler = "templatesCtrl as ctrl";
            modalObj.size = "md";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj,data);
        }

        var getData = function() {
        	$scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];



            GridFactory.getDataCustom($state.current.api.get).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });

        }


        var getUpDate = function(_params,_filterObj) {
        	var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
            params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
            	if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $timeout(function() {
        	$scope.$broadcast("showLoader", true);
            getData();
        }, 5);


        $scope.ctrlFn = function(_callName,_data,_type) {
        	vm.mode = 'edit';
        	var modalObj = {};
            modalObj.template = "app/components/admin/templates/template-tpl.html";
            modalObj.controler = "templatesCtrl as ctrl";
            modalObj.size = "md";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj,_data);
            vm.loading = false;
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    	
    }; //End of templatesListCtrl

    return controllers;

});