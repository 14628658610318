define([
    'angular',
    './QreEntityController',
    './QreEntityService'



], function () {
    'use strict';
    return angular.module('app.qreEntity', ['app.qreentityController','app.qreentityService'] );


});