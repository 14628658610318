/**
 * Created by 212544474 on 4/1/2016.
 */
define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.tbbsController',[])
        .controller('TbbsController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','TbbsService','GENERAL_CONFIG', tbbsController])


    function tbbsController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory,$timeout, tbbsService,GENERAL_CONFIG){


        var vm = this;
        vm.title = "Tax Basis Balance Sheet";
        vm.entities = [];
        vm.projectList = [];
        vm.projectStepList = [];
        vm.project = null;
        vm.projectStep = null;
        vm.projectLoading = true;
        vm.projectStepLoading = false;
        vm.hideProjectSteps = false;
        $scope.crudLoading = false;
        vm.sysacctdropdownloading  = true;
        var sendobj = [];
        vm.checksystemaccount = [];
        vm.oldTbbsAcct = '';
        vm.tbbsAcctType = '';
     	var tbbsTagjson = {};
     	var transdetailskey = [];

	 function init() {

		 $scope.crudLoading = false;
					if (!_.isArray(gridData)) {

						var obj = {}
						obj.row_id = gridData.rowData.object_id;
						obj.data = gridData.rowData;
						gridData = [];
						gridData.push(obj)
					}
					vm.entities  = _.clone(gridData);

					if (typeof rowData !== 'undefined') {
						vm.rowData = rowData;
					}else{
						validateSelectedRows();
					}

					getSystemAcctdropdown();
	 }

	 function validateSelectedRows(){

					for ( var index_ in gridData) {
						vm.checksystemaccount
								.push(gridData[index_].data.TBBS_ACCT);
					}

					vm.checksystemaccount = _.uniq(vm.checksystemaccount,
							JSON.stringify)
					// var paramssystemaccount= vm.checksystemaccount;
					if (vm.checksystemaccount.length > 1) {
						AlertService.add("",
								"Please Select unique TBBS Account.",
								400000);
						//vm.userMessage = "!! Unable to perform Selected Action...";
						throw new ("This is not a javascript error . This is just to abort javascript!");
					}


		 }

        function getSystemAcctdropdown() {
        	if (typeof rowData !== 'undefined'){
        		var params = {
    					"action_code" : 'czu78f',
    					"tax_year" :GlobalService.globalParams.tax_year,
    					"scenario":GlobalService.globalParams.scenario,
    					"jcd_key":GlobalService.globalParams.jcd_key
    					//"acct_type" : rowData.ACCT_TYPE,

    				}

        	}else {
        		var params = {
        				"action_code" : 'czu78f',
    					"tax_year" : GlobalService.globalParams.tax_year,
    					"scenario":GlobalService.globalParams.scenario,
    					"jcd_key":GlobalService.globalParams.jcd_key
    					//"acct_type" : rowData.ACCT_TYPE,
    					//"screen_key" : rowData.SCREEN_KEY,

    				}

        	}
        	  vm.sysacctdropdownloading  = true;
			return JsonObjectFactory
					.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
					.then(
							function(data) {
								if (data.callSuccess === "1") {
									vm.sys_acc_dropdown_data = [];
									for ( var index_ in data.jsonObject) {
										vm.sys_acc_dropdown_data
												.push(data.jsonObject[index_].SYS_DROPDOWN);
									}
									vm.sys_acc_dropdown_data = _.uniq(vm.sys_acc_dropdown_data, JSON.stringify)
									vm.sys_acc_dropdown_data_TEMP = data.jsonObject;

									if (rowData != null) {
										var index = _
												.findIndex(
														vm.sys_acc_dropdown_data_TEMP,
														{
															'SYS_ACCT' : rowData.TBBS_ACCT
														});
									}

									else {
										var index = _
												.findIndex(
														vm.sys_acc_dropdown_data_TEMP,
														{
															'SYS_ACCT' : vm.checksystemaccount[0]
														});

									}
									/*   if(index != undefined  ){
										   if(index > -1){
										   vm.tbbsAcctType = vm.sys_acc_dropdown_data_TEMP[index].ACCT_TYPE;
									vm.TBBS_ACCT_DROPDOWN =(vm.sys_acc_dropdown_data_TEMP[index] !== undefined && vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== -1)?(vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN):'Please select System Account';
									vm.oldTbbsAcct = vm.TBBS_ACCT_DROPDOWN;
									 vm.sysacctdropdownloading  = false;}}
									   else{
											vm.TBBS_ACCT_DROPDOWN =vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN;
											vm.oldTbbsAcct = vm.TBBS_ACCT_DROPDOWN;
											 vm.sysacctdropdownloading  = false;
									   }*/

									   vm.tbbsAcctType = (vm.sys_acc_dropdown_data_TEMP[index] !== undefined && vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== -1)?vm.sys_acc_dropdown_data_TEMP[index].ACCT_TYPE:"";
									   vm.TBBS_ACCT_DROPDOWN =(vm.sys_acc_dropdown_data_TEMP[index] !== undefined && vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== -1)?(vm.sys_acc_dropdown_data[index]):'Please select System Account';
									vm.oldTbbsAcct = vm.TBBS_ACCT_DROPDOWN;
										 vm.sysacctdropdownloading  = false;

								} else {
									if (data.errorMessage === "no access") {
										AlertService.add( "", "Sorry you do not have access to do the requested action.", 4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									} else {
										AlertService .add( "", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									}
								}

							});
		};



        vm.save = function(){


        	  if ($scope.crudLoading) {
                  AlertService.add("info", "Please wait while we save this request", 4000);
                  return;
              }

              $scope.crudLoading = true;
        	addToJson();
           // vm.crudLoading = true;
        	 var dummyJson = {}
        	 tbbsService.saveProjectTag(dummyJson,sendobj).then(function (result) {


        		 for(var a = 0 ; a<sendobj.length; a++ ){
					 //transdetailskey[a] = sendobj[a].TRANS_DETAILS_KEY;
					 transdetailskey[a] = sendobj[a].COMBINATION_KEY;
        		 }
        		// projectTagjsonSend.push(projectTagjson);
        		 transdetailskey = _.uniq(transdetailskey,
							JSON.stringify)

						transdetailskey =transdetailskey.join();



                if(result.callSuccess !== "1" ){
                    AlertService.add("error", result.errorMessage, 4000);
					$scope.crudLoading = false;

                }else{
                        AlertService.add("success", "Data has been saved successfully " , 4000);
                        $uibModalInstance.dismiss('cancel');
                        $timeout(function(){


							var args = {
								comnination_keys:transdetailskey,
								updatekey:transdetailskey,
								gridFilter : {
									COMBINATION_KEY:transdetailskey,
								}
							};
							$rootScope.$emit('gridUpdate', args);
						});
                        sendobj=[];
                }
            });
        }

        function addToJson(){
        	if(vm.entities.length >0){
        		   angular.forEach(vm.entities, function (row, key) {
        	           buildJson(row.data);
        	   });

        	}
        }


        function buildJson(dataObj){
        	tbbsTagjson = {};
	            tbbsTagjson['COMBINATION_KEY'] = dataObj.COMBINATION_KEY;
	            tbbsTagjson['TAX_YEAR'] = dataObj.TAX_YEAR;
	            tbbsTagjson['SCENARIO'] = GlobalService.globalParams.scenario;
	            tbbsTagjson['JCD_KEY'] = GlobalService.globalParams.jcd_key;
	            tbbsTagjson['SCHDM_ID'] = dataObj.SCHDM_ID;
	            tbbsTagjson['TRANS_HEADER_KEY'] = dataObj.TRANS_HEADER_KEY !='' ? dataObj.TRANS_HEADER_KEY : '';
	            tbbsTagjson['TRANS_DETAILS_KEY'] = dataObj.TRANS_DETAILS_KEY !='' ? dataObj.TRANS_DETAILS_KEY : '';
	            tbbsTagjson['OLD_TBBS_ACCT'] = dataObj.TBBS_ACCT;

	            if(vm.oldTbbsAcct == vm.TBBS_ACCT_DROPDOWN){

	            	 tbbsTagjson['NEW_TBBS_ACCT'] = dataObj.TBBS_ACCT;

	            }else {
	            	  var index = _.findIndex(
	  						vm.sys_acc_dropdown_data,
	  						{
	  							'SYS_DROPDOWN' : vm.sys_acc_dropdown_data
	  						});

	            	  for (var a in vm.sys_acc_dropdown_data_TEMP)
	            		  {
	            		  if(vm.TBBS_ACCT_DROPDOWN == vm.sys_acc_dropdown_data_TEMP[a].SYS_DROPDOWN)
	            			  {
	            			  tbbsTagjson['NEW_TBBS_ACCT'] = vm.sys_acc_dropdown_data_TEMP[a].SYS_ACCT_KEY;
	            			  }
	            		  }


	            }

	            tbbsTagjson['SOURCE_SYSTEM'] = dataObj.SOURCE_SYS;

	            sendobj.push(tbbsTagjson);
       }


        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };


        vm.removeEntity = function(_index){
            vm.entities.splice(_index,1);
            gridData.splice(_index,1);
            if( vm.entities.length === 0 ){
                $uibModalInstance.dismiss('cancel');
            }
        }


        init();

        vm.updateTbbsAcctType = function(){
				var index = _
						.findIndex(
								vm.sys_acc_dropdown_data_TEMP,
								{
									'SYS_DROPDOWN' : vm.TBBS_ACCT_DROPDOWN
								});

				vm.tbbsAcctType = vm.sys_acc_dropdown_data_TEMP[index].ACCT_TYPE;
        }


    }///////////////END CTRL

    return controllers;


});