define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-IService',[])
       .factory("scheduleIServiceFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleIServiceFactory ={};
	           scheduleIServiceFactory.url ={};
	           scheduleIServiceFactory.url.save_schedule_I = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=dsbcjk";
	           scheduleIServiceFactory.url.updateFlag = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=1tfdq7";
	           
	           scheduleIServiceFactory.saveScheduleI = function(_data,Details){
	           var service = $injector.get('JsonObjectFactory');
			   var defer = $q.defer();   
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           //params.tax_year = "2018";
	           //params.scenario = "28";
	           if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
	        	   params.jcd_key = "250";
	           }
	           params.process_name =  "Schedule_I_Save";
	           /*var promise = $http({
	        	   method: "post",
	               url: scheduleIServiceFactory.url.save_schedule_I,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
           };*/
	           service.saveJSON(scheduleIServiceFactory.url.save_schedule_I,params).then(function (data) {
	        	   if (data.callSuccess === "1") {						
	        		   //AlertService.add("success", "Data is successfully saved/edited.", 4000);
						defer.resolve(data);
					}else {
                     AlertService.add("error", data.errorMessage);
                     defer.reject(data);
                 }},function(data){
                 defer.reject(data);
             });
             return defer.promise;
		   }
           
           scheduleIServiceFactory.updateFlag = function(_data,Details){
       		
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	           //params = _.extend({'jcd_key':250}, params)
	           //console.log("params-------------", params);
	          // params = _.extend({jcd_key:250}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
	        	   params.jcd_key = "250";
	           }
	         //  params.scenario = "28";
	           params.process_name =  "Schedule_I_updateFlag";
	           var promise = $http({
	        	   method: "post",
	               url: scheduleIServiceFactory.url.updateFlag,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
           };
           
           //////////////////save data from grid
           scheduleIServiceFactory.saveScheduleIGrid = function(_callName, _data)
			{
        	   if(parseInt(_data.rowData.LINE_NO) == 6)
        	   {
        		   console.log("in if");
        		   var intr=setInterval(function(){ 
                       AlertService.add("error", "You cannot edit Line No 6 for total of Line 1 through 5.");
                      clearInterval(intr);
                      $rootScope.$apply();
                   },500);
        		   _data.rowData.FIR_OVERRIDE_AMT = _data.oldRowData.FIR_OVERRIDE_AMT
        		   return false;
        	   }
        	   else
        	   {
		        	   $rootScope.$broadcast('gridRefresh', false); 
						
						if( parseInt(GlobalService.globalParams.tax_year) < 2018 && parseInt(_data.rowData.LINE_NO) < 6 )
						{
							var params = {
									"tax_year":GlobalService.globalParams.tax_year,
			            			"combination_key":_data.rowData.HO_COMBINATION_KEY,
			            	 }
			            	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kij1vt",params).then(function(data) {
			            		//console.log("Response Data x ::", data.jsonObject);
			            		if (data.callSuccess === "1") {
			        				var line_6_total = 0;
			            			console.log("JSONDATA:----------", data.jsonObject);
			            			var editRow = [];
			            			var tempObj = data.jsonObject;
			            			var line_6_Obj = {};
			            			
			            			///getting details for line 5 (line 1 to 4 total) detail in an object
			            			for(var j = 0; j<tempObj.length; j++)
			            			{
			            				if(parseInt(tempObj[j].LINE_NO) == 6)
			            					line_6_Obj = tempObj[j];
			            			}
			            			
			            			for(var i = 0; i < 5; i++ )
			            			{
			            				if(parseInt(tempObj[i].LINE_NO) != parseInt(_data.rowData.LINE_NO))
			            				{
			            					if(tempObj[i].TRANS_DETAILS_KEY != 0)
			            						line_6_total = line_6_total + tempObj[i].ATTRIB_VALUE;
			            					else if(tempObj[i].GHOST_TRANS_DETAILS_KEY != 0)
			            						line_6_total = line_6_total + tempObj[i].GHOST_AMT_VALUE;
			            					else 
			            						line_6_total = line_6_total + 0;
			            				}	
			            			}
			            			line_6_total = line_6_total + parseInt(_data.rowData.FIR_OVERRIDE_AMT);
			            			
			            			console.log("line_6_total-------------", line_6_total);
			            			
			            			var returnObj1 = [{
														"tax_year":GlobalService.globalParams.tax_year,
														"trans_type_key":_data.rowData.TRANS_TYPE_KEY,
														"combination_key":_data.rowData.HO_COMBINATION_KEY,
														"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
														"form_key":_data.rowData.FORM_KEY,
														"line_no":line_6_Obj.LINE_NO,
														"occurence":((line_6_Obj.OCCURRENCE == "0") ? null : line_6_Obj.OCCURRENCE),
														"line_attrib_key" : line_6_Obj.FOA_LINE_ATTRIB_KEY,
														"Attribute_Name":'FIR_OVERRIDE_AMT',
														"Attribute_Value":line_6_total,
														"trans_dtls_key" : ((line_6_Obj.TRANS_DETAILS_KEY == "0") ? null : line_6_Obj.TRANS_DETAILS_KEY),
														"trans_status":"A"}];
			            			
			            			editRow.push(returnObj1);
			            			//////to update GHOST AMT OVERRIDE_Y_N FLAG
			            			var updateOverrideFlag = [];
			            			console.log("_data.rowData---------------", _data.rowData);
			            			if( (_data.rowData.FIR_OVERRIDE_AMT != _data.rowData.GHOST_AMT && _data.rowData.GHOST_OVERRIDE_Y_N != 'Y' && _data.rowData.GHOST_TRANS_DETAILS_KEY != 0) || (_data.rowData.FIR_OVERRIDE_AMT == _data.rowData.GHOST_AMT && _data.rowData.GHOST_OVERRIDE_Y_N != 'N' && _data.rowData.GHOST_TRANS_DETAILS_KEY != 0) )
			            			{
			            				var tempFlagObj = [{
		                					"trans_header_key":parseInt(_data.rowData.GHOST_TRANS_HEADER_KEY),
	            							"trans_details_key":_data.rowData.GHOST_TRANS_DETAILS_KEY,
	            							"trans_type_key":_data.rowData.GHOST_TRANS_TYPE_KEY,
	            							"line_attrib_key":_data.rowData.GHOST_LINE_ATTRIB_KEY,
	            							"form_key":_data.rowData.GHOST_FORM_KEY,
	            							"OVERRIDE_Y_N":(_data.rowData.GHOST_OVERRIDE_Y_N =='Y'? 'N' : 'Y')
	            						}];
	            						updateOverrideFlag.push(tempFlagObj);
			            			}
			            			/////////////
			        				var returnObj = [{
			        									"tax_year":GlobalService.globalParams.tax_year,
			        									"trans_type_key":_data.rowData.TRANS_TYPE_KEY,
			        									"combination_key":_data.rowData.HO_COMBINATION_KEY,
			        									"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
			        									"form_key":_data.rowData.FORM_KEY,
			        									"line_no":_data.rowData.LINE_NO,
			        									"occurence":((_data.rowData.OCCURRENCE == "0") ? null : _data.rowData.OCCURRENCE),
			        									"line_attrib_key" : _data.rowData.LINE_ATTRIB_KEY,
			        									"Attribute_Name":'FIR_OVERRIDE_AMT',
			        									"Attribute_Value":_data.rowData.FIR_OVERRIDE_AMT,
			        									"trans_dtls_key" : ((_data.rowData.TRANS_DETAILS_KEY == "0") ? null : _data.rowData.TRANS_DETAILS_KEY),
			        									"trans_status":"A"}];
			        				editRow.push(returnObj);
		
			        				var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
			        				console.log('scheduleDetails-------------', scheduleDetails);
			        				var JSONSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
			        				scheduleIServiceFactory.saveScheduleI(JSONSettings,
			        						scheduleDetails).then(
			        						function(result) {
		
			        							if (result.errorMessage
			        									&& result.errorMessage !== 'null') {
			        								AlertService.add("error",
			        										result.errorMessage, 4000);
			        							} else {
			        								 var args = {combination_keys: returnObj.HO_COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: returnObj.HO_COMBINATION_KEY}};
			        				                 $rootScope.$emit('gridUpdate', args);
			        								AlertService.add("success", "Schedule I detail is sucessfully Saved/Edited", 4000);
			        								////////UPDATING GHOST AMT FLAG
			        								if(updateOverrideFlag.length > 0)
			        								{
			        									//console.log("vm.rowData.combination_key------------", vm.rowData);
			    										var jsonSettings = [{"combination_key":_data.rowData.HO_COMBINATION_KEY, "tax_year":GlobalService.globalParams.tax_year}];
			    										var jsonDetails = updateOverrideFlag;
			    										console.log("json-------", jsonDetails);
			    										scheduleIServiceFactory.updateFlag(jsonSettings,
			    												jsonDetails).then(
			    												function(result) {

			    													if (result.errorMessage
			    															&& result.errorMessage !== 'null') {
			    														//AlertService.add("error",
			    																//result.errorMessage, 4000);
			    													} else {
			    														//AlertService.add("success", message, 4000);
			    													}
			    												});
			        								}
			        							}
			        						});
			            		}
			            		else {
									if (data.errorMessage === "no access") {
										AlertService.add("","Sorry you do not have access to do the requested action.",
														4000);
										//vm.userMessage = "!! Unable to perform Selected Action...";
									} else {
										AlertService.add(
														"",
														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
														4000);
										//vm.userMessage = "!! Unable to perform Selected Action...";
									}
								}
			            		
			            	});
						}
						else
						{
								var JSONSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
				                var editRow = [];
								var returnObj = [{
													"tax_year":GlobalService.globalParams.tax_year,
													"trans_type_key":_data.rowData.TRANS_TYPE_KEY,
													"combination_key":_data.rowData.HO_COMBINATION_KEY,
													"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
													"form_key":_data.rowData.FORM_KEY,
													"line_no":_data.rowData.LINE_NO,
													"occurence":((_data.rowData.OCCURRENCE == "0") ? null : _data.rowData.OCCURRENCE),
													"line_attrib_key" : _data.rowData.LINE_ATTRIB_KEY,
													"Attribute_Name":'FIR_OVERRIDE_AMT',
													"Attribute_Value":_data.rowData.FIR_OVERRIDE_AMT,
													"trans_dtls_key" : ((_data.rowData.TRANS_DETAILS_KEY == "0") ? null : _data.rowData.TRANS_DETAILS_KEY),
													"trans_status":"A"}];
								editRow.push(returnObj);
				
								var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
								console.log('scheduleDetails-------------', scheduleDetails);
								
								scheduleIServiceFactory.saveScheduleI(JSONSettings,
										scheduleDetails).then(
										function(result) {
				
											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage, 4000);
											} else {
												 var args = {combination_keys: returnObj.HO_COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: returnObj.HO_COMBINATION_KEY}};
								                 $rootScope.$emit('gridUpdate', args);
												AlertService.add("success", "Schedule I detail is sucessfully Saved/Edited", 4000);
				
											}
										});
						}
        	   }
        	   /*else
        	   {
        		   
        	   }*/
			}
           
return scheduleIServiceFactory;
       }]);

   return services;
});