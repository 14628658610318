define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.stdDescCtrl', [])
                             .controller('stdDescListCtrl', ['$rootScope', '$scope', '$state', '$log', 'GridFactory', 'GENERAL_CONFIG', 'AlertService', '$timeout', 'workspaceFactory', '$uibModal', 'stdDescFactory', 'SERVER_CONFIG', 'USER_SETTINGS', stdDescListCtrl])
                             .controller('stdDescAddCtrl', ['$rootScope', '$scope', '$state', '$log', 'AlertService', '$timeout', '$uibModalInstance', 'stdDescData', 'stdDescFactory', stdDescAddCtrl])
                             .controller('stdDescEditCtrl', ['$rootScope', '$scope', '$state', '$log', 'AlertService', '$timeout', '$uibModalInstance', 'stdDescFactory', 'rowData', 'colData', 'stdDescData', stdDescEditCtrl])

    function stdDescListCtrl($rootScope, $scope, $state, $log, GridFactory, GENERAL_CONFIG, AlertService, $timeout, workspaceFactory, $uibModal, stdDescFactory, SERVER_CONFIG, USER_SETTINGS) {
        var vm = this;
        vm.activeScreenLocal = workspaceFactory.activeScreen;
        vm.stdDescCache = vm.activeScreenLocal.data || {};
        vm.data = vm.stdDescCache.data || null;
        vm.canCreateQARStdDesc = SERVER_CONFIG.clientConfig.can_create_qar_std_desc.includes(USER_SETTINGS.user.sso_id);

        if (vm.data) {
            $timeout(() => {
                $scope.$broadcast("dataLoaded", vm.data);
            }, 5);
        }

        $scope.ctrlFn = function (_callName, _data, _type) {
            if (_callName === 'stdDescEdit') {
                $uibModal.open({
                    templateUrl: "app/components/QARStdDesc/stdDesc-add-edit-tpl.html",
                    controller: "stdDescEditCtrl as ctrl",
                    backdrop: "static",
                    size: "md",
                    windowClass: 'right fade',
                    resolve: {
                        rowData: function () {
                            return _data.rowData;
                        },
                        stdDescData: function () {
                            return vm.data;
                        },
                        colData : function(){
                            return _data.colData;
                        }
                    }
                }).result.then(function () {
                        getData();
                });
            }
        };

        vm.refresh = function(){
            getData();
        }
        
         vm.addStdDesc = function () {
            var modalObj = {};
            modalObj.template = "app/components/QARStdDesc/stdDesc-add-edit-tpl.html";
            modalObj.controller = "stdDescAddCtrl as ctrl";
            modalObj.size = "md";
            modalObj.backdrop = "static";
            modalObj.resolve = {
                stdDescData: function () {
                    return vm.data;
                }
            };
            $scope.openModal(modalObj);
        };

        getData();

        function getData() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + '/loadGridJson?action_id=32190&grid_id=5776').then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = vm.stdDescCache.data = data;
                    workspaceFactory.setCache(vm.activeScreenLocal, vm.stdDescCache);
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    vm.stdDescCache.data = [];
                    workspaceFactory.setCache(vm.activeScreenLocal, vm.stdDescCache);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $scope.openModal = function (modalObj) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controller,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: modalObj.resolve
            });

            modalInstance.result.then(function () {
                    getData();
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };

    }

    function stdDescAddCtrl($rootScope, $scope, $state, $log, AlertService, $timeout, $uibModalInstance, stdDescData, stdDescFactory) {
        var vm = this;
        vm.data = stdDescData.data;
        vm.title = 'Add New Standard Description';
        vm.systemFlag = ['Y', 'N'];
        

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function () {
            vm.stdDesc = null;
            vm.selectedSystemFlag = null;
        };

        vm.save = function () {
            if (vm.stdDescAddEditForm.$invalid) {
                return;
            }
            vm.saving = true;
            stdDescFactory.createStdDesc(
                vm.stdDesc,
                vm.selectedSystemFlag ? vm.selectedSystemFlag: 'N')
                         .then((response) => {
                             if (response.data.callSuccess === "0") {
                                 AlertService.add("error", response.data.errorMessage);
                             } else {
                                 AlertService.add("success", " Standard Description added Successfully!", 4000);
                                 $uibModalInstance.close(); 
                             }
                         })
                         .catch((error) => {
                             console.log(error);
                             AlertService.add('error', 'Something went wrong!');
                         })
                         .finally(() => {
                             vm.saving = false;
                         });
        };
    }

    function stdDescEditCtrl($rootScope, $scope, $state, $log, AlertService, $timeout, $uibModalInstance, stdDescFactory, rowData, colData, stdDescData) {
        var vm = this;
        vm.data = stdDescData.data;
        vm.rowData = rowData;
        vm.systemFlag = ['Y', 'N'];
        vm.stdDesc = vm.rowData.QAR_DESC;
        vm.selectedSystemFlag = vm.rowData.SYSTEM_FLAG;
        vm.title = 'Edit Standard Description';

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function () {
            vm.stdDesc = vm.rowData.QAR_DESC;
            vm.selectedSystemFlag = vm.rowData.SYSTEM_FLAG;
            if (vm.stdDescAddEditForm) {
                vm.stdDescAddEditForm.$setPristine();
            }
        };

        vm.save = function () {
            if (vm.stdDescAddEditForm.$invalid) {
                return;
            }
            vm.saving = true;
            stdDescFactory.updateStdDesc(
                vm.stdDesc,
                vm.selectedSystemFlag ? vm.selectedSystemFlag: 'N',
                vm.rowData.STD_DESC_ID)
                         .then((response) => {
                             if (response.data.callSuccess === "0") {
                                 AlertService.add("error", response.data.errorMessage);
                             } else {
                                 AlertService.add("success", "Standard Description Updated Successfully!", 4000);
                                 $uibModalInstance.close(); 
                             }
                         })
                         .catch((error) => {
                             console.log(error);
                             AlertService.add('error', 'Something went wrong!');
                         })
                         .finally(() => {
                             vm.saving = false;
                         });
        };
    }

    return controllers;
});
